import React from 'react';
import { CustomTooltip, Icon } from 'components/common';
import cx from 'classnames';
import styles from 'assets/styles/recipients.module.scss';
import CurrencyIcon from 'components/common/CurrencyIcon';
import { Country } from 'country-state-city';
import { toCapitalize } from 'utils/helpers';
import CountryIcon from 'components/common/CountryIcon';
import selectAppState from 'AppSelectors';

const ReviewBankDetails = ({ recipient, onEdit }) => {
    const { featureFlags } = selectAppState();
    const bank_info_enrichment_enabled = featureFlags.enable_enriched_bank_info_create_recipient;
    const countries = Country.getAllCountries();
    const bankCountry = bank_info_enrichment_enabled? recipient.bank_country : recipient.country;
    const bankCountryEnriched = countries.find((country) => country.isoCode === bankCountry);
    const bankAddressConcat = [
        recipient.bank_name,
        recipient.bank_address_line_1,
        recipient.bank_address_line_2,
        recipient.bank_city,
        recipient.bank_state,
        recipient.bank_postcode,
        bankCountryEnriched?.name,
    ]
        .filter((e) => e)
        .join(', ')
    const renderAccountHolderName = () => {
        return (
            <div className={cx(styles.data)} style={bank_info_enrichment_enabled ? {width: '100%'}: {}}>
                <div className={styles.dataBlock}>
                    <CurrencyIcon currency={recipient.currency} rounded width="45px" />
                    <div style={{ marginLeft: '20px' }}>
                        <div className={cx(styles.label)}>Account holder name</div>
                        <div className={cx(styles.value)}>{recipient.account_holder_name}</div>
                    </div>
                </div>
            </div>
        );
    };

    const renderRowDataBlock = (label, value) => {
        return (
            <div className={cx(styles.data)} style={{width: '100%'}}>
                <div className={cx(styles.label)}>{label}</div>
                <div className={cx(styles.value)}>{value}</div>
            </div>
        );
    };

    const renderDataBlock = (label, value) => {
        return (
            <div className={cx(styles.data)}>
                <div className={cx(styles.label)}>{label}</div>
                <div className={cx(styles.value)}>{value}</div>
            </div>
        );
    };
    return (
        <div>
            <div className={cx(styles.sectionTitle)}>Bank account details</div>
            <div className={cx(styles.section)}>
                <div className={cx(styles.segment)}>
                    <div className={cx(styles.details)}>
                        {renderDataBlock('Sending funds to', toCapitalize(recipient.type))}
                    </div>
                    <div className={cx(styles.action)}>
                        <div className={styles.editDetails} onClick={onEdit}>
                            <CustomTooltip info={'Click here to edit bank details'} enableInfoStyle={false}>
                                <Icon name="edit" color="#00A09B" />
                            </CustomTooltip>
                        </div>
                    </div>
                </div>
                <div className={cx(styles.segment)}>
                    <div className={cx(styles.details)}>
                        {renderAccountHolderName()}
                        {!bank_info_enrichment_enabled && renderDataBlock(
                            'Bank address', <div className={styles.dataBlock}>
                                <CountryIcon countryCode={recipient.country} rounded width="18px" />
                                <div style={{ marginLeft: '5px' }}>
                                    {bankCountryEnriched?.name}
                                </div>
                            </div>
                        )}
                        {recipient.account_number &&
                            renderDataBlock('Account number', recipient.account_number)}
                        {recipient.iban && renderDataBlock('IBAN', recipient.iban)}
                        {recipient.sort_code && renderDataBlock('Sort code', recipient.sort_code)}
                        {recipient.bic && renderDataBlock('BIC', recipient.bic)}
                        {bank_info_enrichment_enabled && renderRowDataBlock(
                            'Bank address',
                            <div className={styles.dataBlock}>
                                {bankAddressConcat}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewBankDetails;
