import React from 'react';
import { CustomTooltip, Icon } from 'components/common';
import cx from 'classnames';
import styles from 'assets/styles/recipients.module.scss';
import { Country } from 'country-state-city';
import { toCapitalize } from 'utils/helpers';
import { paymentPurposeTypes } from './types';

const ReviewResidenceDetails = ({ recipient, onEdit }) => {
    const countries = Country.getAllCountries();
    const recipientCountry = countries.find(
        (country) => country.isoCode === recipient.address_country
    );
    const recipientAddressConcat = [
        recipient.address_line1,
        recipient.address_line2,
        recipient.address_city,
        recipient.address_state,
        recipient.address_postcode,
        recipientCountry?.name,
    ]
        .filter((e) => e)
        .join(', ')

    const renderDataBlock = (label, value) => {
        return (
            <div className={cx(styles.data)}>
                <div className={cx(styles.label)}>{label}</div>
                <div className={cx(styles.value)}>{value}</div>
            </div>
        );
    };

    const renderDataBlock30 = (label, value) => {
        return (
            <div className={cx(styles.data30)}>
                <div className={cx(styles.label)}>{label}</div>
                <div className={cx(styles.value)}>{value}</div>
            </div>
        );
    };

    const renderDataBlock35 = (label, value) => {
        return (
            <div className={cx(styles.data35)}>
                <div className={cx(styles.label)}>{label}</div>
                <div className={cx(styles.value)}>{value}</div>
            </div>
        );
    };    
    const purposeCodeDetails = paymentPurposeTypes.find(
        (x) => x.value === recipient.default_purpose
    );

    return (
        <div>
            <div className={cx(styles.sectionTitle)}>Recipient details</div>
            <div className={cx(styles.section)}>
                <div className={cx(styles.segment)}>
                    <div className={cx(styles.details)}>
                        {recipient.type === 'business' &&
                            renderDataBlock('Business name', toCapitalize(recipient.company_name))}
                        {recipient.type === 'person' &&
                            renderDataBlock(
                                'Person name',
                                `${recipient.first_name} ${recipient.last_name}`
                            )}
                        {renderDataBlock('', '')}
                        {renderDataBlock30('Nick name', recipient.nickname ?? '-')}
                        {renderDataBlock30('Email', recipient.email ?? '-')}
                        {renderDataBlock35(
                            'Payment purpose',
                            purposeCodeDetails ? (
                                <>
                                    {purposeCodeDetails?.default_purpose_code} -{' '}
                                    {purposeCodeDetails?.default_purpose}
                                    <CustomTooltip
                                        info={
                                            `${purposeCodeDetails?.default_purpose_code} - ${purposeCodeDetails?.default_purpose} ` +
                                            (purposeCodeDetails.additional_purpose
                                                ? ` ; ${purposeCodeDetails.additional_purpose}`
                                                : '')
                                        }>
                                        <Icon name={'info'} />
                                    </CustomTooltip>
                                </>
                            ) : (
                                '-'
                            )
                        )}
                        <div className={cx(styles.data)} style={{ width: '75%' }}>
                            <div className={cx(styles.label)}>Residence address</div>
                            <div className={cx(styles.value)}>
                                {recipientAddressConcat ? recipientAddressConcat: '-'}
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.action)}>
                        <div className={styles.editDetails} onClick={onEdit}>
                            <CustomTooltip info={'Click here to edit recipient details'} enableInfoStyle={false}>
                                <Icon name="edit" color="#00A09B" />
                            </CustomTooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewResidenceDetails;
