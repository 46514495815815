import React, { useEffect, useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import cx from 'classnames';
import styles from 'assets/styles/send-funds.module.scss';
import Confirm from './Confirm';
import Review from './Review';
import { Grid } from '@mui/material';
import selectState from 'store/selectors/queuepayments';
import { actions } from 'store/slice/queuepayments';
import { useDispatch } from 'react-redux';
import CustomStepperIcon from 'components/common/StepperContent';
import styled from 'styled-components/macro';
import { Icon } from 'components/common';
import { useSearchParams } from 'react-router-dom';
import { PaymentInput } from 'components/common/Payment';
import DialogV2 from 'components/common/DialogV2';

const steps = ['Enter details', 'Review details', 'Queue payment'];

const CustomStepLabel = styled(StepLabel)(({ ownerState }) => ({
    ...(ownerState.completed && {
        color: '#00A09B !important',
    }),
    ...(ownerState.active && {
        color: '#00A09B',
    }),
    ...(ownerState.error && {
        color: '#FF8888 !important',
    }),
}));

const StyledLabel = styled('span')(({ ownerState }) => ({
    ...(ownerState.completed && {
        color: '#00A09B !important',
    }),
    ...(ownerState.active && {
        color: '#00A09B !important',
    }),
}));

const StyledOffsetGrid = styled(Grid)`
    @media (max-width: 768px) {
        display: none;
    }
`;

const QueuePaymentRequest = () => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const [fromAccountId, setFromAccountId] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const { currentStep, paymentRequestStatus, payment_details, fromAccount } = selectState();

    const isLastStep = currentStep === steps.length - 1;

    useEffect(() => {
        dispatch(actions.resetPaymentRequest());
    }, [dispatch]);

    useEffect(() => {
        if (searchParams.get('from')) {
            setFromAccountId(searchParams.get('from'));
            setSearchParams({});
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (paymentRequestStatus === 'error') {
            setShowErrorModal(true);
        }
    }, [paymentRequestStatus]);
    
    useEffect(() => {
        if (!fromAccountId) {
            return;
        }
        dispatch(actions.fetchFromAccount(fromAccountId));

        // Resetting it so that the changes could be detected next time
        setFromAccountId(null);        
    }, [dispatch, fromAccountId]);

    const handlePaymentsInputSubmit = (values) => {
        dispatch(
            actions.generatePaymentDetails({
                ...values,
            })
        );
        dispatch(actions.setCurrentStep(1));
    };

    const isStepError = (step) => {
        return step === 2 && paymentRequestStatus === 'error';
    };

    const StepIconError = () => {
        return <CustomStepperIcon error={true} />;
    };

    const StepIcon = (props) => {
        const { active, completed, className, error } = props;
        return (
            <CustomStepperIcon
                active={active}
                completed={completed}
                error={error}
                className={className}
                finalStepCompleted={isLastStep && paymentRequestStatus === 'success'}
            />
        );
    };

    const StepIconSuccess = () => {
        return <Icon name="passed" className="completedIcon" />;
    };

    const handleCancelPaymentRequest = () => {
        dispatch(actions.resetPaymentRequest());
        dispatch(actions.setCurrentStep(0));
    };    

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <PaymentInput
                        handleSubmit={handlePaymentsInputSubmit}
                        defaultFrom={fromAccount}
                        values={payment_details}
                    />
                );
            case 1:
                return <Review handleCancel={handleCancelPaymentRequest} />;
            case 2:
                return <Confirm />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Grid className={cx(styles['send-funds'])}>
            <Grid item xs={8}>
                <h1 className={cx(styles['page-title'])}>Queue payments</h1>
            </Grid>
            {isLastStep && <StyledOffsetGrid item md={2} />}
            <Grid item md={12} xs={12} sm={12} lg={12}>
                <Stepper
                    activeStep={currentStep}
                    className={cx(styles['stepper-wrapper'])}
                    alternativeLabel
                    connector={
                        <div className={cx(styles['stepper-connector'])}>
                            <span />
                        </div>
                    }>
                    {steps.map((label, index) => {
                        if (isStepError(index)) {
                            return (
                                <Step key={label}>
                                    <CustomStepLabel
                                        StepIconComponent={StepIconError}
                                        ownerState={{
                                            error: isStepError(index),
                                        }}>
                                        <StyledLabel
                                            ownerState={{
                                                error: isStepError(index),
                                            }}
                                            style={{
                                                color: '#FF8888',
                                            }}>
                                            {label}
                                        </StyledLabel>
                                    </CustomStepLabel>
                                </Step>
                            );
                        }
                        if (isLastStep && paymentRequestStatus === 'success') {
                            return (
                                <Step key={label}>
                                    <CustomStepLabel
                                        StepIconComponent={StepIconSuccess}
                                        ownerState={{
                                            active: currentStep === index,
                                            error: isStepError(index),
                                            completed: currentStep > index,
                                        }}>
                                        <StyledLabel
                                            ownerState={{
                                                active: currentStep === index,
                                                completed: currentStep > index,
                                            }}>
                                            {' '}
                                            {label}
                                        </StyledLabel>
                                    </CustomStepLabel>
                                </Step>
                            );
                        }
                        return (
                            <Step key={label}>
                                <CustomStepLabel
                                    StepIconComponent={StepIcon}
                                    ownerState={{
                                        active: currentStep === index,
                                        error: isStepError(index),
                                        completed: currentStep > index,
                                    }}>
                                    <StyledLabel
                                        ownerState={{
                                            active: currentStep === index,
                                            completed: currentStep > index,
                                        }}>
                                        {' '}
                                        {label}
                                    </StyledLabel>
                                </CustomStepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <DialogV2
                title={'Request to create payment request timed out'}
                open={showErrorModal}
                isSingleButton={true}
                setOpen={() => setShowErrorModal(false)}
                submitButton={'Close'}
                variant={'info'}
                submitAction={() => setShowErrorModal(false)}
            >
                <div className={cx(styles.messageText)}>
                    <p>We encountered an unforeseen error while processing your request. Please retry again shortly. If the issue persists, please get in touch with our customer service team at cs@merge.money.</p>
                </div>                
            </DialogV2>            
            {renderStepContent(currentStep)}
        </Grid>
    );
};

export default QueuePaymentRequest;
