import React from 'react';
import cx from 'classnames';
import styles from 'assets/styles/common.module.scss';
import moment from 'moment';
import selectAppState from 'AppSelectors';
import { formatAmount, toCapitalize, toDateTimeString } from 'utils/helpers';
import { getCurrencySymbol } from 'utils/helpers';
import CurrencyIcon from 'components/common/CurrencyIcon';
import { CustomTooltip, Icon } from 'components/common';
import NumberFormat from 'react-number-format';
import Loading from '../Loading';
import TransactionLink from './TransactionLink';

const TransactionView = ({ transaction, loading, error, onRefresh }) => {
    const transactionType = transaction?.payment_type;
    const { featureFlags } = selectAppState();

    const paymentScheme = {
        'PI_FAST': 'Faster Payments',
        'PI_BACS': 'BACS',
        'PI_CHAPS': 'CHAPS',
        'PI_DD': 'Direct Debit',
        'PI_SECT': 'SEPA Credit Transfers',
        'PI_SEPA_INST': 'SEPA Instant Credit Transfers',
        'PI_MASTER': 'Mastercard',
        'PI_VISA': 'VISA',
        'PO_FAST': 'Faster Payments',
        'PO_CHAPS': 'CHAPS',
        'PO_DD': 'Direct Debit',
        'PO_SECT': 'SEPA Credit Transfers',
        'PO_SEPA_INST': 'SEPA Instant Credit Transfers',
        'PO_MASTER': 'Mastercard',
        'PO_VISA': 'VISA',
        'INT_INTERC': 'Internal Payments',
        'ADHOC': 'Miscellaneous Adjustments',
        'PI_REV': 'Reversed payment',
        'PO_REV': 'Reversed payment'
    }
    const getPaymentSchemeDescription = (schemeId) => {
        if(paymentScheme[schemeId]) {
            return ` Payment processed via ${paymentScheme[schemeId]}`
        }
        return `Payment processed via ${schemeId}`; 
    }
    const getAccountDetails = (transaction) => {
        if (transactionType === 'payin') {
            return {
                account_name: transaction.destination_name,
                currency: transaction.destination_currency ?? transaction.source_currency,
                account_number: transaction.destination_account_number,
                sort_code: transaction.destination_sort_code,
                iban: transaction.destination_iban,
                bic: transaction.destination_bic,
                amount: transaction.destination_amount ?? transaction.source_amount,
            };
        } else {
            return {
                account_name: transaction.source_name,
                currency: transaction.source_currency,
                account_number: transaction.source_account_number,
                sort_code: transaction.source_sort_code,
                iban: transaction.source_iban,
                bic: transaction.source_bic,
                amount: transaction.source_amount,
            };
        }
    };

    const isTransactionInTerminalState = () => {
        const terminalStatuses = ['successful', 'failed'];
        return terminalStatuses.some(status => status === transaction.status);
    }

    const getRecipientDetails = (transaction) => {
        return {
            account_holder_name: transaction.destination_name,
            currency: transaction.destination_currency,
            account_number: transaction.destination_account_number,
            sort_code: transaction.destination_sort_code,
            iban: transaction.destination_iban,
            bic: transaction.destination_bic,
            amount: transaction.destination_amount,
        };
    };


    const getRemitterDetails = (transaction) => {
        if (transactionType === 'payin') {
            return {
                account_holder_name: transaction.source_name,
                account_number: transaction.source_account_number,
                sort_code: transaction.source_sort_code,
                iban: transaction.source_iban,
                bic: transaction.source_bic,
            };
        }
        return {}
    };

    const renderAccountInfo = () => {
        const accountDetails = getAccountDetails(transaction);
        return (
            <div>
                <div className={cx(styles.txndetails)}>
                    <CurrencyIcon currency={accountDetails.currency} rounded width="45px" />
                    <div className={cx(styles.card)}>
                        <div className={cx(styles.clabel)}>Your account</div>
                        <div className={cx(styles.cvalue)}>{accountDetails.account_name}</div>
                    </div>
                </div>
                <div className={cx(styles.amountInfo)}>
                    <div>
                        <div className={cx(styles.alabel)}>
                            {transactionType === 'payin' ? 'You received' : 'You sent'}
                        </div>
                        <div className={cx(styles.amount)}>
                            <NumberFormat
                                value={formatAmount(accountDetails.amount)}
                                thousandSeparator={true}
                                displayType={'text'}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={getCurrencySymbol(accountDetails.currency)}
                            />
                        </div>
                    </div>
                    {transaction.payment_date && (
                        <div style={{ textAlign: 'right' }}>
                            <div className={cx(styles.alabel)}>Payment time</div>
                            <div className={cx(styles.time)}>
                                {toDateTimeString(transaction.payment_date)}
                            </div>
                        </div>
                    )}
                </div>
                <div className={cx(styles.identifiersInfo)}>
                    {accountDetails.account_number && (
                        <div className={cx(styles.identifier)}>
                            <div className={cx(styles.ilabel)}>Account number</div>
                            <div className={cx(styles.ivalue)}>{accountDetails.account_number}</div>
                        </div>
                    )}
                    {accountDetails.sort_code && (
                        <div className={cx(styles.identifier)}>
                            <div className={cx(styles.ilabel)}>Sort code</div>
                            <div className={cx(styles.ivalue)}>{accountDetails.sort_code}</div>
                        </div>
                    )}
                    {accountDetails.iban && (
                        <div className={cx(styles.identifier)}>
                            <div className={cx(styles.ilabel)}>IBAN</div>
                            <div className={cx(styles.ivalue)}>{accountDetails.iban}</div>
                        </div>
                    )}
                    {accountDetails.bic && (
                        <div className={cx(styles.identifier)}>
                            <div className={cx(styles.ilabel)}>BIC</div>
                            <div className={cx(styles.ivalue)}>{accountDetails.bic}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderRecipientInfo = () => {
        const recipientDetails = getRecipientDetails(transaction);
        return (
            <div>
                <div className={cx(styles.txndetails)}>
                    <CurrencyIcon currency={recipientDetails.currency} rounded width="45px" />
                    <div className={cx(styles.card)}>
                        <div className={cx(styles.clabel)}>
                            Recipient account
                        </div>
                        <div className={cx(styles.cvalue)}>
                            {recipientDetails.account_holder_name}
                        </div>
                    </div>
                </div>
                <div className={cx(styles.amountInfo)}>
                    <div>
                        <div className={cx(styles.alabel)}>
                            Sent to recipient
                        </div>
                        <div className={cx(styles.amount)}>
                            <NumberFormat
                                value={formatAmount(recipientDetails.amount)}
                                thousandSeparator={true}
                                displayType={'text'}
                                decimalScale={2}
                                prefix={getCurrencySymbol(recipientDetails.currency)}
                            />
                        </div>
                    </div>
                </div>
                <div className={cx(styles.identifiersInfo)}>
                    <div className={cx(styles.identifiersInfo)}>
                        {recipientDetails.account_number && (
                            <div className={cx(styles.identifier)}>
                                <div className={cx(styles.ilabel)}>Account number</div>
                                <div className={cx(styles.ivalue)}>
                                    {recipientDetails.account_number}
                                </div>
                            </div>
                        )}
                        {recipientDetails.sort_code && (
                            <div className={cx(styles.identifier)}>
                                <div className={cx(styles.ilabel)}>Sort code</div>
                                <div className={cx(styles.ivalue)}>{recipientDetails.sort_code}</div>
                            </div>
                        )}
                        {recipientDetails.iban && (
                            <div className={cx(styles.identifier)}>
                                <div className={cx(styles.ilabel)}>IBAN</div>
                                <div className={cx(styles.ivalue)}>{recipientDetails.iban}</div>
                            </div>
                        )}
                        {recipientDetails.bic && (
                            <div className={cx(styles.identifier)}>
                                <div className={cx(styles.ilabel)}>BIC</div>
                                <div className={cx(styles.ivalue)}>{recipientDetails.bic}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderRemitterInfo = () => {
        const remitterDetails = getRemitterDetails(transaction);
        return (
            <div>
                <div className={cx(styles.txndetails)}>
                    <div className={cx(styles.card)}>
                        <div className={cx(styles.clabel)}>
                            Remitter details
                        </div>
                        <div className={cx(styles.cvalue)}>
                            {remitterDetails.account_holder_name}
                        </div>
                    </div>
                </div>
                <div className={cx(styles.identifiersInfo)}>
                    <div className={cx(styles.identifiersInfo)}>
                        {remitterDetails.account_number && (
                            <div className={cx(styles.identifier)}>
                                <div className={cx(styles.ilabel)}>Account number</div>
                                <div className={cx(styles.ivalue)}>
                                    {remitterDetails.account_number}
                                </div>
                            </div>
                        )}
                        {remitterDetails.sort_code && (
                            <div className={cx(styles.identifier)}>
                                <div className={cx(styles.ilabel)}>Sort code</div>
                                <div className={cx(styles.ivalue)}>{remitterDetails.sort_code}</div>
                            </div>
                        )}
                        {remitterDetails.iban && (
                            <div className={cx(styles.identifier)}>
                                <div className={cx(styles.ilabel)}>IBAN</div>
                                <div className={cx(styles.ivalue)}>{remitterDetails.iban}</div>
                            </div>
                        )}
                        {remitterDetails.bic && (
                            <div className={cx(styles.identifier)}>
                                <div className={cx(styles.ilabel)}>BIC</div>
                                <div className={cx(styles.ivalue)}>{remitterDetails.bic}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderTransferInfo = () => {
        return (
            <div style={{ marginTop: '50px' }}>
                <hr className={cx(styles.transferLine)} />
                <div className={cx(styles.txnStatus, styles[transaction.status])}>
                    {toCapitalize(transaction.payment_type)} {transaction.status}
                </div>
                {transaction.exchange_rate && (
                    <div className={cx(styles.exchangeRate)}>
                        <div>
                            1 {transaction.source_currency} = {transaction.exchange_rate}{' '}
                            {transaction.destination_currency}
                        </div>
                        <CustomTooltip
                            info={
                                <div style={{ padding: '5px' }}>
                                    <div style={{ paddingBottom: '3px' }}>
                                        <b>Quote ID</b>: {transaction.quote_id}{' '}
                                    </div>
                                    <div>
                                        <b>Exchange rate</b>: {transaction.exchange_rate}
                                    </div>
                                </div>
                            }>
                            <Icon name={'quote'} />
                        </CustomTooltip>
                    </div>
                )}
            </div>
        );
    };

    const renderPaymentTransactionInfo = () => {
        return (
            <>
                {transaction.status === 'failed' && <div className={cx(styles.paymentError)}>
                    <div className={cx(styles.plabel)}>Failure reason:</div>
                    <div className={cx(styles.pvalue)}>{transaction.status_note}</div>
                </div>}
                <div style={{display: 'flex', gap: '30px', alignItems: 'center', marginBottom: '20px'}}>
                    <div className={cx(styles.paymentType)}>
                        {toCapitalize(transaction.payment_type)} {onRefresh && !isTransactionInTerminalState() ? <CustomTooltip info={'Refresh transaction'}> <Icon name={'refresh'} onClick={onRefresh} /></CustomTooltip>: <></>}
                    </div>
                    {featureFlags?.enable_payment_scheme && <div>
                        {transaction.payment_scheme? 
                        <div style={{backgroundColor: 'rgba(239, 243, 254, 1)', borderRadius: '4px', lineHeight: '14px', color: 'rgba(68, 65, 156, 1)', fontWeight: '700', fontSize: '12px', padding:'4px 12px'}}>
                            {getPaymentSchemeDescription(transaction.payment_scheme)}
                        </div> : 
                        <div  style={{backgroundColor: 'rgba(247, 247, 247, 1)', borderRadius: '4px', lineHeight: '14px', color: 'rgba(102, 102, 102, 1)', fontWeight: '700', fontSize: '12px', padding:'4px 12px'}}>
                            Payment scheme is not yet available
                        </div>}
                    </div>}
                </div>
                <div className={cx(styles.summary)}>
                    <div className={cx(styles.accountInfo)}>{renderAccountInfo()}</div>
                    <div className={cx(styles.transferInfo)}>{renderTransferInfo()}</div>
                    <div className={cx(styles.accountInfo)}>{
                       transaction.payment_type === 'payout'? renderRecipientInfo(): renderRemitterInfo()
                    }</div>
                </div>
                <div className={cx(styles.misc)}>
                    <div className={cx(styles.panel)}>
                        <div className={cx(styles.createdAt)}>
                            Created on{' '}
                            {moment(transaction.created_at).format('DD MMM YYYY HH:mm:ss')}
                        </div>
                        <div className={cx(styles.plabel)}>Transaction ID</div>
                        <div className={cx(styles.pvalue)}>{transaction.transaction_id}</div>
                    </div>
                    <div className={cx(styles.panel)}>
                        <div className={cx(styles.plabel)} style={{ marginTop: '7px' }}>
                            Reference
                        </div>
                        <div className={cx(styles.pvalue)}>{transaction.reference}</div>
                    </div>
                </div>
            </>
        );
    };

    const renderFeeTransactionInfo = () => {
        return (
            <>
                <div className={cx(styles.paymentType)}>
                    {transaction.type_description === 'fee_payout' ? 'Payout fee': 'Payin fee'}
                </div>

                <div className={cx(styles.feeSummary)}>
                    <div className={cx(styles.feeDetails)}>
                        <div className={cx(styles.accDetails)}>
                            <div style={{ display: 'flex' }}>
                                <CurrencyIcon
                                    currency={transaction.source_currency}
                                    rounded
                                    width="45px"
                                />
                                <div className={cx(styles.card)}>
                                    <div className={cx(styles.clabel)}>Your account</div>
                                    <div className={cx(styles.cvalue)}>{transaction.source_name}</div>
                                </div>
                            </div>
                            <div className={cx(styles.status, styles[transaction.status])}>
                                {toCapitalize(transaction.status)}
                            </div>
                        </div>
                        <div className={cx(styles.txnInfo)}>
                            <div className={cx(styles.data)}>
                                <div className={cx(styles.dlabel)}>Fee deducted</div>
                                <div className={cx(styles.amount)}>
                                    <NumberFormat
                                        value={formatAmount(transaction.source_amount)}
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        decimalScale={2}
                                        prefix={getCurrencySymbol(transaction.source_currency)}
                                    />
                                </div>
                            </div>
                            <div className={cx(styles.data)}>
                                <div className={cx(styles.dlabel)}>Creation time</div>
                                <div className={cx(styles.time)}>
                                    {moment(transaction.created_at).format('DD MMM YYYY HH:mm:ss')}
                                </div>
                            </div>
                            <div className={cx(styles.data)}>
                                <div className={cx(styles.dlabel)}>Payment time</div>
                                <div className={cx(styles.time)}>
                                    {moment(transaction.payment_date).format('DD MMM YYYY HH:mm:ss')}
                                </div>
                            </div>
                        </div>
                        <div className={cx(styles.identifiersInfo)}>
                            {transaction.source_account_number && (
                                <div className={cx(styles.identifier)}>
                                    <div className={cx(styles.ilabel)}>Account number</div>
                                    <div className={cx(styles.ivalue)}>
                                        {transaction.source_account_number}
                                    </div>
                                </div>
                            )}
                            {transaction.source_sort_code && (
                                <div className={cx(styles.identifier)}>
                                    <div className={cx(styles.ilabel)}>Sort code</div>
                                    <div className={cx(styles.ivalue)}>
                                        {transaction.source_sort_code}
                                    </div>
                                </div>
                            )}
                            {transaction.source_iban && (
                                <div className={cx(styles.identifier)}>
                                    <div className={cx(styles.ilabel)}>IBAN</div>
                                    <div className={cx(styles.ivalue)}>{transaction.source_iban}</div>
                                </div>
                            )}
                            {transaction.source_bic && (
                                <div className={cx(styles.identifier)}>
                                    <div className={cx(styles.ilabel)}>BIC</div>
                                    <div className={cx(styles.ivalue)}>{transaction.source_bic}</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={cx(styles.miscFee)}>
                        <div className={cx(styles.panel)}>
                            <div className={cx(styles.plabel)}>Parent transaction ID</div>
                            <div className={cx(styles.value)}>
                                {transaction.parent_transaction_id ? <TransactionLink accountId={transaction.account_id} transactionId={transaction.parent_transaction_id}/>  :'-'}
                            </div>
                            <div className={cx(styles.divider)}></div>
                            <div className={cx(styles.plabel)}>Reference</div>
                            <div className={cx(styles.value)}>{transaction.reference ?? '-'}</div>
                            <div className={cx(styles.divider)}></div>
                            <div className={cx(styles.plabel)}>Fee transaction ID</div>
                            <div className={cx(styles.value)}>{transaction.transaction_id ?? '-'}</div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <div className={cx(styles.txnModal)}>
            {loading && <Loading className={cx(styles.loading)} />}
            {error && <div>{error}</div>}
            {(transaction?.payment_type === 'payout' || transaction?.payment_type === 'payin') &&
                renderPaymentTransactionInfo()}
            {transaction?.payment_type === 'fee' && renderFeeTransactionInfo()}
        </div>
    );
};

export default TransactionView;
