import React from 'react';
import { Grid } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/recipients.module.scss';
import ReviewBankDetails from './ReviewBankDetails';
import ReviewResidenceDetails from './ReviewResidenceDetails';
import { ButtonV2 } from 'components/common/Button';
import { Loading } from 'components/common';

const AddRecipientConfirm = ({
    loading,
    recipient,
    onSubmit,
    onBankDetailsEdit,
    onResidenceDetailsEdit,
    onCancel,
}) => {
    return (
        <Grid item xs={12} md={12} lg={12} sm={12} position={'relative'}>
            {loading && <Loading className={cx(styles.modalLoading)} />}
            <Grid item xs={12}>
                <ReviewBankDetails recipient={recipient} onEdit={onBankDetailsEdit} />
                <ReviewResidenceDetails recipient={recipient} onEdit={onResidenceDetailsEdit} />
                <div style={{ display: 'flex', padding: '16px 0px', justifyContent: 'center', gap: '20px' }}>
                    <ButtonV2
                        text={'Cancel'}
                        // className={cx(styles['submitButton'])}
                        variant="secondary"
                        onClick={onCancel}
                    />

                    <ButtonV2
                        text={'Create recipient'}
                        // className={cx(styles['submitButton'])}
                        onClick={onSubmit}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default AddRecipientConfirm;
