import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Loading, Otp, Button } from 'components/common';
import { actions as commonActions } from 'store/slice/common';
import { actions as accountActions } from 'store/slice/account';
import { selectCloseAccount } from 'store/selectors/account';
import { selectRequestOtp } from 'store/selectors/common';
import styles from 'assets/styles/account.module.scss';
import { StyledOtpButtonWrapper } from 'components/common/Otp/styles';
import DialogV2 from 'components/common/DialogV2';
import { toCapitalize } from 'utils/helpers';

export default function CloseSubAccount({
    displayModal,
    handleModal,
    accountMeta,
    pageType,
    pageMeta,
}) {
    const dispatch = useDispatch();
    const [displayOtpDialog, setDisplayOtpDialog] = useState(false);
    const { loading, message, error, isLoaded } = useSelector(selectCloseAccount);
    const { otpError, otpCount, otpToken, otpLoading, otpLoaded } = useSelector(selectRequestOtp);
    const { accountName, accountId, balance } = accountMeta;
    const hasZeroBalance = Number(balance) === 0;
    const errorLoadingCloseReq = error.status >= 400;
    const errorLoadingOtp = otpLoaded && otpCount !== 0 && displayModal;
    const accCreationValidationSchema = yup.object({
        otp: yup
            .string()
            .required('OTP is required')
            .matches(/^[0-9]{6}$/, 'OTP must be exactly 6 digits'),
    });
    const initialValues = { otp: '' };
    const formik = useFormik({
        initialValues,
        validationSchema: accCreationValidationSchema,
    });

    const handleOtpRequest = () => {
        dispatch(
            commonActions.generateRequestOtp({
                message: `{otp} is your passcode to close the account with alias name ${accountName}. Never share this code.`,
                subject: 'Request to close the account',
                requestPath: `//v1/account/${accountId}/close`,
                requestPayload: {},
                requestMethod: 'POST',
                deliveryChannel: 'email',
            })
        );
    };

    const { values, isValid, errors } = formik;

    const submitCloseAccountRequest = useCallback(() => {
        if (!errors.otp && isValid) {
            dispatch(
                accountActions.closeCustomerAccount({
                    accountId,
                    otp: {
                        otp: values.otp,
                        token: otpToken,
                    },
                })
            );
        }
    }, [values.otp, errors.otp, dispatch, otpToken, isValid, accountId]);

    const handleSubmitOtpResend = () => {
        handleOtpRequest();
        formik.setValues(initialValues);
    };

    const onModalHandle = (val) => {
        dispatch(accountActions.resetCloseCustomerAccount());
        dispatch(commonActions.resetRequestOtp());
        handleModal(val);
    };

    const resetOtpDialog = (state) => {
        if (errorLoadingCloseReq) {
            dispatch(accountActions.resetUpdateAccount());
        }
        setDisplayOtpDialog(state);
    };

    const onActionSubmit = () => {
        onModalHandle(false);
        switch (pageType) {
            case 'summary':
                dispatch(accountActions.getAccountsSummary());
                break;
            case 'accountList':
                if (pageMeta) {
                    const { currency, page, size } = pageMeta;
                    dispatch(
                        accountActions.getCurrencyAccounts({
                            currency,
                            page,
                            size,
                        })
                    );
                }
                break;
            default:
                return;
        }
    };

    useEffect(() => {
        if (displayModal) {
            formik.setValues(initialValues);
        }
    /* eslint-disable */
    }, [displayModal]);

    useEffect(() => {
        if (isLoaded && !errorLoadingCloseReq) {
            setDisplayOtpDialog(false);
        }
    }, [isLoaded, errorLoadingCloseReq]);

    useEffect(() => {
        if (
            hasZeroBalance &&
            displayModal &&
            otpLoaded &&
            otpCount === 0 &&
            otpError.status < 400
        ) {
            setDisplayOtpDialog(true);
        }
    }, [hasZeroBalance, displayModal, otpLoaded, otpCount, otpError.status]);

    return (
        displayModal && (
            <>
                <DialogV2
                    title={'Close account'}
                    open={displayModal}
                    setOpen={onModalHandle}
                    isSingleButton={!hasZeroBalance}
                    submitButton={hasZeroBalance ? 'Yes' : 'Okay'}
                    cancelButton="Cancel"
                    variant={hasZeroBalance ? 'warning' : 'error'}
                    submitAction={() => {
                        if (hasZeroBalance) {
                            handleOtpRequest();
                        } else {
                            onModalHandle(false);
                        }
                    }}
                    disableActions={otpLoading}
                    cancelAction={() => onModalHandle(false)}
                    paperProps={{ position: 'relative' }}>
                    {hasZeroBalance
                        ? `Are you sure you want to close ${accountName} account?`
                        : `To close ${toCapitalize(accountName)} the balance must be zero, and transactions must not be in a processing state.`}
                    {otpLoading && <Loading className={styles.loaderWrapper} />}
                </DialogV2>

                <Dialog
                    title={'Authenticate'}
                    open={displayOtpDialog}
                    isSingleButton={false}
                    setOpen={(val) => {
                        if (!val) {
                            resetOtpDialog(val);
                            formik.setFieldValue('otp', '');
                        }
                    }}
                    fullWidth
                    submitButton={null}
                    isActions={false}
                    backdropProps={{
                        style: {
                            backgroundColor: 'rgba(255,255,255, 0.8)',
                        },
                    }}>
                    <div className={cx(styles.dialog)}>
                        {!errors.name ? (
                            <Otp
                                name={'otp'}
                                heading={'Enter one-time passcode sent to the registered email id.'}
                                otp={values.otp}
                                onChange={(val) => {
                                    if (values.otp?.length === 0 && error.message?.length) {
                                        dispatch(accountActions.resetCloseCustomerAccount());
                                    }
                                    formik.setFieldValue('otp', val);
                                }}
                                disabled={values.otp?.length !== 6}
                                length={6}
                                onSubmit={submitCloseAccountRequest}
                                onResend={handleSubmitOtpResend}
                                error={error}
                                buttonText={'Submit'}
                                otpCount={otpCount}
                                maxTime={45}
                                isSecured={true}
                                loading={loading}
                                addOnStyles={styles.centerLoader}
                            />
                        ) : (
                            <>
                                <StyledOtpButtonWrapper>
                                    <Button
                                        onClick={() => {
                                            setDisplayOtpDialog(!displayOtpDialog);
                                        }}
                                        size="lg"
                                        variant="gradient"
                                        text={'Try Again'}
                                    />
                                </StyledOtpButtonWrapper>
                            </>
                        )}
                    </div>
                </Dialog>

                <DialogV2
                    title={errorLoadingOtp ? 'Error' : 'Success'}
                    open={(isLoaded && !errorLoadingCloseReq) || errorLoadingOtp}
                    setOpen={(state) => {
                        if (!state) {
                            onActionSubmit();
                        }
                    }}
                    isSingleButton={true}
                    submitButton={errorLoadingOtp ? 'Retry' : 'Close'}
                    variant={errorLoadingOtp ? 'error' : 'info'}
                    submitAction={() => {
                        if (errorLoadingOtp) {
                            dispatch(commonActions.resetRequestOtp());
                            return;
                        }
                        onActionSubmit();
                    }}>
                    {errorLoadingOtp ? otpError.message : message}
                </DialogV2>
            </>
        )
    );
}
