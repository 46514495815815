import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import cx from 'classnames';
import styles from 'assets/styles/payments.module.scss';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import styled from 'styled-components';
import { PendingPaymentReview } from 'components/common/Payment';
import { CustomTooltip, Dialog, Icon } from 'components/common';
import { Otp } from 'components/common';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/pendingpayments';
import selectState from 'store/selectors/pendingpayments';
import { getUserTypes } from 'store/selectors/auth';
import { UserType, getCurrentUser } from 'utils/auth';
import { formatAmount, getCurrencySymbol, toDateTimeString } from 'utils/helpers';
import NumberFormat from 'react-number-format';
import DialogV2 from 'components/common/DialogV2';
import TransactionLink from 'components/common/Transaction/TransactionLink';

const TableHeaderRow = styled(TableRow)(() => ({
    backgroundColor: 'rgba(0,160,155,0.19)',
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        backgroundColor: 'rgba(0,160,155,0.05)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PendingPaymentsList = ({ pendingPayments, refreshHandler }) => {
    const {
        approvePaymentOtpToken,
        approvePaymentStatus,
        approvePaymentOtpStatus,
        approvePaymentOtpCount,
        approvePaymentOtpError,
        rejectPendingPaymentStatus,
    } = selectState();

    const dispatch = useDispatch();
    const [paymentActionAllowed, setPaymentActionAllowed] = useState(false);
    const { user_id } = useSelector(getCurrentUser);
    const userTypes = useSelector(getUserTypes);
    const [reviewModal, setReviewModal] = useState(false);
    const [selectedPendingPayment, setSelectedPendingPayment] = useState(null);
    const [approvePaymentModalOpen, setApprovePaymentModalOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [rejectPaymentModalOpen, setRejectPaymentModalOpen] = useState(false);
    const [approvePaymentOtp, setApprovePaymentOtp] = React.useState('');
    const [confirmationModalTitle, setConfirmationModalTitle] = useState('');
    const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);

    const clearReviewData = useCallback(() => {
        setSelectedPendingPayment(null);
        setReviewModal(false);
        dispatch(actions.clearReviewData());
    }, [dispatch]);

    const clearApprovalData = useCallback(() => {
        dispatch(actions.clearApprovalData());
        setApprovePaymentModalOpen(false);
        setApprovePaymentOtp('');
    }, [dispatch]);

    const clearRejectData = useCallback(() => {
        dispatch(actions.clearRejectData());
        setRejectPaymentModalOpen(false);
    }, [dispatch]);

    useEffect(() => {
        if (!approvePaymentOtpStatus) {
            return;
        }
        if (approvePaymentOtpStatus === 'success') {
            setApprovePaymentModalOpen(true);
        } else if (approvePaymentOtpStatus === 'failed'){
            setShowErrorModal(true);
        }
        dispatch(actions.clearApprovePaymentOtpStatus());
    }, [dispatch, approvePaymentOtpStatus]);

    useEffect(() => {
        if (!rejectPendingPaymentStatus) {
            return;
        }
        if (rejectPendingPaymentStatus === 'success') {
            setConfirmationModalTitle('Payment request rejected');
            setConfirmationModalMessage('The payment request is rejected.');
            setConfirmationModalOpen(true);
        }
        if (rejectPendingPaymentStatus === 'failed') {
            alert('Reject Payment failed');
        }
    }, [rejectPendingPaymentStatus, clearRejectData, refreshHandler]);

    useEffect(() => {
        if (!approvePaymentStatus) {
            return;
        }
        if (approvePaymentStatus === 'success') {
            setConfirmationModalTitle('Payment request approved');
            setConfirmationModalMessage(
                'The payment request is approved and submitted for processing.'
            );
            setConfirmationModalOpen(true);
        }
        if (approvePaymentStatus === 'failed') {
            alert('Approve Payment failed');
        }
    }, [approvePaymentStatus, refreshHandler, clearApprovalData]);

    useEffect(() => {
        const allowedRoles = [UserType.Approver];
        setPaymentActionAllowed(
            allowedRoles.some((allowedRole) => userTypes.includes(allowedRole))
        );
    }, [userTypes]);

    const rejectPaymentRequest = useCallback(
        (payment_id) => {
            dispatch(actions.rejectPendingPayments({ payment_id }));
        },
        [dispatch]
    );

    const approvePaymentRequest = useCallback(
        (payment_id) => {
            dispatch(
                actions.approvePendingPayments({
                    payment_id,
                    approvePaymentOtpToken,
                    approvePaymentOtp,
                })
            );
        },
        [dispatch, approvePaymentOtpToken, approvePaymentOtp]
    );

    const reviewPendingPayment = useCallback((payment) => {
        setReviewModal(true);
        setSelectedPendingPayment(payment);
    }, []);

    const isPaymentReqestActive = (payment) => {
        return payment?.status === 'created' || payment?.status === 'retry';
    };

    const isActionAllowed = useCallback(
        (payment) => {
            return (
                isPaymentReqestActive(payment) &&
                payment?.created_by_user_id !== user_id
            );
        },
        [user_id]
    );

    const getPaymentStatus = useCallback((status) => {
        switch (status) {
            case 'processing':
                return 'payment-request-status-processing';
            case 'approved':
                return 'payment-request-status-approved';
            case 'rejected':
                return 'payment-request-status-rejected';
            case 'created':
                return 'payment-request-status-created';
            case 'expired':
                return 'payment-request-status-expired';
            case 'retry':
                return 'payment-request-status-retry';
            default:
                return '';
        }
    }, []);

    const getPaymentStatusTooltip = useCallback((status) => {
        switch (status) {
            case 'processing':
                return 'This request has been approved and is currently being processed.';
            case 'approved':
                return 'This request is approved.';
            case 'rejected':
                return 'This request is rejected.';
            case 'created':
                return 'This request has been created and is available for review.';
            case 'expired':
                return 'This request is expired.';
            case 'retry':
                return 'This request needs to be retried.';
            default:
                return '';
        }
    }, []);

    const handleResend = useCallback(() => {
        setApprovePaymentOtp('');
        dispatch(
            actions.getApprovePaymentOtp({
                amount: selectedPendingPayment?.amount,
                to: selectedPendingPayment?.destination_account_name,
                currency: selectedPendingPayment?.currency,
                payment_id: selectedPendingPayment?.id,
            })
        );
    }, [dispatch, selectedPendingPayment]);

    return (
        <Grid item md={12} xs={12} className={cx(styles.list)}>
            <TableContainer className={cx(styles.table)}>
                <Table>
                    <TableHead>
                        <TableHeaderRow>
                            <TableCell></TableCell>
                            <TableCell>Request ID</TableCell>
                            <TableCell>From Account</TableCell>
                            <TableCell>To Account</TableCell>
                            <TableCell>Reference</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Request Status</TableCell>
                            <TableCell>Details</TableCell>
                        </TableHeaderRow>
                    </TableHead>
                    <TableBody>
                        {pendingPayments?.length > 0 &&
                            pendingPayments.map((payment) => (
                                <StyledTableRow key={payment.alias_id}>
                                    <TableCell>
                                        {paymentActionAllowed && isActionAllowed(payment) && (
                                            <CustomTooltip info={'Click to Approve or Reject'}>
                                                <Icon
                                                    name={'approve-payment-action'}
                                                    disabled={payment.status === 'processing'}
                                                    onClick={() => {
                                                        reviewPendingPayment(payment);
                                                    }}
                                                />
                                            </CustomTooltip>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {isPaymentReqestActive(payment) ? <span
                                            className={cx(styles.view)}
                                            onClick={() => reviewPendingPayment(payment)}>
                                            {payment.alias_id}
                                        </span>: <span>{payment.alias_id}</span>
                                        }
                                    </TableCell>
                                    <TableCell>
                                        <div>{payment.source_account_name}</div>
                                        {payment.source_account_number && (
                                            <div>
                                                <b>Acc#: </b> {payment.source_account_number}
                                            </div>
                                        )}
                                        {payment.source_account_sort_code && (
                                            <div>
                                                <b>Sort code: </b>{' '}
                                                {payment.source_account_sort_code}
                                            </div>
                                        )}
                                        {payment.source_account_iban && (
                                            <div>
                                                <b>IBAN: </b> {payment.source_account_iban}
                                            </div>
                                        )}
                                        {payment.source_account_bic && (
                                            <div>
                                                <b>BIC: </b> {payment.source_account_bic}
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <div>{payment.destination_account_name}</div>
                                        {payment.destination_account_number && (
                                            <div>
                                                <b>Acc#: </b> {payment.destination_account_number}
                                            </div>
                                        )}
                                        {payment.destination_account_sort_code && (
                                            <div>
                                                <b>Sort code: </b>{' '}
                                                {payment.destination_account_sort_code}
                                            </div>
                                        )}
                                        {payment.destination_account_iban && (
                                            <div>
                                                <b>IBAN: </b> {payment.destination_account_iban}
                                            </div>
                                        )}
                                        {payment.destination_account_bic && (
                                            <div>
                                                <b>BIC: </b> {payment.destination_account_bic}
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell>{payment.reference}</TableCell>
                                    <TableCell>
                                        <NumberFormat
                                            value={formatAmount(payment.amount)}
                                            thousandSeparator={true}
                                            displayType={'text'}
                                            decimalScale={2}
                                            prefix={getCurrencySymbol(payment?.currency)}
                                        />
                                    </TableCell>
                                    <TableCell style={{ textTransform: 'capitalize' }}>
                                        <CustomTooltip info={getPaymentStatusTooltip(payment.status)}>
                                            <Icon name={getPaymentStatus(payment.status)} />
                                        </CustomTooltip>
                                    </TableCell>
                                    <TableCell>
                                        {payment.created_at && (
                                            <div>
                                                {' '}
                                                Created by {payment.created_by} on{' '}
                                                {toDateTimeString(payment.created_at)}
                                            </div>
                                        )}
                                        {payment.approved_at && (
                                            <div>
                                                {' '}
                                                Approved by {payment.approved_by} on{' '}
                                                {moment(payment.approved_at).format(
                                                    'DD MMM YYYY HH:mm:ss'
                                                )}
                                            </div>
                                        )}
                                        {payment.transaction_id && (
                                            <div> Transaction ID is <TransactionLink accountId={payment.account_id} transactionId={payment.transaction_id}/></div>
                                        )}
                                        {payment.rejected_at && (
                                            <div>
                                                {' '}
                                                Rejected by {payment.rejected_by} on{' '}
                                                {moment(payment.rejected_at).format(
                                                    'DD MMM YYYY HH:mm:ss'
                                                )}
                                            </div>
                                        )}
                                        {payment.status === 'expired' && (
                                            <div>
                                                {' '}
                                                Expired on{' '}
                                                {moment(payment.expires_at).format(
                                                    'DD MMM YYYY HH:mm:ss'
                                                )}
                                            </div>
                                        )}
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                title="Review payment details"
                fullWidth
                open={reviewModal}
                setOpen={(val) => {
                    setReviewModal(val);
                    clearReviewData();
                }}
                className={'review-modal'}
                isDisable={!paymentActionAllowed}
                submitButton={isActionAllowed(selectedPendingPayment) ? 'Approve' : null}
                cancelButton={isActionAllowed(selectedPendingPayment) ? 'Reject' : null}
                cancelAction={() => setRejectPaymentModalOpen(true)}
                submitAction={() => {
                    handleResend();
                }}
                isSingleButton={false}
                paperProps={{
                    maxWidth: '100%',
                    width: '90%',
                }}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <PendingPaymentReview payment={selectedPendingPayment} />
            </Dialog>

            <Dialog
                title="Authenticate"
                fullWidth
                open={approvePaymentModalOpen}
                isSingleButton={false}
                className={'approve-payment-modal'}
                setOpen={(val) => {
                    clearApprovalData();
                }}
                submitButton={null}
                isActions={false}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <div className={cx(styles.dialog)}>
                    <Otp
                        heading={'Enter the one-time passcode sent to the registered mobile number.'}
                        otp={approvePaymentOtp}
                        onChange={setApprovePaymentOtp}
                        disabled={approvePaymentOtp.length !== 6}
                        length={6}
                        onSubmit={() => approvePaymentRequest(selectedPendingPayment.id)}
                        onResend={handleResend}
                        error={approvePaymentOtpError}
                        buttonText={'Submit'}
                        otpCount={approvePaymentOtpCount}
                        maxTime={45}
                        isSecured={true}
                    />
                </div>
            </Dialog>

            <DialogV2
                title={'Confirm reject payment request'}
                open={rejectPaymentModalOpen}
                setOpen={() => setRejectPaymentModalOpen(false)}
                cancelButton={`Cancel`}
                submitButton={'Confirm'}
                submitAction={() => rejectPaymentRequest(selectedPendingPayment.id)}
                cancelAction={() => setRejectPaymentModalOpen(false)} // On cancel close the modal
                variant={'warning'}
                className={'reject-payment-modal'}>
                Are you sure you want to reject the payment request?
            </DialogV2>

            <DialogV2
                title={confirmationModalTitle}
                open={confirmationModalOpen}
                setOpen={(val) => setConfirmationModalOpen(val)}
                isSingleButton={true}
                submitButton={'Close'}
                submitAction={() => {
                    clearApprovalData();
                    clearRejectData();
                    refreshHandler();
                }}
                variant={'success'}>
                {confirmationModalMessage}
            </DialogV2>
            <DialogV2
                title={'Payment request operation timed out'}
                open={showErrorModal}
                isSingleButton={true}
                setOpen={() => {
                    clearApprovalData();
                    clearRejectData();
                    refreshHandler();
                }}
                submitButton={'Close'}
                variant={'info'}
                submitAction={() => {
                    clearApprovalData();
                    clearRejectData();
                    refreshHandler();
                }}
            >
                <div className={cx(styles.messageText)}>
                    <p>We encountered an unforeseen error while processing your payout request. Please check for this payout under Transactions in the next few minutes before resubmitting the request. Please get in touch with our customer service team at cs@merge.money if you need further assistance. </p>
                </div>                
            </DialogV2>

        </Grid>
    );
};
export default PendingPaymentsList;
