export const paymentPurposeTypes = [
    {
        "value": "MNEANYMAD340",
        "default_purpose_code": 340,
        "default_purpose": "Insurance",
        "additional_purpose": "Ancillary insurance costs",
        "type": "default_purpose",
        "label": "340 - Insurance ; Ancillary insurance costs"
    },
    {
        "value": "MNEANYBRL47025",
        "default_purpose_code": 47025,
        "default_purpose": "Services",
        "additional_purpose": "IT services",
        "type": "default_purpose",
        "label": "47025 - Services ; IT services"
    },
    {
        "value": "MNEANYBRL47128",
        "default_purpose_code": 47128,
        "default_purpose": "Services",
        "additional_purpose": "Legal services",
        "type": "default_purpose",
        "label": "47128 - Services ; Legal services"
    },
    {
        "value": "MNEANYBRL47135",
        "default_purpose_code": 47135,
        "default_purpose": "Services",
        "additional_purpose": "Audit",
        "type": "default_purpose",
        "label": "47135 - Services ; Audit"
    },
    {
        "value": "MNEANYBRL47142",
        "default_purpose_code": 47142,
        "default_purpose": "Services",
        "additional_purpose": "Business and public relations consulting services",
        "type": "default_purpose",
        "label": "47142 - Services ; Business and public relations consulting services"
    },
    {
        "value": "MNEANYBRL47197",
        "default_purpose_code": 47197,
        "default_purpose": "Services",
        "additional_purpose": "Other technical",
        "type": "default_purpose",
        "label": "47197 - Services ; Other technical"
    },
    {
        "value": "MNEANYBRL47609",
        "default_purpose_code": 47609,
        "default_purpose": "Purchase of a property or goods",
        "additional_purpose": "Commissions or expenses on trade transactions",
        "type": "default_purpose",
        "label": "47609 - Purchase of a property or goods ; Commissions or expenses on trade transactions"
    },
    {
        "value": "MNEANYBRL47719",
        "default_purpose_code": 47719,
        "default_purpose": "Services",
        "additional_purpose": "Education services",
        "type": "default_purpose",
        "label": "47719 - Services ; Education services"
    },
    {
        "value": "MNEANYBRL47733",
        "default_purpose_code": 47733,
        "default_purpose": "Services",
        "additional_purpose": "Health services",
        "type": "default_purpose",
        "label": "47733 - Services ; Health services"
    },
    {
        "value": "MNEANYBRL47908",
        "default_purpose_code": 47908,
        "default_purpose": "Other purposes",
        "additional_purpose": "Salary and other compensation",
        "type": "default_purpose",
        "label": "47908 - Other purposes ; Salary and other compensation"
    },
    {
        "value": "MNEJOANY0203",
        "default_purpose_code": "0203",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Private sector staff salaries",
        "type": "default_purpose",
        "label": "0203 - Salaries and wages ; Private sector staff salaries"
    },
    {
        "value": "MNEJOANY0209",
        "default_purpose_code": "0209",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Establishment social security subscription",
        "type": "default_purpose",
        "label": "0209 - Salaries and wages ; Establishment social security subscription"
    },
    {
        "value": "MNEJOANY0404",
        "default_purpose_code": "0404",
        "default_purpose": "Transportation and tourism",
        "additional_purpose": "Travel and tourism",
        "type": "default_purpose",
        "label": "0404 - Transportation and tourism ; Travel and tourism"
    },
    {
        "value": "MNEJOANY0504",
        "default_purpose_code": "0504",
        "default_purpose": "Training and delegation",
        "additional_purpose": "Private sector education",
        "type": "default_purpose",
        "label": "0504 - Training and delegation ; Private sector education"
    },
    {
        "value": "MNEJOANY0801",
        "default_purpose_code": "0801",
        "default_purpose": "Services",
        "additional_purpose": "Telecommunication services",
        "type": "default_purpose",
        "label": "0801 - Services ; Telecommunication services"
    },
    {
        "value": "MNEJOANY0802",
        "default_purpose_code": "0802",
        "default_purpose": "Services",
        "additional_purpose": "Financial services",
        "type": "default_purpose",
        "label": "0802 - Services ; Financial services"
    },
    {
        "value": "MNEJOANY0803",
        "default_purpose_code": "0803",
        "default_purpose": "Services",
        "additional_purpose": "Information Technology services",
        "type": "default_purpose",
        "label": "0803 - Services ; Information Technology services"
    },
    {
        "value": "MNEJOANY0804",
        "default_purpose_code": "0804",
        "default_purpose": "Services",
        "additional_purpose": "Consulting services",
        "type": "default_purpose",
        "label": "0804 - Services ; Consulting services"
    },
    {
        "value": "MNEJOANY0807",
        "default_purpose_code": "0807",
        "default_purpose": "Services",
        "additional_purpose": "Marketing and media services",
        "type": "default_purpose",
        "label": "0807 - Services ; Marketing and media services"
    },
    {
        "value": "MNEJOANY0811",
        "default_purpose_code": "0811",
        "default_purpose": "Services",
        "additional_purpose": "Rental expenses",
        "type": "default_purpose",
        "label": "0811 - Services ; Rental expenses"
    },
    {
        "value": "MNEJOANY0813",
        "default_purpose_code": "0813",
        "default_purpose": "Services",
        "additional_purpose": "Taxes",
        "type": "default_purpose",
        "label": "0813 - Services ; Taxes"
    },
    {
        "value": "MNEJOANY0814",
        "default_purpose_code": "0814",
        "default_purpose": "Services",
        "additional_purpose": "Fees",
        "type": "default_purpose",
        "label": "0814 - Services ; Fees"
    },
    {
        "value": "MNEJOANY0815",
        "default_purpose_code": "0815",
        "default_purpose": "Services",
        "additional_purpose": "Commissions",
        "type": "default_purpose",
        "label": "0815 - Services ; Commissions"
    },
    {
        "value": "MNEANYMAD1042",
        "default_purpose_code": 1042,
        "default_purpose": "Revenues",
        "additional_purpose": "Income from direct investments",
        "type": "default_purpose",
        "label": "1042 - Revenues ; Income from direct investments"
    },
    {
        "value": "MNEANYMAD1100",
        "default_purpose_code": 1100,
        "default_purpose": "Revenues",
        "additional_purpose": "Dividends and distributed profits",
        "type": "default_purpose",
        "label": "1100 - Revenues ; Dividends and distributed profits"
    },
    {
        "value": "MNEKEKES1202",
        "default_purpose_code": 1202,
        "default_purpose": "VAT imports",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1202 - VAT imports"
    },
    {
        "value": "MNEANYMAD1210",
        "default_purpose_code": 1210,
        "default_purpose": "Current transfers",
        "additional_purpose": "Pensions",
        "type": "default_purpose",
        "label": "1210 - Current transfers ; Pensions"
    },
    {
        "value": "MNEANYMAD1220",
        "default_purpose_code": 1220,
        "default_purpose": "Current transfers",
        "additional_purpose": "Contributions to pension funds or provident schemes",
        "type": "default_purpose",
        "label": "1220 - Current transfers ; Contributions to pension funds or provident schemes"
    },
    {
        "value": "MNEKEKES1519",
        "default_purpose_code": 1519,
        "default_purpose": "Registration fees",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1519 - Registration fees"
    },
    {
        "value": "MNEANYMAD180",
        "default_purpose_code": 180,
        "default_purpose": "Goods",
        "additional_purpose": "International trade",
        "type": "default_purpose",
        "label": "180 - Goods ; International trade"
    },
    {
        "value": "MNEKEKES2901",
        "default_purpose_code": 2901,
        "default_purpose": "Income tax - PAYE",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "2901 - Income tax - PAYE"
    },
    {
        "value": "MNEKEKES3001",
        "default_purpose_code": 3001,
        "default_purpose": "Income tax - company",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "3001 - Income tax - company"
    },
    {
        "value": "MNEKEKES3514",
        "default_purpose_code": 3514,
        "default_purpose": "Value Added Tax (VAT)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "3514 - Value Added Tax (VAT)"
    },
    {
        "value": "MNEANYMAD440",
        "default_purpose_code": 440,
        "default_purpose": "Travel",
        "additional_purpose": "Tuition fees",
        "type": "default_purpose",
        "label": "440 - Travel ; Tuition fees"
    },
    {
        "value": "MNEANYMAD441",
        "default_purpose_code": 441,
        "default_purpose": "Travel",
        "additional_purpose": "Students\\u2019 living expenses",
        "type": "default_purpose",
        "label": "441 - Travel ; Students\\u2019 living expenses"
    },
    {
        "value": "MNEANYMAD442",
        "default_purpose_code": 442,
        "default_purpose": "Travel",
        "additional_purpose": "Rents",
        "type": "default_purpose",
        "label": "442 - Travel ; Rents"
    },
    {
        "value": "MNEANYMAD510",
        "default_purpose_code": 510,
        "default_purpose": "Business services",
        "additional_purpose": "Administrative procurement",
        "type": "default_purpose",
        "label": "510 - Business services ; Administrative procurement"
    },
    {
        "value": "MNEANYMAD560",
        "default_purpose_code": 560,
        "default_purpose": "Business services",
        "additional_purpose": "IT services",
        "type": "default_purpose",
        "label": "560 - Business services ; IT services"
    },
    {
        "value": "MNEANYMAD570",
        "default_purpose_code": 570,
        "default_purpose": "Business services",
        "additional_purpose": "Information services",
        "type": "default_purpose",
        "label": "570 - Business services ; Information services"
    },
    {
        "value": "MNEANYMAD585",
        "default_purpose_code": 585,
        "default_purpose": "Business services",
        "additional_purpose": "Research and development",
        "type": "default_purpose",
        "label": "585 - Business services ; Research and development"
    },
    {
        "value": "MNEANYMAD590",
        "default_purpose_code": 590,
        "default_purpose": "Business services",
        "additional_purpose": "Consulting",
        "type": "default_purpose",
        "label": "590 - Business services ; Consulting"
    },
    {
        "value": "MNEANYMAD595",
        "default_purpose_code": 595,
        "default_purpose": "Business services",
        "additional_purpose": "Various business",
        "type": "default_purpose",
        "label": "595 - Business services ; Various business"
    },
    {
        "value": "MNEANYMAD600",
        "default_purpose_code": 600,
        "default_purpose": "Communication services",
        "additional_purpose": "Postal services",
        "type": "default_purpose",
        "label": "600 - Communication services ; Postal services"
    },
    {
        "value": "MNEANYMAD610",
        "default_purpose_code": 610,
        "default_purpose": "Communication services",
        "additional_purpose": "Mail and courier services",
        "type": "default_purpose",
        "label": "610 - Communication services ; Mail and courier services"
    },
    {
        "value": "MNEANYMAD620",
        "default_purpose_code": 620,
        "default_purpose": "Communication services",
        "additional_purpose": "Telecommunication services",
        "type": "default_purpose",
        "label": "620 - Communication services ; Telecommunication services"
    },
    {
        "value": "MNEANYCNYCCTFDR",
        "default_purpose_code": "CCTFDR",
        "default_purpose": "Payment to capital account",
        "additional_purpose": "Capital injection",
        "type": "default_purpose",
        "label": "CCTFDR - Payment to capital account ; Capital injection"
    },
    {
        "value": "MNEANYCNYCCTFDR/PART",
        "default_purpose_code": "CCTFDR/PART",
        "default_purpose": "Payment to capital account",
        "additional_purpose": "Partial return of payment to capital account",
        "type": "default_purpose",
        "label": "CCTFDR/PART - Payment to capital account ; Partial return of payment to capital account"
    },
    {
        "value": "MNEANYCNYCCTFDR/RETN",
        "default_purpose_code": "CCTFDR/RETN",
        "default_purpose": "Payment to capital account",
        "additional_purpose": "Cancellation of payment to capital account",
        "type": "default_purpose",
        "label": "CCTFDR/RETN - Payment to capital account ; Cancellation of payment to capital account"
    },
    {
        "value": "MNEANYCNYCGODDR",
        "default_purpose_code": "CGODDR",
        "default_purpose": "Trade settlement for goods",
        "additional_purpose": "Sale or purchase of goods",
        "type": "default_purpose",
        "label": "CGODDR - Trade settlement for goods ; Sale or purchase of goods"
    },
    {
        "value": "MNEANYCNYCGODDR/PART",
        "default_purpose_code": "CGODDR/PART",
        "default_purpose": "Trade settlement for goods",
        "additional_purpose": "Partial return of trade settlement for goods",
        "type": "default_purpose",
        "label": "CGODDR/PART - Trade settlement for goods ; Partial return of trade settlement for goods"
    },
    {
        "value": "MNEANYCNYCGODDR/RETN",
        "default_purpose_code": "CGODDR/RETN",
        "default_purpose": "Trade settlement for goods",
        "additional_purpose": "Cancellation of trade settlement for goods",
        "type": "default_purpose",
        "label": "CGODDR/RETN - Trade settlement for goods ; Cancellation of trade settlement for goods"
    },
    {
        "value": "MNEAEANYCOM",
        "default_purpose_code": "COM",
        "default_purpose": "Commission",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "COM - Commission"
    },
    {
        "value": "MNEANYCNYCSTRDR",
        "default_purpose_code": "CSTRDR",
        "default_purpose": "Trade settlement for services",
        "additional_purpose": "Public utility",
        "type": "default_purpose",
        "label": "CSTRDR - Trade settlement for services ; Public utility"
    },
    {
        "value": "MNEANYCNYCSTRDR/PART",
        "default_purpose_code": "CSTRDR/PART",
        "default_purpose": "Trade settlement for services",
        "additional_purpose": "Partial return of trade settlement for services",
        "type": "default_purpose",
        "label": "CSTRDR/PART - Trade settlement for services ; Partial return of trade settlement for services"
    },
    {
        "value": "MNEANYCNYCSTRDR/RETN",
        "default_purpose_code": "CSTRDR/RETN",
        "default_purpose": "Trade settlement for services",
        "additional_purpose": "Cancellation of trade settlement for services",
        "type": "default_purpose",
        "label": "CSTRDR/RETN - Trade settlement for services ; Cancellation of trade settlement for services"
    },
    {
        "value": "MNEAEANYDIV",
        "default_purpose_code": "DIV",
        "default_purpose": "Dividend payouts",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "DIV - Dividend payouts"
    },
    {
        "value": "MNEANYINRP1004",
        "default_purpose_code": "P1004",
        "default_purpose": "Other business services",
        "additional_purpose": "Legal services",
        "type": "default_purpose",
        "label": "P1004 - Other business services ; Legal services"
    },
    {
        "value": "MNEANYINRP1005",
        "default_purpose_code": "P1005",
        "default_purpose": "Other business services",
        "additional_purpose": "Accounting",
        "type": "default_purpose",
        "label": "P1005 - Other business services ; Accounting"
    },
    {
        "value": "MNEJOANY0810",
        "default_purpose_code": "0810",
        "default_purpose": "Services",
        "additional_purpose": "Cultural",
        "type": "default_purpose",
        "label": "0810 - Services ; Cultural"
    },
    {
        "value": "MNEANYINRP1007",
        "default_purpose_code": "P1007",
        "default_purpose": "Other business services",
        "additional_purpose": "Advertising",
        "type": "default_purpose",
        "label": "P1007 - Other business services ; Advertising"
    },
    {
        "value": "MNEANYINRP1019",
        "default_purpose_code": "P1019",
        "default_purpose": "Other business services",
        "additional_purpose": "Other services not included elsewhere",
        "type": "default_purpose",
        "label": "P1019 - Other business services ; Other services not included elsewhere"
    },
    {
        "value": "MNEKEKESPAYE",
        "default_purpose_code": "PAYE",
        "default_purpose": "Pay As You Earn (PAYE)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PAYE - Pay As You Earn (PAYE)"
    },
    {
        "value": "MNEAEANYPEN",
        "default_purpose_code": "PEN",
        "default_purpose": "Pension",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PEN - Pension"
    },
    {
        "value": "MNEAEANYSAL",
        "default_purpose_code": "SAL",
        "default_purpose": "Salary",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SAL - Salary"
    },
    {
        "value": "MNEBHANYSAL",
        "default_purpose_code": "SAL",
        "default_purpose": "Personal",
        "additional_purpose": "Salary (compensation of employees)",
        "type": "default_purpose",
        "label": "SAL - Personal ; Salary (compensation of employees)"
    },
    {
        "value": "MNEKEKESSALA",
        "default_purpose_code": "SALA",
        "default_purpose": "Salary payment",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SALA - Salary payment"
    },
    {
        "value": "MNEBHANYSTR",
        "default_purpose_code": "STR",
        "default_purpose": "Transport and travel",
        "additional_purpose": "Travel",
        "type": "default_purpose",
        "label": "STR - Transport and travel ; Travel"
    },
    {
        "value": "MNEAEANYTAX",
        "default_purpose_code": "TAX",
        "default_purpose": "Tax payment",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TAX - Tax payment"
    },
    {
        "value": "MNEKEKESTAXS",
        "default_purpose_code": "TAXS",
        "default_purpose": "Tax payment",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TAXS - Tax payment"
    },
    {
        "value": "MNEKEKESTBIL",
        "default_purpose_code": "TBIL",
        "default_purpose": "Telecommunications bill",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TBIL - Telecommunications bill"
    },
    {
        "value": "MNEKEKESUBIL",
        "default_purpose_code": "UBIL",
        "default_purpose": "Utilities",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "UBIL - Utilities"
    },
    {
        "value": "MNEAEANYUTL",
        "default_purpose_code": "UTL",
        "default_purpose": "Utility bill payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "UTL - Utility bill payments"
    },
    {
        "value": "MNEKEKESVATX",
        "default_purpose_code": "VATX",
        "default_purpose": "Value Added Tax (VAT) payment",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "VATX - Value Added Tax (VAT) payment"
    },
    {
        "value": "MNEANYMAD210",
        "default_purpose_code": 210,
        "default_purpose": "International transport of goods",
        "additional_purpose": "Air freight transport",
        "type": "default_purpose",
        "label": "210 - International transport of goods ; Air freight transport"
    },
    {
        "value": "MNEANYMAD1340",
        "default_purpose_code": 1340,
        "default_purpose": "Foreign investment and private loans in Morocco",
        "additional_purpose": "Loans",
        "type": "default_purpose",
        "label": "1340 - Foreign investment and private loans in Morocco ; Loans"
    },
    {
        "value": "MNEANYBRL37004",
        "default_purpose_code": 37004,
        "default_purpose": "Family support",
        "additional_purpose": "Resident family support",
        "type": "default_purpose",
        "label": "37004 - Family support ; Resident family support"
    },
    {
        "value": "MNEANYBRL37011",
        "default_purpose_code": 37011,
        "default_purpose": "Family support",
        "additional_purpose": "Student family support",
        "type": "default_purpose",
        "label": "37011 - Family support ; Student family support"
    },
    {
        "value": "MNEANYBRL37080",
        "default_purpose_code": 37080,
        "default_purpose": "Donation",
        "additional_purpose": "Donation",
        "type": "default_purpose",
        "label": "37080 - Donation ; Donation"
    },
    {
        "value": "MNEANYBRL37107",
        "default_purpose_code": 37107,
        "default_purpose": "Other purposes",
        "additional_purpose": "Other current transfers",
        "type": "default_purpose",
        "label": "37107 - Other purposes ; Other current transfers"
    },
    {
        "value": "MNEANYBRL47070",
        "default_purpose_code": 47070,
        "default_purpose": "Services",
        "additional_purpose": "Engineering and architecture services",
        "type": "default_purpose",
        "label": "47070 - Services ; Engineering and architecture services"
    },
    {
        "value": "MNEANYBRL47915",
        "default_purpose_code": 47915,
        "default_purpose": "Other purposes",
        "additional_purpose": "Letting",
        "type": "default_purpose",
        "label": "47915 - Other purposes ; Letting"
    },
    {
        "value": "MNEANYBRL67500",
        "default_purpose_code": 67500,
        "default_purpose": "Transfer to own remitter account in Brazil",
        "additional_purpose": "Transfer to own remitter account in Brazil",
        "type": "default_purpose",
        "label": "67500 - Transfer to own remitter account in Brazil ; Transfer to own remitter account in Brazil"
    },
    {
        "value": "MNEANYBRL72904",
        "default_purpose_code": 72904,
        "default_purpose": "Purchase of a property or goods",
        "additional_purpose": "Acquisition of goods delivered in Brazil",
        "type": "default_purpose",
        "label": "72904 - Purchase of a property or goods ; Acquisition of goods delivered in Brazil"
    },
    {
        "value": "MNEANYBRL72911",
        "default_purpose_code": 72911,
        "default_purpose": "Purchase of a property or goods",
        "additional_purpose": "Purchase of a property in Brazil",
        "type": "default_purpose",
        "label": "72911 - Purchase of a property or goods ; Purchase of a property in Brazil"
    },
    {
        "value": "MNEJOANY0101",
        "default_purpose_code": "0101",
        "default_purpose": "Personal",
        "additional_purpose": "Invoice payment and purchase",
        "type": "default_purpose",
        "label": "0101 - Personal ; Invoice payment and purchase"
    },
    {
        "value": "MNEJOANY0102",
        "default_purpose_code": "0102",
        "default_purpose": "Personal",
        "additional_purpose": "Utility bill payment",
        "type": "default_purpose",
        "label": "0102 - Personal ; Utility bill payment"
    },
    {
        "value": "MNEJOANY0103",
        "default_purpose_code": "0103",
        "default_purpose": "Personal",
        "additional_purpose": "Prepaid cards recharging",
        "type": "default_purpose",
        "label": "0103 - Personal ; Prepaid cards recharging"
    },
    {
        "value": "MNEJOANY0104",
        "default_purpose_code": "0104",
        "default_purpose": "Personal",
        "additional_purpose": "Standing orders",
        "type": "default_purpose",
        "label": "0104 - Personal ; Standing orders"
    },
    {
        "value": "MNEJOANY0105",
        "default_purpose_code": "0105",
        "default_purpose": "Personal",
        "additional_purpose": "Personal donations",
        "type": "default_purpose",
        "label": "0105 - Personal ; Personal donations"
    },
    {
        "value": "MNEJOANY0106",
        "default_purpose_code": "0106",
        "default_purpose": "Personal",
        "additional_purpose": "Family assistance and expenses",
        "type": "default_purpose",
        "label": "0106 - Personal ; Family assistance and expenses"
    },
    {
        "value": "MNEJOANY0107",
        "default_purpose_code": "0107",
        "default_purpose": "Personal",
        "additional_purpose": "Individual social security subscription",
        "type": "default_purpose",
        "label": "0107 - Personal ; Individual social security subscription"
    },
    {
        "value": "MNEJOANY0108",
        "default_purpose_code": "0108",
        "default_purpose": "Personal",
        "additional_purpose": "Associations subscriptions",
        "type": "default_purpose",
        "label": "0108 - Personal ; Associations subscriptions"
    },
    {
        "value": "MNEJOANY0109",
        "default_purpose_code": "0109",
        "default_purpose": "Personal",
        "additional_purpose": "Saving and funding account",
        "type": "default_purpose",
        "label": "0109 - Personal ; Saving and funding account"
    },
    {
        "value": "MNEJOANY0110",
        "default_purpose_code": "0110",
        "default_purpose": "Personal",
        "additional_purpose": "Heritance",
        "type": "default_purpose",
        "label": "0110 - Personal ; Heritance"
    },
    {
        "value": "MNEJOANY0111",
        "default_purpose_code": "0111",
        "default_purpose": "Personal",
        "additional_purpose": "End of service indemnity",
        "type": "default_purpose",
        "label": "0111 - Personal ; End of service indemnity"
    },
    {
        "value": "MNEJOANY0201",
        "default_purpose_code": "0201",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Public sector employees salaries",
        "type": "default_purpose",
        "label": "0201 - Salaries and wages ; Public sector employees salaries"
    },
    {
        "value": "MNEJOANY0202",
        "default_purpose_code": "0202",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Laborers salaries",
        "type": "default_purpose",
        "label": "0202 - Salaries and wages ; Laborers salaries"
    },
    {
        "value": "MNEJOANY0204",
        "default_purpose_code": "0204",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Jordanian diplomatic staff salaries",
        "type": "default_purpose",
        "label": "0204 - Salaries and wages ; Jordanian diplomatic staff salaries"
    },
    {
        "value": "MNEJOANY0205",
        "default_purpose_code": "0205",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Foreign diplomatic salaries",
        "type": "default_purpose",
        "label": "0205 - Salaries and wages ; Foreign diplomatic salaries"
    },
    {
        "value": "MNEJOANY0206",
        "default_purpose_code": "0206",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Overseas incoming salaries",
        "type": "default_purpose",
        "label": "0206 - Salaries and wages ; Overseas incoming salaries"
    },
    {
        "value": "MNEJOANY0207",
        "default_purpose_code": "0207",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Civil or military retirement salaries",
        "type": "default_purpose",
        "label": "0207 - Salaries and wages ; Civil or military retirement salaries"
    },
    {
        "value": "MNEJOANY0208",
        "default_purpose_code": "0208",
        "default_purpose": "Salaries and wages",
        "additional_purpose": "Social security retirement salaries",
        "type": "default_purpose",
        "label": "0208 - Salaries and wages ; Social security retirement salaries"
    },
    {
        "value": "MNEJOANY0301",
        "default_purpose_code": "0301",
        "default_purpose": "Investment remittances",
        "additional_purpose": "Investment revenues",
        "type": "default_purpose",
        "label": "0301 - Investment remittances ; Investment revenues"
    },
    {
        "value": "MNEJOANY0302",
        "default_purpose_code": "0302",
        "default_purpose": "Investment remittances",
        "additional_purpose": "Brokerage investment",
        "type": "default_purpose",
        "label": "0302 - Investment remittances ; Brokerage investment"
    },
    {
        "value": "MNEJOANY0303",
        "default_purpose_code": "0303",
        "default_purpose": "Investment remittances",
        "additional_purpose": "Insurance",
        "type": "default_purpose",
        "label": "0303 - Investment remittances ; Insurance"
    },
    {
        "value": "MNEJOANY0304",
        "default_purpose_code": "0304",
        "default_purpose": "Investment remittances",
        "additional_purpose": "Subscriptions to international nonmonetary organizations",
        "type": "default_purpose",
        "label": "0304 - Investment remittances ; Subscriptions to international nonmonetary organizations"
    },
    {
        "value": "MNEJOANY0305",
        "default_purpose_code": "0305",
        "default_purpose": "Investment remittances",
        "additional_purpose": "Local investment",
        "type": "default_purpose",
        "label": "0305 - Investment remittances ; Local investment"
    },
    {
        "value": "MNEJOANY0306",
        "default_purpose_code": "0306",
        "default_purpose": "Investment remittances",
        "additional_purpose": "External investment",
        "type": "default_purpose",
        "label": "0306 - Investment remittances ; External investment"
    },
    {
        "value": "MNEJOANY0307",
        "default_purpose_code": "0307",
        "default_purpose": "Investment remittances",
        "additional_purpose": "Tender bond guarantee",
        "type": "default_purpose",
        "label": "0307 - Investment remittances ; Tender bond guarantee"
    },
    {
        "value": "MNEJOANY0401",
        "default_purpose_code": "0401",
        "default_purpose": "Transportation and tourism",
        "additional_purpose": "Air freight",
        "type": "default_purpose",
        "label": "0401 - Transportation and tourism ; Air freight"
    },
    {
        "value": "MNEJOANY0402",
        "default_purpose_code": "0402",
        "default_purpose": "Transportation and tourism",
        "additional_purpose": "Land freight",
        "type": "default_purpose",
        "label": "0402 - Transportation and tourism ; Land freight"
    },
    {
        "value": "MNEJOANY0403",
        "default_purpose_code": "0403",
        "default_purpose": "Transportation and tourism",
        "additional_purpose": "Sea freight",
        "type": "default_purpose",
        "label": "0403 - Transportation and tourism ; Sea freight"
    },
    {
        "value": "MNEJOANY0501",
        "default_purpose_code": "0501",
        "default_purpose": "Training and delegation",
        "additional_purpose": "Governmental delegation transfers",
        "type": "default_purpose",
        "label": "0501 - Training and delegation ; Governmental delegation transfers"
    },
    {
        "value": "MNEJOANY0502",
        "default_purpose_code": "0502",
        "default_purpose": "Training and delegation",
        "additional_purpose": "Private sector delegation transfers",
        "type": "default_purpose",
        "label": "0502 - Training and delegation ; Private sector delegation transfers"
    },
    {
        "value": "MNEJOANY0503",
        "default_purpose_code": "0503",
        "default_purpose": "Training and delegation",
        "additional_purpose": "Governmental education",
        "type": "default_purpose",
        "label": "0503 - Training and delegation ; Governmental education"
    },
    {
        "value": "MNEJOANY0601",
        "default_purpose_code": "0601",
        "default_purpose": "Import and export",
        "additional_purpose": "Public sector exportation",
        "type": "default_purpose",
        "label": "0601 - Import and export ; Public sector exportation"
    },
    {
        "value": "MNEJOANY0602",
        "default_purpose_code": "0602",
        "default_purpose": "Import and export",
        "additional_purpose": "Private sector exportation",
        "type": "default_purpose",
        "label": "0602 - Import and export ; Private sector exportation"
    },
    {
        "value": "MNEJOANY0603",
        "default_purpose_code": "0603",
        "default_purpose": "Import and export",
        "additional_purpose": "Public sector importation",
        "type": "default_purpose",
        "label": "0603 - Import and export ; Public sector importation"
    },
    {
        "value": "MNEJOANY0604",
        "default_purpose_code": "0604",
        "default_purpose": "Import and export",
        "additional_purpose": "Private sector importation",
        "type": "default_purpose",
        "label": "0604 - Import and export ; Private sector importation"
    },
    {
        "value": "MNEJOANY0701",
        "default_purpose_code": "0701",
        "default_purpose": "External aid",
        "additional_purpose": "Religious communities aid",
        "type": "default_purpose",
        "label": "0701 - External aid ; Religious communities aid"
    },
    {
        "value": "MNEJOANY0702",
        "default_purpose_code": "0702",
        "default_purpose": "External aid",
        "additional_purpose": "International communities aid",
        "type": "default_purpose",
        "label": "0702 - External aid ; International communities aid"
    },
    {
        "value": "MNEJOANY0703",
        "default_purpose_code": "0703",
        "default_purpose": "External aid",
        "additional_purpose": "Arab communities aid",
        "type": "default_purpose",
        "label": "0703 - External aid ; Arab communities aid"
    },
    {
        "value": "MNEJOANY0704",
        "default_purpose_code": "0704",
        "default_purpose": "External aid",
        "additional_purpose": "UN aid",
        "type": "default_purpose",
        "label": "0704 - External aid ; UN aid"
    },
    {
        "value": "MNEJOANY0705",
        "default_purpose_code": "0705",
        "default_purpose": "External aid",
        "additional_purpose": "Charity communities aid",
        "type": "default_purpose",
        "label": "0705 - External aid ; Charity communities aid"
    },
    {
        "value": "MNEJOANY0805",
        "default_purpose_code": "0805",
        "default_purpose": "Services",
        "additional_purpose": "Construction services",
        "type": "default_purpose",
        "label": "0805 - Services ; Construction services"
    },
    {
        "value": "MNEJOANY0806",
        "default_purpose_code": "0806",
        "default_purpose": "Services",
        "additional_purpose": "Maintenance and assembling services",
        "type": "default_purpose",
        "label": "0806 - Services ; Maintenance and assembling services"
    },
    {
        "value": "MNEJOANY0808",
        "default_purpose_code": "0808",
        "default_purpose": "Services",
        "additional_purpose": "Mining services",
        "type": "default_purpose",
        "label": "0808 - Services ; Mining services"
    },
    {
        "value": "MNEJOANY0809",
        "default_purpose_code": "0809",
        "default_purpose": "Services",
        "additional_purpose": "Medical and health services",
        "type": "default_purpose",
        "label": "0809 - Services ; Medical and health services"
    },
    {
        "value": "MNEJOANY0812",
        "default_purpose_code": "0812",
        "default_purpose": "Services",
        "additional_purpose": "Real estate",
        "type": "default_purpose",
        "label": "0812 - Services ; Real estate"
    },
    {
        "value": "MNEJOANY0816",
        "default_purpose_code": "0816",
        "default_purpose": "Services",
        "additional_purpose": "Franchise and license fees",
        "type": "default_purpose",
        "label": "0816 - Services ; Franchise and license fees"
    },
    {
        "value": "MNEJOANY0817",
        "default_purpose_code": "0817",
        "default_purpose": "Services",
        "additional_purpose": "Cheque collection",
        "type": "default_purpose",
        "label": "0817 - Services ; Cheque collection"
    },
    {
        "value": "MNEJOANY0818",
        "default_purpose_code": "0818",
        "default_purpose": "Services",
        "additional_purpose": "Membership fees",
        "type": "default_purpose",
        "label": "0818 - Services ; Membership fees"
    },
    {
        "value": "MNEJOANY0901",
        "default_purpose_code": "0901",
        "default_purpose": "Funding",
        "additional_purpose": "Municipality funds",
        "type": "default_purpose",
        "label": "0901 - Funding ; Municipality funds"
    },
    {
        "value": "MNEJOANY0902",
        "default_purpose_code": "0902",
        "default_purpose": "Funding",
        "additional_purpose": "Government funds",
        "type": "default_purpose",
        "label": "0902 - Funding ; Government funds"
    },
    {
        "value": "MNEJOANY0903",
        "default_purpose_code": "0903",
        "default_purpose": "Funding",
        "additional_purpose": "Private sector funds",
        "type": "default_purpose",
        "label": "0903 - Funding ; Private sector funds"
    },
    {
        "value": "MNEJOANY0904",
        "default_purpose_code": "0904",
        "default_purpose": "Funding",
        "additional_purpose": "External incoming funds",
        "type": "default_purpose",
        "label": "0904 - Funding ; External incoming funds"
    },
    {
        "value": "MNEANYMAD100",
        "default_purpose_code": 100,
        "default_purpose": "Goods",
        "additional_purpose": "Free on board (FOB) imports and exports",
        "type": "default_purpose",
        "label": "100 - Goods ; Free on board (FOB) imports and exports"
    },
    {
        "value": "MNEANYMAD1000",
        "default_purpose_code": 1000,
        "default_purpose": "Other services",
        "additional_purpose": "Operating costs of trade branches and representative offices",
        "type": "default_purpose",
        "label": "1000 - Other services ; Operating costs of trade branches and representative offices"
    },
    {
        "value": "MNEJOANY1001",
        "default_purpose_code": 1001,
        "default_purpose": "Diplomacy",
        "additional_purpose": "International communities and embassies remittances",
        "type": "default_purpose",
        "label": "1001 - Diplomacy ; International communities and embassies remittances"
    },
    {
        "value": "MNEKEKES1001",
        "default_purpose_code": 1001,
        "default_purpose": "Import duty - oil",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1001 - Import duty - oil"
    },
    {
        "value": "MNEJOANY1002",
        "default_purpose_code": 1002,
        "default_purpose": "Diplomacy",
        "additional_purpose": "Permanent diplomatic missions",
        "type": "default_purpose",
        "label": "1002 - Diplomacy ; Permanent diplomatic missions"
    },
    {
        "value": "MNEKEKES1002",
        "default_purpose_code": 1002,
        "default_purpose": "Import duty",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1002 - Import duty"
    },
    {
        "value": "MNEJOANY1003",
        "default_purpose_code": 1003,
        "default_purpose": "Diplomacy",
        "additional_purpose": "Temporary diplomatic missions",
        "type": "default_purpose",
        "label": "1003 - Diplomacy ; Temporary diplomatic missions"
    },
    {
        "value": "MNEJOANY1004",
        "default_purpose_code": 1004,
        "default_purpose": "Diplomacy",
        "additional_purpose": "Jordanian embassies income",
        "type": "default_purpose",
        "label": "1004 - Diplomacy ; Jordanian embassies income"
    },
    {
        "value": "MNEANYMAD1010",
        "default_purpose_code": 1010,
        "default_purpose": "Other services",
        "additional_purpose": "Margin on international trading operations",
        "type": "default_purpose",
        "label": "1010 - Other services ; Margin on international trading operations"
    },
    {
        "value": "MNEANYMAD1020",
        "default_purpose_code": 1020,
        "default_purpose": "Other services",
        "additional_purpose": "Foreign trade related services",
        "type": "default_purpose",
        "label": "1020 - Other services ; Foreign trade related services"
    },
    {
        "value": "MNEANYMAD1021",
        "default_purpose_code": 1021,
        "default_purpose": "Other services",
        "additional_purpose": "Commissions on exports of goods",
        "type": "default_purpose",
        "label": "1021 - Other services ; Commissions on exports of goods"
    },
    {
        "value": "MNEANYMAD1022",
        "default_purpose_code": 1022,
        "default_purpose": "Other services",
        "additional_purpose": "Commissions on exports of services",
        "type": "default_purpose",
        "label": "1022 - Other services ; Commissions on exports of services"
    },
    {
        "value": "MNEANYMAD1030",
        "default_purpose_code": 1030,
        "default_purpose": "Other services",
        "additional_purpose": "Fishing rights and royalties",
        "type": "default_purpose",
        "label": "1030 - Other services ; Fishing rights and royalties"
    },
    {
        "value": "MNEANYMAD1040",
        "default_purpose_code": 1040,
        "default_purpose": "Other services",
        "additional_purpose": "Other royalties",
        "type": "default_purpose",
        "label": "1040 - Other services ; Other royalties"
    },
    {
        "value": "MNEANYMAD110",
        "default_purpose_code": 110,
        "default_purpose": "Goods",
        "additional_purpose": "Cost and freight (CFR) imports and exports",
        "type": "default_purpose",
        "label": "110 - Goods ; Cost and freight (CFR) imports and exports"
    },
    {
        "value": "MNEJOANY1101",
        "default_purpose_code": 1101,
        "default_purpose": "Loans",
        "additional_purpose": "Long-term loans installments (public sector)",
        "type": "default_purpose",
        "label": "1101 - Loans ; Long-term loans installments (public sector)"
    },
    {
        "value": "MNEKEKES1101",
        "default_purpose_code": 1101,
        "default_purpose": "Excise duty - oils",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1101 - Excise duty - oils"
    },
    {
        "value": "MNEJOANY1102",
        "default_purpose_code": 1102,
        "default_purpose": "Loans",
        "additional_purpose": "Long-term loans interest installments (public sector)",
        "type": "default_purpose",
        "label": "1102 - Loans ; Long-term loans interest installments (public sector)"
    },
    {
        "value": "MNEKEKES1102",
        "default_purpose_code": 1102,
        "default_purpose": "Excise duty",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1102 - Excise duty"
    },
    {
        "value": "MNEJOANY1103",
        "default_purpose_code": 1103,
        "default_purpose": "Loans",
        "additional_purpose": "Short-term loans installments (public sector)",
        "type": "default_purpose",
        "label": "1103 - Loans ; Short-term loans installments (public sector)"
    },
    {
        "value": "MNEJOANY1104",
        "default_purpose_code": 1104,
        "default_purpose": "Loans",
        "additional_purpose": "Short-term loans interest installments (public sector)",
        "type": "default_purpose",
        "label": "1104 - Loans ; Short-term loans interest installments (public sector)"
    },
    {
        "value": "MNEJOANY1105",
        "default_purpose_code": 1105,
        "default_purpose": "Loans",
        "additional_purpose": "Long-term loans installments (private sector)",
        "type": "default_purpose",
        "label": "1105 - Loans ; Long-term loans installments (private sector)"
    },
    {
        "value": "MNEJOANY1106",
        "default_purpose_code": 1106,
        "default_purpose": "Loans",
        "additional_purpose": "Long-term loans interest installments (public sector)",
        "type": "default_purpose",
        "label": "1106 - Loans ; Long-term loans interest installments (public sector)"
    },
    {
        "value": "MNEJOANY1107",
        "default_purpose_code": 1107,
        "default_purpose": "Loans",
        "additional_purpose": "Short-term loans installments (private sector)",
        "type": "default_purpose",
        "label": "1107 - Loans ; Short-term loans installments (private sector)"
    },
    {
        "value": "MNEJOANY1108",
        "default_purpose_code": 1108,
        "default_purpose": "Loans",
        "additional_purpose": "Short-term loans interest installments (private sector)",
        "type": "default_purpose",
        "label": "1108 - Loans ; Short-term loans interest installments (private sector)"
    },
    {
        "value": "MNEJOANY1109",
        "default_purpose_code": 1109,
        "default_purpose": "Loans",
        "additional_purpose": "Loans installments against governmental guarantee",
        "type": "default_purpose",
        "label": "1109 - Loans ; Loans installments against governmental guarantee"
    },
    {
        "value": "MNEANYMAD1110",
        "default_purpose_code": 1110,
        "default_purpose": "Revenues",
        "additional_purpose": "Repayment of advances on associates’ current accounts",
        "type": "default_purpose",
        "label": "1110 - Revenues ; Repayment of advances on associates’ current accounts"
    },
    {
        "value": "MNEJOANY1110",
        "default_purpose_code": 1110,
        "default_purpose": "Loans",
        "additional_purpose": "Loans interest installments against governmental guarantee",
        "type": "default_purpose",
        "label": "1110 - Loans ; Loans interest installments against governmental guarantee"
    },
    {
        "value": "MNEANYMAD1111",
        "default_purpose_code": 1111,
        "default_purpose": "Revenues",
        "additional_purpose": "Rental income",
        "type": "default_purpose",
        "label": "1111 - Revenues ; Rental income"
    },
    {
        "value": "MNEJOANY1111",
        "default_purpose_code": 1111,
        "default_purpose": "Loans",
        "additional_purpose": "Credit card payment",
        "type": "default_purpose",
        "label": "1111 - Loans ; Credit card payment"
    },
    {
        "value": "MNEANYMAD1112",
        "default_purpose_code": 1112,
        "default_purpose": "Revenues",
        "additional_purpose": "Income from portfolio investments",
        "type": "default_purpose",
        "label": "1112 - Revenues ; Income from portfolio investments"
    },
    {
        "value": "MNEJOANY1112",
        "default_purpose_code": 1112,
        "default_purpose": "Loans",
        "additional_purpose": "Personal loan payment",
        "type": "default_purpose",
        "label": "1112 - Loans ; Personal loan payment"
    },
    {
        "value": "MNEANYMAD1120",
        "default_purpose_code": 1120,
        "default_purpose": "Revenues",
        "additional_purpose": "Income from equity investments",
        "type": "default_purpose",
        "label": "1120 - Revenues ; Income from equity investments"
    },
    {
        "value": "MNEANYMAD1130",
        "default_purpose_code": 1130,
        "default_purpose": "Revenues",
        "additional_purpose": "Income from debt securities",
        "type": "default_purpose",
        "label": "1130 - Revenues ; Income from debt securities"
    },
    {
        "value": "MNEANYMAD1140",
        "default_purpose_code": 1140,
        "default_purpose": "Revenues",
        "additional_purpose": "Interest on private loans and borrowings",
        "type": "default_purpose",
        "label": "1140 - Revenues ; Interest on private loans and borrowings"
    },
    {
        "value": "MNEANYMAD1150",
        "default_purpose_code": 1150,
        "default_purpose": "Interest on public loans and borrowings",
        "additional_purpose": "Interest on treasury loans and borrowings",
        "type": "default_purpose",
        "label": "1150 - Interest on public loans and borrowings ; Interest on treasury loans and borrowings"
    },
    {
        "value": "MNEANYMAD1160",
        "default_purpose_code": 1160,
        "default_purpose": "Interest on public loans and borrowings",
        "additional_purpose": "Interest on loans and borrowings by state enterprises and institutions",
        "type": "default_purpose",
        "label": "1160 - Interest on public loans and borrowings ; Interest on loans and borrowings by state enterprises and institutions"
    },
    {
        "value": "MNEANYMAD1170",
        "default_purpose_code": 1170,
        "default_purpose": "Income from investments in the financial sector",
        "additional_purpose": "Income from investments of Bank Al-Maghrib",
        "type": "default_purpose",
        "label": "1170 - Income from investments in the financial sector ; Income from investments of Bank Al-Maghrib"
    },
    {
        "value": "MNEANYMAD1180",
        "default_purpose_code": 1180,
        "default_purpose": "Income from investments in the financial sector",
        "additional_purpose": "Income from investments by the financial sector other than other than insurance and reinsurance companies",
        "type": "default_purpose",
        "label": "1180 - Income from investments in the financial sector ; Income from investments by the financial sector other than other than insurance and reinsurance companies"
    },
    {
        "value": "MNEANYMAD1185",
        "default_purpose_code": 1185,
        "default_purpose": "Income from investments in the financial sector",
        "additional_purpose": "Income from investments by insurance and reinsurance companies",
        "type": "default_purpose",
        "label": "1185 - Income from investments in the financial sector ; Income from investments by insurance and reinsurance companies"
    },
    {
        "value": "MNEANYMAD1190",
        "default_purpose_code": 1190,
        "default_purpose": "Income from investments in the financial sector",
        "additional_purpose": "Income from investments by undertakings for collective investment in transferable securities",
        "type": "default_purpose",
        "label": "1190 - Income from investments in the financial sector ; Income from investments by undertakings for collective investment in transferable securities"
    },
    {
        "value": "MNEANYMAD1195",
        "default_purpose_code": 1195,
        "default_purpose": "Income from investments in the financial sector",
        "additional_purpose": "Income from investments by pension funds",
        "type": "default_purpose",
        "label": "1195 - Income from investments in the financial sector ; Income from investments by pension funds"
    },
    {
        "value": "MNEANYMAD120",
        "default_purpose_code": 120,
        "default_purpose": "Goods",
        "additional_purpose": "Cost",
        "type": "default_purpose",
        "label": "120 - Goods ; Cost"
    },
    {
        "value": "MNEANYMAD1200",
        "default_purpose_code": 1200,
        "default_purpose": "Current transfers",
        "additional_purpose": "Savings on income",
        "type": "default_purpose",
        "label": "1200 - Current transfers ; Savings on income"
    },
    {
        "value": "MNEJOANY1201",
        "default_purpose_code": 1201,
        "default_purpose": "General",
        "additional_purpose": "Rerouting",
        "type": "default_purpose",
        "label": "1201 - General ; Rerouting"
    },
    {
        "value": "MNEKEKES1201",
        "default_purpose_code": 1201,
        "default_purpose": "VAT oils",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1201 - VAT oils"
    },
    {
        "value": "MNEJOANY1202",
        "default_purpose_code": 1202,
        "default_purpose": "General",
        "additional_purpose": "Scientific research support",
        "type": "default_purpose",
        "label": "1202 - General ; Scientific research support"
    },
    {
        "value": "MNEKEKES1206",
        "default_purpose_code": 1206,
        "default_purpose": "VAT oils - 8%",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1206 - VAT oils - 8%"
    },
    {
        "value": "MNEANYMAD1230",
        "default_purpose_code": 1230,
        "default_purpose": "Current transfers",
        "additional_purpose": "Current transfers in the form of postal orders",
        "type": "default_purpose",
        "label": "1230 - Current transfers ; Current transfers in the form of postal orders"
    },
    {
        "value": "MNEANYMAD1240",
        "default_purpose_code": 1240,
        "default_purpose": "Current transfers",
        "additional_purpose": "Migrant transfers",
        "type": "default_purpose",
        "label": "1240 - Current transfers ; Migrant transfers"
    },
    {
        "value": "MNEANYMAD125",
        "default_purpose_code": 125,
        "default_purpose": "Goods",
        "additional_purpose": "Ex works (EXW)",
        "type": "default_purpose",
        "label": "125 - Goods ; Ex works (EXW)"
    },
    {
        "value": "MNEANYMAD1250",
        "default_purpose_code": 1250,
        "default_purpose": "Current transfers",
        "additional_purpose": "Bequests and aid",
        "type": "default_purpose",
        "label": "1250 - Current transfers ; Bequests and aid"
    },
    {
        "value": "MNEANYMAD1260",
        "default_purpose_code": 1260,
        "default_purpose": "Current transfers",
        "additional_purpose": "Private donations",
        "type": "default_purpose",
        "label": "1260 - Current transfers ; Private donations"
    },
    {
        "value": "MNEANYMAD1270",
        "default_purpose_code": 1270,
        "default_purpose": "Current transfers",
        "additional_purpose": "Public donations",
        "type": "default_purpose",
        "label": "1270 - Current transfers ; Public donations"
    },
    {
        "value": "MNEANYMAD1280",
        "default_purpose_code": 1280,
        "default_purpose": "Current transfers",
        "additional_purpose": "Other current transfers",
        "type": "default_purpose",
        "label": "1280 - Current transfers ; Other current transfers"
    },
    {
        "value": "MNEANYMAD130",
        "default_purpose_code": 130,
        "default_purpose": "Goods",
        "additional_purpose": "Imports and exports of goods traded on other Incoterms: carriage paid to (CPT)",
        "type": "default_purpose",
        "label": "130 - Goods ; Imports and exports of goods traded on other Incoterms: carriage paid to (CPT)"
    },
    {
        "value": "MNEANYMAD1300",
        "default_purpose_code": 1300,
        "default_purpose": "Foreign investment and private loans in Morocco",
        "additional_purpose": "Direct investments",
        "type": "default_purpose",
        "label": "1300 - Foreign investment and private loans in Morocco ; Direct investments"
    },
    {
        "value": "MNEANYMAD1310",
        "default_purpose_code": 1310,
        "default_purpose": "Foreign investment and private loans in Morocco",
        "additional_purpose": "Portfolio investments",
        "type": "default_purpose",
        "label": "1310 - Foreign investment and private loans in Morocco ; Portfolio investments"
    },
    {
        "value": "MNEANYMAD1315",
        "default_purpose_code": 1315,
        "default_purpose": "Foreign investment and private loans in Morocco",
        "additional_purpose": "Portfolio investments by foreign investment funds",
        "type": "default_purpose",
        "label": "1315 - Foreign investment and private loans in Morocco ; Portfolio investments by foreign investment funds"
    },
    {
        "value": "MNEANYMAD132",
        "default_purpose_code": 132,
        "default_purpose": "Goods",
        "additional_purpose": "Advance payments in respect of free on board (FOB) import and export transactions",
        "type": "default_purpose",
        "label": "132 - Goods ; Advance payments in respect of free on board (FOB) import and export transactions"
    },
    {
        "value": "MNEANYMAD1320",
        "default_purpose_code": 1320,
        "default_purpose": "Foreign investment and private loans in Morocco",
        "additional_purpose": "Advances on associates current accounts",
        "type": "default_purpose",
        "label": "1320 - Foreign investment and private loans in Morocco ; Advances on associates current accounts"
    },
    {
        "value": "MNEANYMAD1330",
        "default_purpose_code": 1330,
        "default_purpose": "Foreign investment and private loans in Morocco",
        "additional_purpose": "Real estate investments",
        "type": "default_purpose",
        "label": "1330 - Foreign investment and private loans in Morocco ; Real estate investments"
    },
    {
        "value": "MNEANYMAD134",
        "default_purpose_code": 134,
        "default_purpose": "Goods",
        "additional_purpose": "Down-payments in respect of CFR (cost and freight) import and export transactions",
        "type": "default_purpose",
        "label": "134 - Goods ; Down-payments in respect of CFR (cost and freight) import and export transactions"
    },
    {
        "value": "MNEANYMAD1350",
        "default_purpose_code": 1350,
        "default_purpose": "Foreign investment and private loans in Morocco",
        "additional_purpose": "Private sector commercial credits",
        "type": "default_purpose",
        "label": "1350 - Foreign investment and private loans in Morocco ; Private sector commercial credits"
    },
    {
        "value": "MNEANYMAD136",
        "default_purpose_code": 136,
        "default_purpose": "Goods",
        "additional_purpose": "Down-payments in respect of CIF (cost",
        "type": "default_purpose",
        "label": "136 - Goods ; Down-payments in respect of CIF (cost"
    },
    {
        "value": "MNEANYMAD1360",
        "default_purpose_code": 1360,
        "default_purpose": "Foreign investment and private loans in Morocco",
        "additional_purpose": "Transactions on convertible term deposit accounts",
        "type": "default_purpose",
        "label": "1360 - Foreign investment and private loans in Morocco ; Transactions on convertible term deposit accounts"
    },
    {
        "value": "MNEANYMAD1370",
        "default_purpose_code": 1370,
        "default_purpose": "Foreign investment and private loans in Morocco",
        "additional_purpose": "Liquidation of French investments in Morocco",
        "type": "default_purpose",
        "label": "1370 - Foreign investment and private loans in Morocco ; Liquidation of French investments in Morocco"
    },
    {
        "value": "MNEANYMAD138",
        "default_purpose_code": 138,
        "default_purpose": "Goods",
        "additional_purpose": "Payment in advance of imports",
        "type": "default_purpose",
        "label": "138 - Goods ; Payment in advance of imports"
    },
    {
        "value": "MNEANYMAD140",
        "default_purpose_code": 140,
        "default_purpose": "Goods",
        "additional_purpose": "Toll operations (added value)",
        "type": "default_purpose",
        "label": "140 - Goods ; Toll operations (added value)"
    },
    {
        "value": "MNEANYMAD1400",
        "default_purpose_code": 1400,
        "default_purpose": "Investments made in Morocco by Moroccans residing abroad (MREs)",
        "additional_purpose": "Direct MRE investments",
        "type": "default_purpose",
        "label": "1400 - Investments made in Morocco by Moroccans residing abroad (MREs) ; Direct MRE investments"
    },
    {
        "value": "MNEANYMAD1410",
        "default_purpose_code": 1410,
        "default_purpose": "Investments made in Morocco by Moroccans residing abroad (MREs)",
        "additional_purpose": "MRE portfolio investments",
        "type": "default_purpose",
        "label": "1410 - Investments made in Morocco by Moroccans residing abroad (MREs) ; MRE portfolio investments"
    },
    {
        "value": "MNEANYMAD1420",
        "default_purpose_code": 1420,
        "default_purpose": "Investments made in Morocco by Moroccans residing abroad (MREs)",
        "additional_purpose": "Advances on MRE associates current accounts",
        "type": "default_purpose",
        "label": "1420 - Investments made in Morocco by Moroccans residing abroad (MREs) ; Advances on MRE associates current accounts"
    },
    {
        "value": "MNEANYMAD1430",
        "default_purpose_code": 1430,
        "default_purpose": "Investments made in Morocco by Moroccans residing abroad (MREs)",
        "additional_purpose": "MRE real estate investments",
        "type": "default_purpose",
        "label": "1430 - Investments made in Morocco by Moroccans residing abroad (MREs) ; MRE real estate investments"
    },
    {
        "value": "MNEANYMAD150",
        "default_purpose_code": 150,
        "default_purpose": "Goods",
        "additional_purpose": "Goods repairs",
        "type": "default_purpose",
        "label": "150 - Goods ; Goods repairs"
    },
    {
        "value": "MNEANYMAD1500",
        "default_purpose_code": 1500,
        "default_purpose": "Moroccan private investments and loans abroad",
        "additional_purpose": "Moroccan direct investments abroad",
        "type": "default_purpose",
        "label": "1500 - Moroccan private investments and loans abroad ; Moroccan direct investments abroad"
    },
    {
        "value": "MNEKEKES1501",
        "default_purpose_code": 1501,
        "default_purpose": "Alteration fee",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1501 - Alteration fee"
    },
    {
        "value": "MNEANYMAD1510",
        "default_purpose_code": 1510,
        "default_purpose": "Moroccan private investments and loans abroad",
        "additional_purpose": "Portfolio investments abroad",
        "type": "default_purpose",
        "label": "1510 - Moroccan private investments and loans abroad ; Portfolio investments abroad"
    },
    {
        "value": "MNEKEKES1518",
        "default_purpose_code": 1518,
        "default_purpose": "Concession fees",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1518 - Concession fees"
    },
    {
        "value": "MNEANYMAD1520",
        "default_purpose_code": 1520,
        "default_purpose": "Moroccan private investments and loans abroad",
        "additional_purpose": "Advances on associates current accounts to non-residents",
        "type": "default_purpose",
        "label": "1520 - Moroccan private investments and loans abroad ; Advances on associates current accounts to non-residents"
    },
    {
        "value": "MNEKEKES1527",
        "default_purpose_code": 1527,
        "default_purpose": "Transhipment fee",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1527 - Transhipment fee"
    },
    {
        "value": "MNEANYMAD1530",
        "default_purpose_code": 1530,
        "default_purpose": "Moroccan private investments and loans abroad",
        "additional_purpose": "Loans granted to non-residents",
        "type": "default_purpose",
        "label": "1530 - Moroccan private investments and loans abroad ; Loans granted to non-residents"
    },
    {
        "value": "MNEANYMAD1540",
        "default_purpose_code": 1540,
        "default_purpose": "Moroccan private investments and loans abroad",
        "additional_purpose": "Banking sector loans",
        "type": "default_purpose",
        "label": "1540 - Moroccan private investments and loans abroad ; Banking sector loans"
    },
    {
        "value": "MNEANYMAD1550",
        "default_purpose_code": 1550,
        "default_purpose": "Moroccan private investments and loans abroad",
        "additional_purpose": "Foreign investments by insurance and reinsurance companies",
        "type": "default_purpose",
        "label": "1550 - Moroccan private investments and loans abroad ; Foreign investments by insurance and reinsurance companies"
    },
    {
        "value": "MNEANYMAD1555",
        "default_purpose_code": 1555,
        "default_purpose": "Moroccan private investments and loans abroad",
        "additional_purpose": "Foreign investments by Undertakings for Collective Investment in Transferable Securities (UCITS)",
        "type": "default_purpose",
        "label": "1555 - Moroccan private investments and loans abroad ; Foreign investments by Undertakings for Collective Investment in Transferable Securities (UCITS)"
    },
    {
        "value": "MNEANYMAD1560",
        "default_purpose_code": 1560,
        "default_purpose": "Moroccan private investments and loans abroad",
        "additional_purpose": "Foreign investments by pension funds",
        "type": "default_purpose",
        "label": "1560 - Moroccan private investments and loans abroad ; Foreign investments by pension funds"
    },
    {
        "value": "MNEANYMAD1565",
        "default_purpose_code": 1565,
        "default_purpose": "Moroccan private investments and loans abroad",
        "additional_purpose": "Export credits",
        "type": "default_purpose",
        "label": "1565 - Moroccan private investments and loans abroad ; Export credits"
    },
    {
        "value": "MNEANYMAD1570",
        "default_purpose_code": 1570,
        "default_purpose": "Moroccan private investments and loans abroad",
        "additional_purpose": "Stock options",
        "type": "default_purpose",
        "label": "1570 - Moroccan private investments and loans abroad ; Stock options"
    },
    {
        "value": "MNEANYMAD1580",
        "default_purpose_code": 1580,
        "default_purpose": "Financial derivatives",
        "additional_purpose": "Financial derivatives",
        "type": "default_purpose",
        "label": "1580 - Financial derivatives ; Financial derivatives"
    },
    {
        "value": "MNEANYMAD160",
        "default_purpose_code": 160,
        "default_purpose": "Goods",
        "additional_purpose": "Industrial (non-monetary) gold",
        "type": "default_purpose",
        "label": "160 - Goods ; Industrial (non-monetary) gold"
    },
    {
        "value": "MNEANYMAD1600",
        "default_purpose_code": 1600,
        "default_purpose": "Public sector financial transactions",
        "additional_purpose": "Treasury loans",
        "type": "default_purpose",
        "label": "1600 - Public sector financial transactions ; Treasury loans"
    },
    {
        "value": "MNEANYMAD1610",
        "default_purpose_code": 1610,
        "default_purpose": "Public sector financial transactions",
        "additional_purpose": "Local authority loans",
        "type": "default_purpose",
        "label": "1610 - Public sector financial transactions ; Local authority loans"
    },
    {
        "value": "MNEANYMAD1620",
        "default_purpose_code": 1620,
        "default_purpose": "Public sector financial transactions",
        "additional_purpose": "State enterprise and institution loans",
        "type": "default_purpose",
        "label": "1620 - Public sector financial transactions ; State enterprise and institution loans"
    },
    {
        "value": "MNEANYMAD1630",
        "default_purpose_code": 1630,
        "default_purpose": "Public sector financial transactions",
        "additional_purpose": "Participation of the Treasury or other state entities in the capital of international organisations",
        "type": "default_purpose",
        "label": "1630 - Public sector financial transactions ; Participation of the Treasury or other state entities in the capital of international organisations"
    },
    {
        "value": "MNEANYMAD170",
        "default_purpose_code": 170,
        "default_purpose": "Goods",
        "additional_purpose": "Reimbursement of proceeds from the collection of export-based receivables",
        "type": "default_purpose",
        "label": "170 - Goods ; Reimbursement of proceeds from the collection of export-based receivables"
    },
    {
        "value": "MNEANYMAD1700",
        "default_purpose_code": 1700,
        "default_purpose": "Accounts in convertible dirhams",
        "additional_purpose": "Transactions on foreign accounts in convertible dirhams of foreign banks or financial institutions",
        "type": "default_purpose",
        "label": "1700 - Accounts in convertible dirhams ; Transactions on foreign accounts in convertible dirhams of foreign banks or financial institutions"
    },
    {
        "value": "MNEANYMAD1702",
        "default_purpose_code": 1702,
        "default_purpose": "Accounts in convertible dirhams",
        "additional_purpose": "Transactions on foreign accounts in convertible dirhams of branches of Moroccan banks abroad or in offshore financial markets",
        "type": "default_purpose",
        "label": "1702 - Accounts in convertible dirhams ; Transactions on foreign accounts in convertible dirhams of branches of Moroccan banks abroad or in offshore financial markets"
    },
    {
        "value": "MNEANYMAD1704",
        "default_purpose_code": 1704,
        "default_purpose": "Accounts in convertible dirhams",
        "additional_purpose": "Transactions on foreign accounts in convertible dirhams of money transfer agencies",
        "type": "default_purpose",
        "label": "1704 - Accounts in convertible dirhams ; Transactions on foreign accounts in convertible dirhams of money transfer agencies"
    },
    {
        "value": "MNEANYMAD1706",
        "default_purpose_code": 1706,
        "default_purpose": "Accounts in convertible dirhams",
        "additional_purpose": "Transactions on foreign accounts in convertible dirhams of foreign investment funds",
        "type": "default_purpose",
        "label": "1706 - Accounts in convertible dirhams ; Transactions on foreign accounts in convertible dirhams of foreign investment funds"
    },
    {
        "value": "MNEANYMAD1710",
        "default_purpose_code": 1710,
        "default_purpose": "Accounts in convertible dirhams",
        "additional_purpose": "Transactions on foreign accounts in convertible dirhams of foreign clients",
        "type": "default_purpose",
        "label": "1710 - Accounts in convertible dirhams ; Transactions on foreign accounts in convertible dirhams of foreign clients"
    },
    {
        "value": "MNEANYMAD1720",
        "default_purpose_code": 1720,
        "default_purpose": "Accounts in convertible dirhams",
        "additional_purpose": "Transactions on accounts in convertible dirhams of Moroccans residing abroad (MREs)",
        "type": "default_purpose",
        "label": "1720 - Accounts in convertible dirhams ; Transactions on accounts in convertible dirhams of Moroccans residing abroad (MREs)"
    },
    {
        "value": "MNEKEKES1801",
        "default_purpose_code": 1801,
        "default_purpose": "IDF fees (2.0%)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1801 - IDF fees (2.0%)"
    },
    {
        "value": "MNEKEKES1802",
        "default_purpose_code": 1802,
        "default_purpose": "IDF/Public Investment Fund (PIF) oil",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1802 - IDF/Public Investment Fund (PIF) oil"
    },
    {
        "value": "MNEANYMAD1810",
        "default_purpose_code": 1810,
        "default_purpose": "Other transactions",
        "additional_purpose": "Repatriations of PayPal funds",
        "type": "default_purpose",
        "label": "1810 - Other transactions ; Repatriations of PayPal funds"
    },
    {
        "value": "MNEANYMAD190",
        "default_purpose_code": 190,
        "default_purpose": "Goods",
        "additional_purpose": "Commercial retrocessions",
        "type": "default_purpose",
        "label": "190 - Goods ; Commercial retrocessions"
    },
    {
        "value": "MNEANYMAD1900",
        "default_purpose_code": 1900,
        "default_purpose": "Other transactions",
        "additional_purpose": "Arbitrage",
        "type": "default_purpose",
        "label": "1900 - Other transactions ; Arbitrage"
    },
    {
        "value": "MNEKEKES1908",
        "default_purpose_code": 1908,
        "default_purpose": "Customs warehouse rent",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "1908 - Customs warehouse rent"
    },
    {
        "value": "MNEANYMAD200",
        "default_purpose_code": 200,
        "default_purpose": "International transport of goods",
        "additional_purpose": "Maritime freight transport",
        "type": "default_purpose",
        "label": "200 - International transport of goods ; Maritime freight transport"
    },
    {
        "value": "MNEANYMAD2000",
        "default_purpose_code": 2000,
        "default_purpose": "Other transactions",
        "additional_purpose": "Retrocessions on foreign private investments and loans",
        "type": "default_purpose",
        "label": "2000 - Other transactions ; Retrocessions on foreign private investments and loans"
    },
    {
        "value": "MNEANYMAD2010",
        "default_purpose_code": 2010,
        "default_purpose": "Other transactions",
        "additional_purpose": "Other financial retrocessions",
        "type": "default_purpose",
        "label": "2010 - Other transactions ; Other financial retrocessions"
    },
    {
        "value": "MNEANYMAD2100",
        "default_purpose_code": 2100,
        "default_purpose": "Special transactions on foreign currency accounts",
        "additional_purpose": "Sales of foreign currencies on the foreign exchange market by debiting a foreign currency account of an exporter",
        "type": "default_purpose",
        "label": "2100 - Special transactions on foreign currency accounts ; Sales of foreign currencies on the foreign exchange market by debiting a foreign currency account of an exporter"
    },
    {
        "value": "MNEKEKES2101",
        "default_purpose_code": 2101,
        "default_purpose": "Road Maintenance Levy (RML)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "2101 - Road Maintenance Levy (RML)"
    },
    {
        "value": "MNEANYMAD2102",
        "default_purpose_code": 2102,
        "default_purpose": "Special transactions on foreign currency accounts",
        "additional_purpose": "Purchases of foreign currencies on the foreign exchange market to credit the foreign currency account of an exporter",
        "type": "default_purpose",
        "label": "2102 - Special transactions on foreign currency accounts ; Purchases of foreign currencies on the foreign exchange market to credit the foreign currency account of an exporter"
    },
    {
        "value": "MNEANYMAD2104",
        "default_purpose_code": 2104,
        "default_purpose": "Special transactions on foreign currency accounts",
        "additional_purpose": "Debit of a foreign currency account of an exporter to credit a foreign currency account of the same exporter opened with a correspondent",
        "type": "default_purpose",
        "label": "2104 - Special transactions on foreign currency accounts ; Debit of a foreign currency account of an exporter to credit a foreign currency account of the same exporter opened with a correspondent"
    },
    {
        "value": "MNEANYMAD2106",
        "default_purpose_code": 2106,
        "default_purpose": "Special transactions on foreign currency accounts",
        "additional_purpose": "Credit of the foreign currency account of an exporter by debiting a foreign currency account of the same exporter opened with a correspondent",
        "type": "default_purpose",
        "label": "2106 - Special transactions on foreign currency accounts ; Credit of the foreign currency account of an exporter by debiting a foreign currency account of the same exporter opened with a correspondent"
    },
    {
        "value": "MNEANYMAD2108",
        "default_purpose_code": 2108,
        "default_purpose": "Special transactions on foreign currency accounts",
        "additional_purpose": "Provision to a correspondent of currency from a foreign currency account of an exporter",
        "type": "default_purpose",
        "label": "2108 - Special transactions on foreign currency accounts ; Provision to a correspondent of currency from a foreign currency account of an exporter"
    },
    {
        "value": "MNEANYMAD2110",
        "default_purpose_code": 2110,
        "default_purpose": "Special transactions on foreign currency accounts",
        "additional_purpose": "Debit of a foreign currency account of an exporter to fund his accounts in convertible dirhams or in ordinary dirhams",
        "type": "default_purpose",
        "label": "2110 - Special transactions on foreign currency accounts ; Debit of a foreign currency account of an exporter to fund his accounts in convertible dirhams or in ordinary dirhams"
    },
    {
        "value": "MNEANYMAD2112",
        "default_purpose_code": 2112,
        "default_purpose": "Special transactions on foreign currency accounts",
        "additional_purpose": "Funding an account in convertible dirhams or ordinary dirhams by debiting the foreign currency account of an exporter",
        "type": "default_purpose",
        "label": "2112 - Special transactions on foreign currency accounts ; Funding an account in convertible dirhams or ordinary dirhams by debiting the foreign currency account of an exporter"
    },
    {
        "value": "MNEANYMAD2114",
        "default_purpose_code": 2114,
        "default_purpose": "Special transactions on foreign currency accounts",
        "additional_purpose": "Funding of foreign currency accounts of clients opened with offshore banks",
        "type": "default_purpose",
        "label": "2114 - Special transactions on foreign currency accounts ; Funding of foreign currency accounts of clients opened with offshore banks"
    },
    {
        "value": "MNEANYMAD220",
        "default_purpose_code": 220,
        "default_purpose": "International transport of goods",
        "additional_purpose": "Road freight transport",
        "type": "default_purpose",
        "label": "220 - International transport of goods ; Road freight transport"
    },
    {
        "value": "MNEANYMAD230",
        "default_purpose_code": 230,
        "default_purpose": "International transport of goods",
        "additional_purpose": "Rail freight transport",
        "type": "default_purpose",
        "label": "230 - International transport of goods ; Rail freight transport"
    },
    {
        "value": "MNEKEKES2301",
        "default_purpose_code": 2301,
        "default_purpose": "Petroleum Regulatory Levy (PRL)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "2301 - Petroleum Regulatory Levy (PRL)"
    },
    {
        "value": "MNEANYMAD235",
        "default_purpose_code": 235,
        "default_purpose": "International transport of goods",
        "additional_purpose": "Transport by gas pipeline",
        "type": "default_purpose",
        "label": "235 - International transport of goods ; Transport by gas pipeline"
    },
    {
        "value": "MNEANYMAD240",
        "default_purpose_code": 240,
        "default_purpose": "International passenger transport",
        "additional_purpose": "Maritime passenger transport",
        "type": "default_purpose",
        "label": "240 - International passenger transport ; Maritime passenger transport"
    },
    {
        "value": "MNEANYMAD250",
        "default_purpose_code": 250,
        "default_purpose": "International passenger transport",
        "additional_purpose": "Air passenger transport",
        "type": "default_purpose",
        "label": "250 - International passenger transport ; Air passenger transport"
    },
    {
        "value": "MNEKEKES2501",
        "default_purpose_code": 2501,
        "default_purpose": "Gross payment - Petroleum Development Fund (PDF)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "2501 - Gross payment - Petroleum Development Fund (PDF)"
    },
    {
        "value": "MNEANYMAD260",
        "default_purpose_code": 260,
        "default_purpose": "International passenger transport",
        "additional_purpose": "Road passenger transport",
        "type": "default_purpose",
        "label": "260 - International passenger transport ; Road passenger transport"
    },
    {
        "value": "MNEANYMAD270",
        "default_purpose_code": 270,
        "default_purpose": "International passenger transport",
        "additional_purpose": "Rail passenger transport",
        "type": "default_purpose",
        "label": "270 - International passenger transport ; Rail passenger transport"
    },
    {
        "value": "MNEANYMAD280",
        "default_purpose_code": 280,
        "default_purpose": "International passenger transport",
        "additional_purpose": "Removals",
        "type": "default_purpose",
        "label": "280 - International passenger transport ; Removals"
    },
    {
        "value": "MNEANYMAD300",
        "default_purpose_code": 300,
        "default_purpose": "Insurance",
        "additional_purpose": "Goods insurance",
        "type": "default_purpose",
        "label": "300 - Insurance ; Goods insurance"
    },
    {
        "value": "MNEANYMAD310",
        "default_purpose_code": 310,
        "default_purpose": "Insurance",
        "additional_purpose": "Insurance other than goods insurance",
        "type": "default_purpose",
        "label": "310 - Insurance ; Insurance other than goods insurance"
    },
    {
        "value": "MNEKEKES3100",
        "default_purpose_code": 3100,
        "default_purpose": "Income tax - resident individual",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "3100 - Income tax - resident individual"
    },
    {
        "value": "MNEKEKES3101",
        "default_purpose_code": 3101,
        "default_purpose": "Monthly rental income tax",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "3101 - Monthly rental income tax"
    },
    {
        "value": "MNEKEKES3103",
        "default_purpose_code": 3103,
        "default_purpose": "Withholding rental income",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "3103 - Withholding rental income"
    },
    {
        "value": "MNEANYMAD320",
        "default_purpose_code": 320,
        "default_purpose": "Insurance",
        "additional_purpose": "Reinsurance operations",
        "type": "default_purpose",
        "label": "320 - Insurance ; Reinsurance operations"
    },
    {
        "value": "MNEKEKES3200",
        "default_purpose_code": 3200,
        "default_purpose": "Income tax - withholding",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "3200 - Income tax - withholding"
    },
    {
        "value": "MNEANYMAD322",
        "default_purpose_code": 322,
        "default_purpose": "Insurance",
        "additional_purpose": "Cash claims",
        "type": "default_purpose",
        "label": "322 - Insurance ; Cash claims"
    },
    {
        "value": "MNEANYMAD330",
        "default_purpose_code": 330,
        "default_purpose": "Insurance",
        "additional_purpose": "Insurance settlements",
        "type": "default_purpose",
        "label": "330 - Insurance ; Insurance settlements"
    },
    {
        "value": "MNEKEKES3304",
        "default_purpose_code": 3304,
        "default_purpose": "Motor vehicle advance tax",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "3304 - Motor vehicle advance tax"
    },
    {
        "value": "MNEANYMAD350",
        "default_purpose_code": 350,
        "default_purpose": "Insurance",
        "additional_purpose": "Life insurance",
        "type": "default_purpose",
        "label": "350 - Insurance ; Life insurance"
    },
    {
        "value": "MNEKEKES3509",
        "default_purpose_code": 3509,
        "default_purpose": "VAT - withholding",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "3509 - VAT - withholding"
    },
    {
        "value": "MNEKEKES3801",
        "default_purpose_code": 3801,
        "default_purpose": "Standards levy",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "3801 - Standards levy"
    },
    {
        "value": "MNEANYMAD400",
        "default_purpose_code": 400,
        "default_purpose": "Travel",
        "additional_purpose": "Tourism",
        "type": "default_purpose",
        "label": "400 - Travel ; Tourism"
    },
    {
        "value": "MNEANYMAD410",
        "default_purpose_code": 410,
        "default_purpose": "Travel",
        "additional_purpose": "Medical care",
        "type": "default_purpose",
        "label": "410 - Travel ; Medical care"
    },
    {
        "value": "MNEKEKES4103",
        "default_purpose_code": 4103,
        "default_purpose": "Stamp duty",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "4103 - Stamp duty"
    },
    {
        "value": "MNEANYMAD420",
        "default_purpose_code": 420,
        "default_purpose": "Travel",
        "additional_purpose": "Pilgrimage",
        "type": "default_purpose",
        "label": "420 - Travel ; Pilgrimage"
    },
    {
        "value": "MNEANYMAD430",
        "default_purpose_code": 430,
        "default_purpose": "Travel",
        "additional_purpose": "Umrah",
        "type": "default_purpose",
        "label": "430 - Travel ; Umrah"
    },
    {
        "value": "MNEKEKES4301",
        "default_purpose_code": 4301,
        "default_purpose": "State Department for Fisheries",
        "additional_purpose": " Aquaculture and the Blue Economy",
        "type": "default_purpose",
        "label": "4301 - State Department for Fisheries ;  Aquaculture and the Blue Economy"
    },
    {
        "value": "MNEANYMAD443",
        "default_purpose_code": 443,
        "default_purpose": "Travel",
        "additional_purpose": "Students’ purchase of computer equipment",
        "type": "default_purpose",
        "label": "443 - Travel ; Students’ purchase of computer equipment"
    },
    {
        "value": "MNEANYMAD444",
        "default_purpose_code": 444,
        "default_purpose": "Travel",
        "additional_purpose": "Student internship expenses",
        "type": "default_purpose",
        "label": "444 - Travel ; Student internship expenses"
    },
    {
        "value": "MNEANYMAD445",
        "default_purpose_code": 445,
        "default_purpose": "Travel",
        "additional_purpose": "Student loan repayment",
        "type": "default_purpose",
        "label": "445 - Travel ; Student loan repayment"
    },
    {
        "value": "MNEKEKES4601",
        "default_purpose_code": 4601,
        "default_purpose": "Import health certificate",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "4601 - Import health certificate"
    },
    {
        "value": "MNEKEKES4702",
        "default_purpose_code": 4702,
        "default_purpose": "Nuts and oils import declaration form",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "4702 - Nuts and oils import declaration form"
    },
    {
        "value": "MNEANYMAD500",
        "default_purpose_code": 500,
        "default_purpose": "Business services",
        "additional_purpose": "Building and public works services provided by foreign companies in Morocco",
        "type": "default_purpose",
        "label": "500 - Business services ; Building and public works services provided by foreign companies in Morocco"
    },
    {
        "value": "MNEANYMAD505",
        "default_purpose_code": 505,
        "default_purpose": "Business services",
        "additional_purpose": "Building and public works services provided by Moroccan companies abroad",
        "type": "default_purpose",
        "label": "505 - Business services ; Building and public works services provided by Moroccan companies abroad"
    },
    {
        "value": "MNEKEKES512 ",
        "default_purpose_code": 512,
        "default_purpose": "National Industrial Training Authority",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "512 - National Industrial Training Authority"
    },
    {
        "value": "MNEANYMAD520",
        "default_purpose_code": 520,
        "default_purpose": "Business services",
        "additional_purpose": "Patent and licence leases",
        "type": "default_purpose",
        "label": "520 - Business services ; Patent and licence leases"
    },
    {
        "value": "MNEANYMAD530",
        "default_purpose_code": 530,
        "default_purpose": "Business services",
        "additional_purpose": "Trademark use",
        "type": "default_purpose",
        "label": "530 - Business services ; Trademark use"
    },
    {
        "value": "MNEANYMAD540",
        "default_purpose_code": 540,
        "default_purpose": "Business services",
        "additional_purpose": "Private technical assistance",
        "type": "default_purpose",
        "label": "540 - Business services ; Private technical assistance"
    },
    {
        "value": "MNEANYMAD550",
        "default_purpose_code": 550,
        "default_purpose": "Business services",
        "additional_purpose": "Construction costs in Morocco",
        "type": "default_purpose",
        "label": "550 - Business services ; Construction costs in Morocco"
    },
    {
        "value": "MNEANYMAD555",
        "default_purpose_code": 555,
        "default_purpose": "Business services",
        "additional_purpose": "Construction costs abroad",
        "type": "default_purpose",
        "label": "555 - Business services ; Construction costs abroad"
    },
    {
        "value": "MNEANYMAD580",
        "default_purpose_code": 580,
        "default_purpose": "Business services",
        "additional_purpose": "Royalties in respect of the exploitation of audio-visual products",
        "type": "default_purpose",
        "label": "580 - Business services ; Royalties in respect of the exploitation of audio-visual products"
    },
    {
        "value": "MNEKEKES6001",
        "default_purpose_code": 6001,
        "default_purpose": "Kenya Railway Development Levy (RDL)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "6001 - Kenya Railway Development Levy (RDL)"
    },
    {
        "value": "MNEKEKES6002",
        "default_purpose_code": 6002,
        "default_purpose": "Kenya Railway Development Levy (RDL) - oils",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "6002 - Kenya Railway Development Levy (RDL) - oils"
    },
    {
        "value": "MNEKEKES6101",
        "default_purpose_code": 6101,
        "default_purpose": "Sale of single number plate",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "6101 - Sale of single number plate"
    },
    {
        "value": "MNEKEKES6102",
        "default_purpose_code": 6102,
        "default_purpose": "Sale of pair of number plates",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "6102 - Sale of pair of number plates"
    },
    {
        "value": "MNEANYMAD630",
        "default_purpose_code": 630,
        "default_purpose": "Communication services",
        "additional_purpose": "Receipts from call centres",
        "type": "default_purpose",
        "label": "630 - Communication services ; Receipts from call centres"
    },
    {
        "value": "MNEKEKES6301",
        "default_purpose_code": 6301,
        "default_purpose": "Transfer fees for motor vehicle registration",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "6301 - Transfer fees for motor vehicle registration"
    },
    {
        "value": "MNEKEKES6401",
        "default_purpose_code": 6401,
        "default_purpose": "Merchant shipping superintendent levy",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "6401 - Merchant shipping superintendent levy"
    },
    {
        "value": "MNEKEKES6402",
        "default_purpose_code": 6402,
        "default_purpose": "Merchant shipping superintendent (MSS) levy - oils",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "6402 - Merchant shipping superintendent (MSS) levy - oils"
    },
    {
        "value": "MNEKEKES6501",
        "default_purpose_code": 6501,
        "default_purpose": "Road safety fund",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "6501 - Road safety fund"
    },
    {
        "value": "MNEKEKES6601",
        "default_purpose_code": 6601,
        "default_purpose": "Second hand motor vehicles (SHMV) purchase tax",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "6601 - Second hand motor vehicles (SHMV) purchase tax"
    },
    {
        "value": "MNEANYMAD700",
        "default_purpose_code": 700,
        "default_purpose": "Personal cultural and leisure services",
        "additional_purpose": "Services related to the production of films and radio and television programmes",
        "type": "default_purpose",
        "label": "700 - Personal cultural and leisure services ; Services related to the production of films and radio and television programmes"
    },
    {
        "value": "MNEANYMAD710",
        "default_purpose_code": 710,
        "default_purpose": "Personal cultural and leisure services",
        "additional_purpose": "Fees to artists and sportspeople",
        "type": "default_purpose",
        "label": "710 - Personal cultural and leisure services ; Fees to artists and sportspeople"
    },
    {
        "value": "MNEANYMAD715",
        "default_purpose_code": 715,
        "default_purpose": "Personal cultural and leisure services",
        "additional_purpose": "Literary",
        "type": "default_purpose",
        "label": "715 - Personal cultural and leisure services ; Literary"
    },
    {
        "value": "MNEANYMAD720",
        "default_purpose_code": 720,
        "default_purpose": "Personal cultural and leisure services",
        "additional_purpose": "Training services",
        "type": "default_purpose",
        "label": "720 - Personal cultural and leisure services ; Training services"
    },
    {
        "value": "MNEANYMAD725",
        "default_purpose_code": 725,
        "default_purpose": "Personal cultural and leisure services",
        "additional_purpose": "Healthcare services",
        "type": "default_purpose",
        "label": "725 - Personal cultural and leisure services ; Healthcare services"
    },
    {
        "value": "MNEANYMAD730",
        "default_purpose_code": 730,
        "default_purpose": "Personal cultural and leisure services",
        "additional_purpose": "Other personal cultural and recreational services",
        "type": "default_purpose",
        "label": "730 - Personal cultural and leisure services ; Other personal cultural and recreational services"
    },
    {
        "value": "MNEANYMAD800",
        "default_purpose_code": 800,
        "default_purpose": "Services provided or received by public administrations",
        "additional_purpose": "Moroccan diplomatic posts",
        "type": "default_purpose",
        "label": "800 - Services provided or received by public administrations ; Moroccan diplomatic posts"
    },
    {
        "value": "MNEANYMAD810",
        "default_purpose_code": 810,
        "default_purpose": "Services provided or received by public administrations",
        "additional_purpose": "Diplomatic posts abroad",
        "type": "default_purpose",
        "label": "810 - Services provided or received by public administrations ; Diplomatic posts abroad"
    },
    {
        "value": "MNEANYMAD820",
        "default_purpose_code": 820,
        "default_purpose": "Services provided or received by public administrations",
        "additional_purpose": "Expenses of international organisations",
        "type": "default_purpose",
        "label": "820 - Services provided or received by public administrations ; Expenses of international organisations"
    },
    {
        "value": "MNEANYMAD830",
        "default_purpose_code": 830,
        "default_purpose": "Services provided or received by public administrations",
        "additional_purpose": "Operations of the National Defence Administration",
        "type": "default_purpose",
        "label": "830 - Services provided or received by public administrations ; Operations of the National Defence Administration"
    },
    {
        "value": "MNEANYMAD840",
        "default_purpose_code": 840,
        "default_purpose": "Services provided or received by public administrations",
        "additional_purpose": "Other government operations",
        "type": "default_purpose",
        "label": "840 - Services provided or received by public administrations ; Other government operations"
    },
    {
        "value": "MNEANYMAD900",
        "default_purpose_code": 900,
        "default_purpose": "Financial services",
        "additional_purpose": "Financial intermediation services",
        "type": "default_purpose",
        "label": "900 - Financial services ; Financial intermediation services"
    },
    {
        "value": "MNEANYMAD910",
        "default_purpose_code": 910,
        "default_purpose": "Financial services",
        "additional_purpose": "Securities transaction services",
        "type": "default_purpose",
        "label": "910 - Financial services ; Securities transaction services"
    },
    {
        "value": "MNEANYMAD920",
        "default_purpose_code": 920,
        "default_purpose": "Financial services",
        "additional_purpose": "Other financial services",
        "type": "default_purpose",
        "label": "920 - Financial services ; Other financial services"
    },
    {
        "value": "MNEANYMAD930",
        "default_purpose_code": 930,
        "default_purpose": "Financial services",
        "additional_purpose": "Commissions related to the use of IMF resources",
        "type": "default_purpose",
        "label": "930 - Financial services ; Commissions related to the use of IMF resources"
    },
    {
        "value": "MNEANYMAD940",
        "default_purpose_code": 940,
        "default_purpose": "Financial services",
        "additional_purpose": "Bank guarantees",
        "type": "default_purpose",
        "label": "940 - Financial services ; Bank guarantees"
    },
    {
        "value": "MNEAEANYACM",
        "default_purpose_code": "ACM",
        "default_purpose": "Agency commissions",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ACM - Agency commissions"
    },
    {
        "value": "MNEKEKESADTX",
        "default_purpose_code": "ADTX",
        "default_purpose": "Advance tax",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ADTX - Advance tax"
    },
    {
        "value": "MNEAEANYAES",
        "default_purpose_code": "AES",
        "default_purpose": "Advance payment against end of service",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "AES - Advance payment against end of service"
    },
    {
        "value": "MNEAEANYAFA",
        "default_purpose_code": "AFA",
        "default_purpose": "Receipts or payments from personal residents bank account or deposits abroad",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "AFA - Receipts or payments from personal residents bank account or deposits abroad"
    },
    {
        "value": "MNEBHANYAFA",
        "default_purpose_code": "AFA",
        "default_purpose": "Lending: repayments and transfers by residents of loans and deposits abroad",
        "additional_purpose": "Receipts or payments from personal residents bank account or deposits abroad",
        "type": "default_purpose",
        "label": "AFA - Lending: repayments and transfers by residents of loans and deposits abroad ; Receipts or payments from personal residents bank account or deposits abroad"
    },
    {
        "value": "MNEAEANYAFL",
        "default_purpose_code": "AFL",
        "default_purpose": "Receipts or payments from personal non-resident bank account in the UAE",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "AFL - Receipts or payments from personal non-resident bank account in the UAE"
    },
    {
        "value": "MNEBHANYAFL",
        "default_purpose_code": "AFL",
        "default_purpose": "Lending: repayments and transfers by non-residents of loans and deposits in Bahrain",
        "additional_purpose": "Receipts or payments from personal non-residents bank account in Bahrain",
        "type": "default_purpose",
        "label": "AFL - Lending: repayments and transfers by non-residents of loans and deposits in Bahrain ; Receipts or payments from personal non-residents bank account in Bahrain"
    },
    {
        "value": "MNEKEKESAIRB",
        "default_purpose_code": "AIRB",
        "default_purpose": "Air transport",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "AIRB - Air transport"
    },
    {
        "value": "MNEAEANYALW",
        "default_purpose_code": "ALW",
        "default_purpose": "Allowance",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ALW - Allowance"
    },
    {
        "value": "MNEKEKESARTX",
        "default_purpose_code": "ARTX",
        "default_purpose": "Agency revenue",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ARTX - Agency revenue"
    },
    {
        "value": "MNEAEANYATS",
        "default_purpose_code": "ATS",
        "default_purpose": "Air transport",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ATS - Air transport"
    },
    {
        "value": "MNEBHANYATS",
        "default_purpose_code": "ATS",
        "default_purpose": "Transport and travel",
        "additional_purpose": "Air transport",
        "type": "default_purpose",
        "label": "ATS - Transport and travel ; Air transport"
    },
    {
        "value": "MNEKEKESBECH",
        "default_purpose_code": "BECH",
        "default_purpose": "Child benefit",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "BECH - Child benefit"
    },
    {
        "value": "MNEAEANYBON",
        "default_purpose_code": "BON",
        "default_purpose": "Bonus",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "BON - Bonus"
    },
    {
        "value": "MNEKEKESBTTX",
        "default_purpose_code": "BTTX",
        "default_purpose": "Betting tax",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "BTTX - Betting tax"
    },
    {
        "value": "MNEKEKESBUSB",
        "default_purpose_code": "BUSB",
        "default_purpose": "Bus",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "BUSB - Bus"
    },
    {
        "value": "MNEANYCNYCCDNDR",
        "default_purpose_code": "CCDNDR",
        "default_purpose": "Charity donation",
        "additional_purpose": "Charity donation",
        "type": "default_purpose",
        "label": "CCDNDR - Charity donation ; Charity donation"
    },
    {
        "value": "MNEANYCNYCCDNDR/RETN",
        "default_purpose_code": "CCDNDR/RETN",
        "default_purpose": "Charity donation",
        "additional_purpose": "Cancellation of charity donation",
        "type": "default_purpose",
        "label": "CCDNDR/RETN - Charity donation ; Cancellation of charity donation"
    },
    {
        "value": "MNEAEANYCCP",
        "default_purpose_code": "CCP",
        "default_purpose": "Corporate card payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CCP - Corporate card payments"
    },
    {
        "value": "MNEAEANYCEA",
        "default_purpose_code": "CEA",
        "default_purpose": "Equity for establishment of new company from residents abroad equity of merger or acquisition of companies abroad from residents and participation to capital increase of related company abroad",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CEA - Equity for establishment of new company from residents abroad equity of merger or acquisition of companies abroad from residents and participation to capital increase of related company abroad"
    },
    {
        "value": "MNEBHANYCEA",
        "default_purpose_code": "CEA",
        "default_purpose": "Foreign Direct Investment (FDI) flows: acquisition and liquidation by residents of equity and securities abroad (above 10% share)",
        "additional_purpose": "Equity and investment fund shares for the establishment of new company from residents abroad",
        "type": "default_purpose",
        "label": "CEA - Foreign Direct Investment (FDI) flows: acquisition and liquidation by residents of equity and securities abroad (above 10% share) ; Equity and investment fund shares for the establishment of new company from residents abroad"
    },
    {
        "value": "MNEAEANYCEL",
        "default_purpose_code": "CEL",
        "default_purpose": "Equity for establishment of new company in UAE from non-residents equity of merger or acquisition of companies in the UAE from non-residents participation to capital increase of related",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CEL - Equity for establishment of new company in UAE from non-residents equity of merger or acquisition of companies in the UAE from non-residents participation to capital increase of related"
    },
    {
        "value": "MNEBHANYCEL",
        "default_purpose_code": "CEL",
        "default_purpose": "FDI flows: acquisition and liquidation by residents of equity and securities in Bahrain (above 10% share)",
        "additional_purpose": "Equity and investment fund shares for the establishment of new company in Bahrain from non-residents",
        "type": "default_purpose",
        "label": "CEL - FDI flows: acquisition and liquidation by residents of equity and securities in Bahrain (above 10% share) ; Equity and investment fund shares for the establishment of new company in Bahrain from non-residents"
    },
    {
        "value": "MNEKEKESCERE",
        "default_purpose_code": "CERE",
        "default_purpose": "Ceremonies",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CERE - Ceremonies"
    },
    {
        "value": "MNEKEKESCFR",
        "default_purpose_code": "CFR",
        "default_purpose": "Cost and freight",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CFR - Cost and freight"
    },
    {
        "value": "MNEKEKESCGTX",
        "default_purpose_code": "CGTX",
        "default_purpose": "Capital Gains Tax (CGT)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CGTX - Capital Gains Tax (CGT)"
    },
    {
        "value": "MNEAEANYCHC",
        "default_purpose_code": "CHC",
        "default_purpose": "Charitable contributions",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CHC - Charitable contributions"
    },
    {
        "value": "MNEBHANYCHC",
        "default_purpose_code": "CHC",
        "default_purpose": "Personal",
        "additional_purpose": "Charitable contributions (charity and aid)",
        "type": "default_purpose",
        "label": "CHC - Personal ; Charitable contributions (charity and aid)"
    },
    {
        "value": "MNEKEKESCHC",
        "default_purpose_code": "CHC",
        "default_purpose": "Charitable contributions (charity and aid)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CHC - Charitable contributions (charity and aid)"
    },
    {
        "value": "MNEAEANYCIN",
        "default_purpose_code": "CIN",
        "default_purpose": "Commercial investments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CIN - Commercial investments"
    },
    {
        "value": "MNEKEKESCLOT",
        "default_purpose_code": "CLOT",
        "default_purpose": "Clothing",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CLOT - Clothing"
    },
    {
        "value": "MNEANYCNYCOCADR",
        "default_purpose_code": "COCADR",
        "default_purpose": "Payment to current account",
        "additional_purpose": "Dividend payment",
        "type": "default_purpose",
        "label": "COCADR - Payment to current account ; Dividend payment"
    },
    {
        "value": "MNEANYCNYCOCADR/RETN",
        "default_purpose_code": "COCADR/RETN",
        "default_purpose": "Payment to current account",
        "additional_purpose": "Cancellation of payment to current account",
        "type": "default_purpose",
        "label": "COCADR/RETN - Payment to current account ; Cancellation of payment to current account"
    },
    {
        "value": "MNEKEKESCOMU",
        "default_purpose_code": "COMU",
        "default_purpose": "Community development",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "COMU - Community development"
    },
    {
        "value": "MNEKEKESCONS",
        "default_purpose_code": "CONS",
        "default_purpose": "Construction activities",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "CONS - Construction activities"
    },
    {
        "value": "MNEAEANYCOP",
        "default_purpose_code": "COP",
        "default_purpose": "Compensation",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "COP - Compensation"
    },
    {
        "value": "MNEKEKESCOTX",
        "default_purpose_code": "COTX",
        "default_purpose": "Corporate tax identification",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "COTX - Corporate tax identification"
    },
    {
        "value": "MNEAEANYDCP",
        "default_purpose_code": "DCP",
        "default_purpose": "Debit card payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "DCP - Debit card payments"
    },
    {
        "value": "MNEAEANYIFS",
        "default_purpose_code": "IFS",
        "default_purpose": "Information services",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "IFS - Information services"
    },
    {
        "value": "MNEAEANYDIF",
        "default_purpose_code": "DIF",
        "default_purpose": "Debt instruments intra group loans securities deposits foreign",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "DIF - Debt instruments intra group loans securities deposits foreign"
    },
    {
        "value": "MNEAEANYDIL",
        "default_purpose_code": "DIL",
        "default_purpose": "Debt instruments intra group loans securities deposits in the UAE",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "DIL - Debt instruments intra group loans securities deposits in the UAE"
    },
    {
        "value": "MNEAEANYDLA",
        "default_purpose_code": "DLA",
        "default_purpose": "Purchases and sales of foreign debt securities more than a year in the related companies",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "DLA - Purchases and sales of foreign debt securities more than a year in the related companies"
    },
    {
        "value": "MNEBHANYDLA",
        "default_purpose_code": "DLA",
        "default_purpose": "Portfolio investment flows: acquisition and liquidation by residents of equity and securities abroad (below 10% share)",
        "additional_purpose": "Purchases and sales of foreign debt securities in not related companies (more than a year)",
        "type": "default_purpose",
        "label": "DLA - Portfolio investment flows: acquisition and liquidation by residents of equity and securities abroad (below 10% share) ; Purchases and sales of foreign debt securities in not related companies (more than a year)"
    },
    {
        "value": "MNEAEANYDLF",
        "default_purpose_code": "DLF",
        "default_purpose": "Debt instruments intra group loans or deposits foreign (above 10% share)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "DLF - Debt instruments intra group loans or deposits foreign (above 10% share)"
    },
    {
        "value": "MNEBHANYDLF",
        "default_purpose_code": "DLF",
        "default_purpose": "Lending: repayments and transfers by residents of loans and deposits abroad",
        "additional_purpose": "Debt instruments intragroup loans",
        "type": "default_purpose",
        "label": "DLF - Lending: repayments and transfers by residents of loans and deposits abroad ; Debt instruments intragroup loans"
    },
    {
        "value": "MNEAEANYDLL",
        "default_purpose_code": "DLL",
        "default_purpose": "Purchases and sales of securities issued by residents more than year in the related companies",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "DLL - Purchases and sales of securities issued by residents more than year in the related companies"
    },
    {
        "value": "MNEBHANYDLL",
        "default_purpose_code": "DLL",
        "default_purpose": "Portfolio investment flows: acquisition and liquidation by residents of equity and securities in Bahrain (below 10% share)",
        "additional_purpose": "Purchases and sales of securities issued by residents in not related companies (more than a year)",
        "type": "default_purpose",
        "label": "DLL - Portfolio investment flows: acquisition and liquidation by residents of equity and securities in Bahrain (below 10% share) ; Purchases and sales of securities issued by residents in not related companies (more than a year)"
    },
    {
        "value": "MNEAEANYDOE",
        "default_purpose_code": "DOE",
        "default_purpose": "Dividends on equity not intra group",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "DOE - Dividends on equity not intra group"
    },
    {
        "value": "MNEBHANYDOE",
        "default_purpose_code": "DOE",
        "default_purpose": "Interest and profits with abroad",
        "additional_purpose": "Dividends on equity not intragroup",
        "type": "default_purpose",
        "label": "DOE - Interest and profits with abroad ; Dividends on equity not intragroup"
    },
    {
        "value": "MNEAEANYDSA",
        "default_purpose_code": "DSA",
        "default_purpose": "Purchases and sales of foreign debt securities less than a year in the related companies",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "DSA - Purchases and sales of foreign debt securities less than a year in the related companies"
    },
    {
        "value": "MNEBHANYDSA",
        "default_purpose_code": "DSA",
        "default_purpose": "Portfolio investment flows: acquisition and liquidation by residents of equity and securities abroad (below 10% share)",
        "additional_purpose": "Purchases and sales of foreign debt securities in not related companies (less than a year)",
        "type": "default_purpose",
        "label": "DSA - Portfolio investment flows: acquisition and liquidation by residents of equity and securities abroad (below 10% share) ; Purchases and sales of foreign debt securities in not related companies (less than a year)"
    },
    {
        "value": "MNEBHANYDSF",
        "default_purpose_code": "DSF",
        "default_purpose": "Foreign Direct Investment (FDI) flows: acquisition and liquidation by residents of equity and securities abroad (above 10% share)",
        "additional_purpose": "Debt instruments intragroup foreign securities",
        "type": "default_purpose",
        "label": "DSF - Foreign Direct Investment (FDI) flows: acquisition and liquidation by residents of equity and securities abroad (above 10% share) ; Debt instruments intragroup foreign securities"
    },
    {
        "value": "MNEAEANYDSL",
        "default_purpose_code": "DSL",
        "default_purpose": "Purchases and sales of securities issued by residents less than year in the related companies",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "DSL - Purchases and sales of securities issued by residents less than year in the related companies"
    },
    {
        "value": "MNEBHANYDSL",
        "default_purpose_code": "DSL",
        "default_purpose": "Portfolio investment flows: acquisition and liquidation by residents of equity and securities in Bahrain (below 10% share)",
        "additional_purpose": "Purchases and sales of securities issued by residents in not related companies (less than a year)",
        "type": "default_purpose",
        "label": "DSL - Portfolio investment flows: acquisition and liquidation by residents of equity and securities in Bahrain (below 10% share) ; Purchases and sales of securities issued by residents in not related companies (less than a year)"
    },
    {
        "value": "MNEKEKESEDTX",
        "default_purpose_code": "EDTX",
        "default_purpose": "Excise duty",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "EDTX - Excise duty"
    },
    {
        "value": "MNEAEANYEDU",
        "default_purpose_code": "EDU",
        "default_purpose": "Educational support",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "EDU - Educational support"
    },
    {
        "value": "MNEKEKESEDUC",
        "default_purpose_code": "EDUC",
        "default_purpose": "Education expenses",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "EDUC - Education expenses"
    },
    {
        "value": "MNEAEANYEMI",
        "default_purpose_code": "EMI",
        "default_purpose": "Equated monthly installments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "EMI - Equated monthly installments"
    },
    {
        "value": "MNEAEANYEOS",
        "default_purpose_code": "EOS",
        "default_purpose": "End of service or final settlement",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "EOS - End of service or final settlement"
    },
    {
        "value": "MNEBHANYFAM",
        "default_purpose_code": "FAM",
        "default_purpose": "Personal",
        "additional_purpose": "Family support (workers’ remittances)",
        "type": "default_purpose",
        "label": "FAM - Personal ; Family support (workers’ remittances)"
    },
    {
        "value": "MNEKEKESFARM",
        "default_purpose_code": "FARM",
        "default_purpose": "Farming",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "FARM - Farming"
    },
    {
        "value": "MNEAEANYFDA",
        "default_purpose_code": "FDA",
        "default_purpose": "Financial derivatives foreign",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "FDA - Financial derivatives foreign"
    },
    {
        "value": "MNEBHANYFDA",
        "default_purpose_code": "FDA",
        "default_purpose": "Portfolio investment flows: acquisition and liquidation by residents of equity and securities abroad (below 10% share)",
        "additional_purpose": "Financial derivatives foreign",
        "type": "default_purpose",
        "label": "FDA - Portfolio investment flows: acquisition and liquidation by residents of equity and securities abroad (below 10% share) ; Financial derivatives foreign"
    },
    {
        "value": "MNEAEANYFDL",
        "default_purpose_code": "FDL",
        "default_purpose": "Financial derivatives in the UAE",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "FDL - Financial derivatives in the UAE"
    },
    {
        "value": "MNEBHANYFDL",
        "default_purpose_code": "FDL",
        "default_purpose": "Portfolio investment flows: acquisition and liquidation by residents of equity and securities in Bahrain (below 10% share)",
        "additional_purpose": "Financial derivatives in Bahrain",
        "type": "default_purpose",
        "label": "FDL - Portfolio investment flows: acquisition and liquidation by residents of equity and securities in Bahrain (below 10% share) ; Financial derivatives in Bahrain"
    },
    {
        "value": "MNEAEANYFIA",
        "default_purpose_code": "FIA",
        "default_purpose": "Investment fund shares foreign",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "FIA - Investment fund shares foreign"
    },
    {
        "value": "MNEBHANYFIA",
        "default_purpose_code": "FIA",
        "default_purpose": "Portfolio investment flows: acquisition and liquidation by residents of equity and securities abroad (below 10% share)",
        "additional_purpose": "Investment fund shares foreign",
        "type": "default_purpose",
        "label": "FIA - Portfolio investment flows: acquisition and liquidation by residents of equity and securities abroad (below 10% share) ; Investment fund shares foreign"
    },
    {
        "value": "MNEAEANYFIL",
        "default_purpose_code": "FIL",
        "default_purpose": "Investment fund shares in the UAE",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "FIL - Investment fund shares in the UAE"
    },
    {
        "value": "MNEBHANYFIL",
        "default_purpose_code": "FIL",
        "default_purpose": "Portfolio investment flows: acquisition and liquidation by residents of equity and securities in Bahrain (below 10% share)",
        "additional_purpose": "Investment fund shares in Bahrain",
        "type": "default_purpose",
        "label": "FIL - Portfolio investment flows: acquisition and liquidation by residents of equity and securities in Bahrain (below 10% share) ; Investment fund shares in Bahrain"
    },
    {
        "value": "MNEAEANYFIS",
        "default_purpose_code": "FIS",
        "default_purpose": "Financial services",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "FIS - Financial services"
    },
    {
        "value": "MNEBHANYFIS",
        "default_purpose_code": "FIS",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Financial services",
        "type": "default_purpose",
        "label": "FIS - Services with abroad ; Financial services"
    },
    {
        "value": "MNEKEKESFOEX",
        "default_purpose_code": "FOEX",
        "default_purpose": "Foreign exchange",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "FOEX - Foreign exchange"
    },
    {
        "value": "MNEAEANYFSA",
        "default_purpose_code": "FSA",
        "default_purpose": "Equity other than investment fund shares in the related companies abroad",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "FSA - Equity other than investment fund shares in the related companies abroad"
    },
    {
        "value": "MNEBHANYFSA",
        "default_purpose_code": "FSA",
        "default_purpose": "Portfolio investment flows: acquisition and liquidation by residents of equity and securities abroad (below 10% share)",
        "additional_purpose": "Equity other than investment fund shares in not related companies abroad",
        "type": "default_purpose",
        "label": "FSA - Portfolio investment flows: acquisition and liquidation by residents of equity and securities abroad (below 10% share) ; Equity other than investment fund shares in not related companies abroad"
    },
    {
        "value": "MNEAEANYFSL",
        "default_purpose_code": "FSL",
        "default_purpose": "Equity other than investment fund shares in the related companies in the UAE",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "FSL - Equity other than investment fund shares in the related companies in the UAE"
    },
    {
        "value": "MNEBHANYFSL",
        "default_purpose_code": "FSL",
        "default_purpose": "Portfolio investment flows: acquisition and liquidation by residents of equity and securities in Bahrain (below 10% share)",
        "additional_purpose": "Equity other than investment fund shares in not related companies in Bahrain",
        "type": "default_purpose",
        "label": "FSL - Portfolio investment flows: acquisition and liquidation by residents of equity and securities in Bahrain (below 10% share) ; Equity other than investment fund shares in not related companies in Bahrain"
    },
    {
        "value": "MNEKEKESFUEL",
        "default_purpose_code": "FUEL",
        "default_purpose": "Fuel expenses",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "FUEL - Fuel expenses"
    },
    {
        "value": "MNEAEANYGDE",
        "default_purpose_code": "GDE",
        "default_purpose": "Goods sold - exports in free on board (FOB) value",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "GDE - Goods sold - exports in free on board (FOB) value"
    },
    {
        "value": "MNEBHANYGDE",
        "default_purpose_code": "GDE",
        "default_purpose": "Import or export",
        "additional_purpose": "Goods sold - exports in free on board (FOB) value",
        "type": "default_purpose",
        "label": "GDE - Import or export ; Goods sold - exports in free on board (FOB) value"
    },
    {
        "value": "MNEAEANYGDI",
        "default_purpose_code": "GDI",
        "default_purpose": "Goods bought - imports in cost",
        "additional_purpose": " insurance and freight (CIF) value",
        "type": "default_purpose",
        "label": "GDI - Goods bought - imports in cost ;  insurance and freight (CIF) value"
    },
    {
        "value": "MNEBHANYGDI",
        "default_purpose_code": "GDI",
        "default_purpose": "Import or export",
        "additional_purpose": "Goods bought - imports in cost",
        "type": "default_purpose",
        "label": "GDI - Import or export ; Goods bought - imports in cost"
    },
    {
        "value": "MNEAEANYGDS",
        "default_purpose_code": "GDS",
        "default_purpose": "Goods bought or sold",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "GDS - Goods bought or sold"
    },
    {
        "value": "MNEAEANYGMS",
        "default_purpose_code": "GMS",
        "default_purpose": "Processing repair and maintenance services on goods",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "GMS - Processing repair and maintenance services on goods"
    },
    {
        "value": "MNEBHANYGMS",
        "default_purpose_code": "GMS",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Processing repair and maintenance services on goods",
        "type": "default_purpose",
        "label": "GMS - Services with abroad ; Processing repair and maintenance services on goods"
    },
    {
        "value": "MNEKEKESGOKX",
        "default_purpose_code": "GOKX",
        "default_purpose": "Government related payments and transfers",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "GOKX - Government related payments and transfers"
    },
    {
        "value": "MNEAEANYGOS",
        "default_purpose_code": "GOS",
        "default_purpose": "Government goods and services embassies etc.",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "GOS - Government goods and services embassies etc."
    },
    {
        "value": "MNEBHANYGOS",
        "default_purpose_code": "GOS",
        "default_purpose": "Government",
        "additional_purpose": "Government goods and services embassies etc.",
        "type": "default_purpose",
        "label": "GOS - Government ; Government goods and services embassies etc."
    },
    {
        "value": "MNEKEKESGOVT",
        "default_purpose_code": "GOVT",
        "default_purpose": "Government payment",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "GOVT - Government payment"
    },
    {
        "value": "MNEAEANYGRI",
        "default_purpose_code": "GRI",
        "default_purpose": "Government related income",
        "additional_purpose": " taxes",
        "type": "default_purpose",
        "label": "GRI - Government related income ;  taxes"
    },
    {
        "value": "MNEBHANYGRI",
        "default_purpose_code": "GRI",
        "default_purpose": "Government",
        "additional_purpose": "Government related income taxes",
        "type": "default_purpose",
        "label": "GRI - Government ; Government related income taxes"
    },
    {
        "value": "MNEKEKESHLFD",
        "default_purpose_code": "HLFD",
        "default_purpose": "Purchase of food and household goods",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "HLFD - Purchase of food and household goods"
    },
    {
        "value": "MNEKEKESHLTI",
        "default_purpose_code": "HLTI",
        "default_purpose": "Health insurance",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "HLTI - Health insurance"
    },
    {
        "value": "MNEBHANYIFS",
        "default_purpose_code": "IFS",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Information services",
        "type": "default_purpose",
        "label": "IFS - Services with abroad ; Information services"
    },
    {
        "value": "MNEAEANYIGD",
        "default_purpose_code": "IGD",
        "default_purpose": "Intra group dividends",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "IGD - Intra group dividends"
    },
    {
        "value": "MNEBHANYIGD",
        "default_purpose_code": "IGD",
        "default_purpose": "Interest and profits with abroad",
        "additional_purpose": "Dividends intragroup",
        "type": "default_purpose",
        "label": "IGD - Interest and profits with abroad ; Dividends intragroup"
    },
    {
        "value": "MNEAEANYIGT",
        "default_purpose_code": "IGT",
        "default_purpose": "Inter group transfer",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "IGT - Inter group transfer"
    },
    {
        "value": "MNEAEANYIID",
        "default_purpose_code": "IID",
        "default_purpose": "Intra group interest on debt",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "IID - Intra group interest on debt"
    },
    {
        "value": "MNEBHANYIID",
        "default_purpose_code": "IID",
        "default_purpose": "Interest and profits with abroad",
        "additional_purpose": "Interest on debt intragroup",
        "type": "default_purpose",
        "label": "IID - Interest and profits with abroad ; Interest on debt intragroup"
    },
    {
        "value": "MNEKEKESINPC",
        "default_purpose_code": "INPC",
        "default_purpose": "Insurance premium car",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "INPC - Insurance premium car"
    },
    {
        "value": "MNEAEANYINS",
        "default_purpose_code": "INS",
        "default_purpose": "Insurance services",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "INS - Insurance services"
    },
    {
        "value": "MNEBHANYINS",
        "default_purpose_code": "INS",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Insurance services",
        "type": "default_purpose",
        "label": "INS - Services with abroad ; Insurance services"
    },
    {
        "value": "MNEKEKESINSU",
        "default_purpose_code": "INSU",
        "default_purpose": "Insurance premium",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "INSU - Insurance premium"
    },
    {
        "value": "MNEKEKESINTE",
        "default_purpose_code": "INTE",
        "default_purpose": "Interest",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "INTE - Interest"
    },
    {
        "value": "MNEKEKESINTX",
        "default_purpose_code": "INTX",
        "default_purpose": "Income tax",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "INTX - Income tax"
    },
    {
        "value": "MNEKEKESINVS",
        "default_purpose_code": "INVS",
        "default_purpose": "Investment and securities",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "INVS - Investment and securities"
    },
    {
        "value": "MNEAEANYIOD",
        "default_purpose_code": "IOD",
        "default_purpose": "Income on deposits",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "IOD - Income on deposits"
    },
    {
        "value": "MNEBHANYIOD",
        "default_purpose_code": "IOD",
        "default_purpose": "Interest and profits with abroad",
        "additional_purpose": "Income on deposits",
        "type": "default_purpose",
        "label": "IOD - Interest and profits with abroad ; Income on deposits"
    },
    {
        "value": "MNEAEANYIOL",
        "default_purpose_code": "IOL",
        "default_purpose": "Income on loans",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "IOL - Income on loans"
    },
    {
        "value": "MNEBHANYIOL",
        "default_purpose_code": "IOL",
        "default_purpose": "Interest and profits with abroad",
        "additional_purpose": "Income on loans",
        "type": "default_purpose",
        "label": "IOL - Interest and profits with abroad ; Income on loans"
    },
    {
        "value": "MNEAEANYIPC",
        "default_purpose_code": "IPC",
        "default_purpose": "Charges for the use of intellectual property royalties",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "IPC - Charges for the use of intellectual property royalties"
    },
    {
        "value": "MNEBHANYIPC",
        "default_purpose_code": "IPC",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Charges for the use of intellectual property royalties",
        "type": "default_purpose",
        "label": "IPC - Services with abroad ; Charges for the use of intellectual property royalties"
    },
    {
        "value": "MNEAEANYIPO",
        "default_purpose_code": "IPO",
        "default_purpose": "IPO subscriptions",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "IPO - IPO subscriptions"
    },
    {
        "value": "MNEAEANYIRP",
        "default_purpose_code": "IRP",
        "default_purpose": "Interest rate swap payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "IRP - Interest rate swap payments"
    },
    {
        "value": "MNEAEANYIRW",
        "default_purpose_code": "IRW",
        "default_purpose": "Interest rate unwind payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "IRW - Interest rate unwind payments"
    },
    {
        "value": "MNEAEANYISH",
        "default_purpose_code": "ISH",
        "default_purpose": "Income on investment funds shares",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ISH - Income on investment funds shares"
    },
    {
        "value": "MNEBHANYISH",
        "default_purpose_code": "ISH",
        "default_purpose": "Interest and profits with abroad",
        "additional_purpose": "Income on investment funds shares",
        "type": "default_purpose",
        "label": "ISH - Interest and profits with abroad ; Income on investment funds shares"
    },
    {
        "value": "MNEAEANYISL",
        "default_purpose_code": "ISL",
        "default_purpose": "Interest on securities (more than a year)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ISL - Interest on securities (more than a year)"
    },
    {
        "value": "MNEBHANYISL",
        "default_purpose_code": "ISL",
        "default_purpose": "Interest and profits with abroad",
        "additional_purpose": "Interest on securities more than a year",
        "type": "default_purpose",
        "label": "ISL - Interest and profits with abroad ; Interest on securities more than a year"
    },
    {
        "value": "MNEAEANYISS",
        "default_purpose_code": "ISS",
        "default_purpose": "Interest on securities (less than a year)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ISS - Interest on securities (less than a year)"
    },
    {
        "value": "MNEBHANYISS",
        "default_purpose_code": "ISS",
        "default_purpose": "Interest and profits with abroad",
        "additional_purpose": "Interest on securities less than a year",
        "type": "default_purpose",
        "label": "ISS - Interest and profits with abroad ; Interest on securities less than a year"
    },
    {
        "value": "MNEKEKESISTX",
        "default_purpose_code": "ISTX",
        "default_purpose": "Instalment tax",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ISTX - Instalment tax"
    },
    {
        "value": "MNEAEANYITS",
        "default_purpose_code": "ITS",
        "default_purpose": "Computer services",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ITS - Computer services"
    },
    {
        "value": "MNEBHANYITS",
        "default_purpose_code": "ITS",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Computer services",
        "type": "default_purpose",
        "label": "ITS - Services with abroad ; Computer services"
    },
    {
        "value": "MNEAEANYLAS",
        "default_purpose_code": "LAS",
        "default_purpose": "Leave salary",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LAS - Leave salary"
    },
    {
        "value": "MNEAEANYLDL",
        "default_purpose_code": "LDL",
        "default_purpose": "Debt instruments intra group loans or deposits in the UAE (above 10% share)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LDL - Debt instruments intra group loans or deposits in the UAE (above 10% share)"
    },
    {
        "value": "MNEBHANYLDL",
        "default_purpose_code": "LDL",
        "default_purpose": "Lending: repayments and transfers by non-residents of loans and deposits in Bahrain",
        "additional_purpose": "Debt instruments intragroup loans",
        "type": "default_purpose",
        "label": "LDL - Lending: repayments and transfers by non-residents of loans and deposits in Bahrain ; Debt instruments intragroup loans"
    },
    {
        "value": "MNEAEANYLDS",
        "default_purpose_code": "LDS",
        "default_purpose": "Debt instruments intra group securities in the UAE",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LDS - Debt instruments intra group securities in the UAE"
    },
    {
        "value": "MNEBHANYLDS",
        "default_purpose_code": "LDS",
        "default_purpose": "FDI flows: acquisition and liquidation by residents of equity and securities in Bahrain (above 10% share)",
        "additional_purpose": "Debt instruments intragroup securities in Bahrain",
        "type": "default_purpose",
        "label": "LDS - FDI flows: acquisition and liquidation by residents of equity and securities in Bahrain (above 10% share) ; Debt instruments intragroup securities in Bahrain"
    },
    {
        "value": "MNEAEANYLEA",
        "default_purpose_code": "LEA",
        "default_purpose": "Leasing abroad",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LEA - Leasing abroad"
    },
    {
        "value": "MNEBHANYLEA",
        "default_purpose_code": "LEA",
        "default_purpose": "Lending: repayments and transfers by residents of loans and deposits abroad",
        "additional_purpose": "Leasing abroad",
        "type": "default_purpose",
        "label": "LEA - Lending: repayments and transfers by residents of loans and deposits abroad ; Leasing abroad"
    },
    {
        "value": "MNEAEANYLEL",
        "default_purpose_code": "LEL",
        "default_purpose": "Leasing in the UAE",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LEL - Leasing in the UAE"
    },
    {
        "value": "MNEBHANYLEL",
        "default_purpose_code": "LEL",
        "default_purpose": "Lending: repayments and transfers by non-residents of loans and deposits in Bahrain",
        "additional_purpose": "Leasing in Bahrain",
        "type": "default_purpose",
        "label": "LEL - Lending: repayments and transfers by non-residents of loans and deposits in Bahrain ; Leasing in Bahrain"
    },
    {
        "value": "MNEKEKESLICF",
        "default_purpose_code": "LICF",
        "default_purpose": "License fee",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LICF - License fee"
    },
    {
        "value": "MNEKEKESLIFI",
        "default_purpose_code": "LIFI",
        "default_purpose": "Life insurance",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LIFI - Life insurance"
    },
    {
        "value": "MNEAEANYLIP",
        "default_purpose_code": "LIP",
        "default_purpose": "Loan interest payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LIP - Loan interest payments"
    },
    {
        "value": "MNEAEANYLLA",
        "default_purpose_code": "LLA",
        "default_purpose": "Loans: drawings or repayments on loans extended to non-residents (long term)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LLA - Loans: drawings or repayments on loans extended to non-residents (long term)"
    },
    {
        "value": "MNEBHANYLLA",
        "default_purpose_code": "LLA",
        "default_purpose": "Lending: repayments and transfers by residents of loans and deposits abroad",
        "additional_purpose": "Loans: drawings or repayments on loans extended to non-residents (long-term)",
        "type": "default_purpose",
        "label": "LLA - Lending: repayments and transfers by residents of loans and deposits abroad ; Loans: drawings or repayments on loans extended to non-residents (long-term)"
    },
    {
        "value": "MNEAEANYLLL",
        "default_purpose_code": "LLL",
        "default_purpose": "Loans: drawings or repayments on foreign loans to residents (long term)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LLL - Loans: drawings or repayments on foreign loans to residents (long term)"
    },
    {
        "value": "MNEBHANYLLL",
        "default_purpose_code": "LLL",
        "default_purpose": "Lending: repayments and transfers by non-residents of loans and deposits in Bahrain",
        "additional_purpose": "Loans: drawings or repayments on foreign loans extended to residents (long-term)",
        "type": "default_purpose",
        "label": "LLL - Lending: repayments and transfers by non-residents of loans and deposits in Bahrain ; Loans: drawings or repayments on foreign loans extended to residents (long-term)"
    },
    {
        "value": "MNEAEANYLNC",
        "default_purpose_code": "LNC",
        "default_purpose": "Loan charges",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LNC - Loan charges"
    },
    {
        "value": "MNEAEANYLND",
        "default_purpose_code": "LND",
        "default_purpose": "Loan disbursements",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LND - Loan disbursements"
    },
    {
        "value": "MNEKEKESLOAN",
        "default_purpose_code": "LOAN",
        "default_purpose": "Loan",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "LOAN - Loan"
    },
    {
        "value": "MNEKEKESMACH",
        "default_purpose_code": "MACH",
        "default_purpose": "Machinery related",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "MACH - Machinery related"
    },
    {
        "value": "MNEKEKESMAFC",
        "default_purpose_code": "MAFC",
        "default_purpose": "Medical aid fund contribution",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "MAFC - Medical aid fund contribution"
    },
    {
        "value": "MNEAEANYMCR",
        "default_purpose_code": "MCR",
        "default_purpose": "Monetary claim reimbursement",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "MCR - Monetary claim reimbursement"
    },
    {
        "value": "MNEKEKESMDCS",
        "default_purpose_code": "MDCS",
        "default_purpose": "Medical services",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "MDCS - Medical services"
    },
    {
        "value": "MNEAEANYMWI",
        "default_purpose_code": "MWI",
        "default_purpose": "Mobile wallet card cash-ins",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "MWI - Mobile wallet card cash-ins"
    },
    {
        "value": "MNEAEANYMWP",
        "default_purpose_code": "MWP",
        "default_purpose": "Mobile wallet card payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "MWP - Mobile wallet card payments"
    },
    {
        "value": "MNEKEKESONCL",
        "default_purpose_code": "ONCL",
        "default_purpose": "Overnight",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "ONCL - Overnight"
    },
    {
        "value": "MNEAEANYOTS",
        "default_purpose_code": "OTS",
        "default_purpose": "Other modes of transport",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "OTS - Other modes of transport"
    },
    {
        "value": "MNEBHANYOTS",
        "default_purpose_code": "OTS",
        "default_purpose": "Transport and travel",
        "additional_purpose": "Other methods of transport (including postal and courier services)",
        "type": "default_purpose",
        "label": "OTS - Transport and travel ; Other methods of transport (including postal and courier services)"
    },
    {
        "value": "MNEAEANYOVT",
        "default_purpose_code": "OVT",
        "default_purpose": "Overtime",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "OVT - Overtime"
    },
    {
        "value": "MNEANYINRP0001",
        "default_purpose_code": "P0001",
        "default_purpose": "Capital account",
        "additional_purpose": "Repatriation of Indian investment abroad in equity capital (shares)",
        "type": "default_purpose",
        "label": "P0001 - Capital account ; Repatriation of Indian investment abroad in equity capital (shares)"
    },
    {
        "value": "MNEANYINRP0002",
        "default_purpose_code": "P0002",
        "default_purpose": "Capital account",
        "additional_purpose": "Repatriation of Indian investment abroad in debt securities",
        "type": "default_purpose",
        "label": "P0002 - Capital account ; Repatriation of Indian investment abroad in debt securities"
    },
    {
        "value": "MNEANYINRP0003",
        "default_purpose_code": "P0003",
        "default_purpose": "Capital account",
        "additional_purpose": "Repatriation of Indian investment abroad in branches",
        "type": "default_purpose",
        "label": "P0003 - Capital account ; Repatriation of Indian investment abroad in branches"
    },
    {
        "value": "MNEANYINRP0004",
        "default_purpose_code": "P0004",
        "default_purpose": "Capital account",
        "additional_purpose": "Repatriation of Indian investment abroad in subsidiaries and associates",
        "type": "default_purpose",
        "label": "P0004 - Capital account ; Repatriation of Indian investment abroad in subsidiaries and associates"
    },
    {
        "value": "MNEANYINRP0005",
        "default_purpose_code": "P0005",
        "default_purpose": "Capital account",
        "additional_purpose": "Repatriation of Indian investment abroad in real estate",
        "type": "default_purpose",
        "label": "P0005 - Capital account ; Repatriation of Indian investment abroad in real estate"
    },
    {
        "value": "MNEANYINRP0006",
        "default_purpose_code": "P0006",
        "default_purpose": "Capital account",
        "additional_purpose": "Foreign direct investment in India in equity",
        "type": "default_purpose",
        "label": "P0006 - Capital account ; Foreign direct investment in India in equity"
    },
    {
        "value": "MNEANYINRP0007",
        "default_purpose_code": "P0007",
        "default_purpose": "Capital account",
        "additional_purpose": "Foreign direct investment in India in debt securities",
        "type": "default_purpose",
        "label": "P0007 - Capital account ; Foreign direct investment in India in debt securities"
    },
    {
        "value": "MNEANYINRP0008",
        "default_purpose_code": "P0008",
        "default_purpose": "Capital account",
        "additional_purpose": "Foreign direct investment in India in real estate",
        "type": "default_purpose",
        "label": "P0008 - Capital account ; Foreign direct investment in India in real estate"
    },
    {
        "value": "MNEANYINRP0009",
        "default_purpose_code": "P0009",
        "default_purpose": "Capital account",
        "additional_purpose": "Foreign portfolio investment in India in equity shares",
        "type": "default_purpose",
        "label": "P0009 - Capital account ; Foreign portfolio investment in India in equity shares"
    },
    {
        "value": "MNEANYINRP0010",
        "default_purpose_code": "P0010",
        "default_purpose": "Capital account",
        "additional_purpose": "Foreign portfolio investment in India in debt securities including debt funds",
        "type": "default_purpose",
        "label": "P0010 - Capital account ; Foreign portfolio investment in India in debt securities including debt funds"
    },
    {
        "value": "MNEANYINRP0011",
        "default_purpose_code": "P0011",
        "default_purpose": "Capital account",
        "additional_purpose": "Repayment of loans extended to non-residents",
        "type": "default_purpose",
        "label": "P0011 - Capital account ; Repayment of loans extended to non-residents"
    },
    {
        "value": "MNEANYINRP0012",
        "default_purpose_code": "P0012",
        "default_purpose": "Capital account",
        "additional_purpose": "Loans from non-residents to India",
        "type": "default_purpose",
        "label": "P0012 - Capital account ; Loans from non-residents to India"
    },
    {
        "value": "MNEANYINRP0014",
        "default_purpose_code": "P0014",
        "default_purpose": "Capital account",
        "additional_purpose": "Receipts o/a non-resident deposits (Foreign Currency Non-Resident Account",
        "type": "default_purpose",
        "label": "P0014 - Capital account ; Receipts o/a non-resident deposits (Foreign Currency Non-Resident Account"
    },
    {
        "value": "MNEANYINRP0015",
        "default_purpose_code": "P0015",
        "default_purpose": "Capital account",
        "additional_purpose": "Loans and overdrafts taken by ADs on their own account (any amount of loan credited to the NOSTRO account which may not be swapped into Rupees should also be reported)",
        "type": "default_purpose",
        "label": "P0015 - Capital account ; Loans and overdrafts taken by ADs on their own account (any amount of loan credited to the NOSTRO account which may not be swapped into Rupees should also be reported)"
    },
    {
        "value": "MNEANYINRP0016",
        "default_purpose_code": "P0016",
        "default_purpose": "Capital account",
        "additional_purpose": "Purchase of a foreign currency against another currency",
        "type": "default_purpose",
        "label": "P0016 - Capital account ; Purchase of a foreign currency against another currency"
    },
    {
        "value": "MNEANYINRP0017",
        "default_purpose_code": "P0017",
        "default_purpose": "Capital account",
        "additional_purpose": "Sale of intangible assets like patents",
        "type": "default_purpose",
        "label": "P0017 - Capital account ; Sale of intangible assets like patents"
    },
    {
        "value": "MNEANYINRP0018",
        "default_purpose_code": "P0018",
        "default_purpose": "Capital account",
        "additional_purpose": "Other capital receipts not included elsewhere",
        "type": "default_purpose",
        "label": "P0018 - Capital account ; Other capital receipts not included elsewhere"
    },
    {
        "value": "MNEANYINRP0101",
        "default_purpose_code": "P0101",
        "default_purpose": "Exports (of goods)",
        "additional_purpose": "Value of export bills negotiated",
        "type": "default_purpose",
        "label": "P0101 - Exports (of goods) ; Value of export bills negotiated"
    },
    {
        "value": "MNEANYINRP0102",
        "default_purpose_code": "P0102",
        "default_purpose": "Exports (of goods)",
        "additional_purpose": "Realisation of export bills (in respect of goods) sent on collection (full invoice value)",
        "type": "default_purpose",
        "label": "P0102 - Exports (of goods) ; Realisation of export bills (in respect of goods) sent on collection (full invoice value)"
    },
    {
        "value": "MNEANYINRP0103",
        "default_purpose_code": "P0103",
        "default_purpose": "Exports (of goods)",
        "additional_purpose": "Advance receipts against export contracts (export of goods only)",
        "type": "default_purpose",
        "label": "P0103 - Exports (of goods) ; Advance receipts against export contracts (export of goods only)"
    },
    {
        "value": "MNEANYINRP0104",
        "default_purpose_code": "P0104",
        "default_purpose": "Exports (of goods)",
        "additional_purpose": "Receipts against export of goods not covered by the GR/PP/SOFTEX/EC copy of shipping bill etc.",
        "type": "default_purpose",
        "label": "P0104 - Exports (of goods) ; Receipts against export of goods not covered by the GR/PP/SOFTEX/EC copy of shipping bill etc."
    },
    {
        "value": "MNEANYINRP0105",
        "default_purpose_code": "P0105",
        "default_purpose": "Exports (of goods)",
        "additional_purpose": "Export bills (in respect of goods) sent on collection",
        "type": "default_purpose",
        "label": "P0105 - Exports (of goods) ; Export bills (in respect of goods) sent on collection"
    },
    {
        "value": "MNEANYINRP0106",
        "default_purpose_code": "P0106",
        "default_purpose": "Exports (of goods)",
        "additional_purpose": "Conversion of overdue export bills from NPD to collection mode",
        "type": "default_purpose",
        "label": "P0106 - Exports (of goods) ; Conversion of overdue export bills from NPD to collection mode"
    },
    {
        "value": "MNEANYINRP0107",
        "default_purpose_code": "P0107",
        "default_purpose": "Exports (of goods)",
        "additional_purpose": "Realisation of NPD export bills (full value of bill to be reported)",
        "type": "default_purpose",
        "label": "P0107 - Exports (of goods) ; Realisation of NPD export bills (full value of bill to be reported)"
    },
    {
        "value": "MNEANYINRP0201",
        "default_purpose_code": "P0201",
        "default_purpose": "Transportation",
        "additional_purpose": "Receipts of surplus freight/passenger fare by Indian shipping companies operating abroad",
        "type": "default_purpose",
        "label": "P0201 - Transportation ; Receipts of surplus freight/passenger fare by Indian shipping companies operating abroad"
    },
    {
        "value": "MNEANYINRP0202",
        "default_purpose_code": "P0202",
        "default_purpose": "Transportation",
        "additional_purpose": "Purchases on account of operating expenses of Foreign shipping companies operating in India",
        "type": "default_purpose",
        "label": "P0202 - Transportation ; Purchases on account of operating expenses of Foreign shipping companies operating in India"
    },
    {
        "value": "MNEANYINRP0205",
        "default_purpose_code": "P0205",
        "default_purpose": "Transportation",
        "additional_purpose": "Purchases on account of operational leasing (with crew) - shipping companies",
        "type": "default_purpose",
        "label": "P0205 - Transportation ; Purchases on account of operational leasing (with crew) - shipping companies"
    },
    {
        "value": "MNEANYINRP0207",
        "default_purpose_code": "P0207",
        "default_purpose": "Transportation",
        "additional_purpose": "Receipts of surplus freight/passenger fare by Indian airlines companies operating abroad",
        "type": "default_purpose",
        "label": "P0207 - Transportation ; Receipts of surplus freight/passenger fare by Indian airlines companies operating abroad"
    },
    {
        "value": "MNEANYINRP0208",
        "default_purpose_code": "P0208",
        "default_purpose": "Transportation",
        "additional_purpose": "Receipt on account of operating expenses of foreign airlines companies operating in India",
        "type": "default_purpose",
        "label": "P0208 - Transportation ; Receipt on account of operating expenses of foreign airlines companies operating in India"
    },
    {
        "value": "MNEANYINRP0211",
        "default_purpose_code": "P0211",
        "default_purpose": "Transportation",
        "additional_purpose": "Purchases on account of operational leasing (with crew) - airlines companies",
        "type": "default_purpose",
        "label": "P0211 - Transportation ; Purchases on account of operational leasing (with crew) - airlines companies"
    },
    {
        "value": "MNEANYINRP0213",
        "default_purpose_code": "P0213",
        "default_purpose": "Transportation",
        "additional_purpose": "Receipts on account of other transportation services (stevedoring",
        "type": "default_purpose",
        "label": "P0213 - Transportation ; Receipts on account of other transportation services (stevedoring"
    },
    {
        "value": "MNEANYINRP0301",
        "default_purpose_code": "P0301",
        "default_purpose": "Travel",
        "additional_purpose": "Purchases towards travel (includes purchases of foreign travellers cheques",
        "type": "default_purpose",
        "label": "P0301 - Travel ; Purchases towards travel (includes purchases of foreign travellers cheques"
    },
    {
        "value": "MNEANYINRP0308",
        "default_purpose_code": "P0308",
        "default_purpose": "Travel",
        "additional_purpose": "Foreign currency surrendered by returning Indian tourists",
        "type": "default_purpose",
        "label": "P0308 - Travel ; Foreign currency surrendered by returning Indian tourists"
    },
    {
        "value": "MNEANYINRP0401 ",
        "default_purpose_code": "P0401 ",
        "default_purpose": "Communication service",
        "additional_purpose": "Postal services",
        "type": "default_purpose",
        "label": "P0401  - Communication service ; Postal services"
    },
    {
        "value": "MNEANYINRP0402",
        "default_purpose_code": "P0402",
        "default_purpose": "Communication service",
        "additional_purpose": "Courier services",
        "type": "default_purpose",
        "label": "P0402 - Communication service ; Courier services"
    },
    {
        "value": "MNEANYINRP0403",
        "default_purpose_code": "P0403",
        "default_purpose": "Communication service",
        "additional_purpose": "Telecommunication services",
        "type": "default_purpose",
        "label": "P0403 - Communication service ; Telecommunication services"
    },
    {
        "value": "MNEANYINRP0404",
        "default_purpose_code": "P0404",
        "default_purpose": "Communication service",
        "additional_purpose": "Satellite services",
        "type": "default_purpose",
        "label": "P0404 - Communication service ; Satellite services"
    },
    {
        "value": "MNEANYINRP0501",
        "default_purpose_code": "P0501",
        "default_purpose": "Construction service",
        "additional_purpose": "Receipts for cost of construction of services projects in India",
        "type": "default_purpose",
        "label": "P0501 - Construction service ; Receipts for cost of construction of services projects in India"
    },
    {
        "value": "MNEANYINRP0601",
        "default_purpose_code": "P0601",
        "default_purpose": "Insurance service",
        "additional_purpose": "Receipts of life insurance premium",
        "type": "default_purpose",
        "label": "P0601 - Insurance service ; Receipts of life insurance premium"
    },
    {
        "value": "MNEANYINRP0602",
        "default_purpose_code": "P0602",
        "default_purpose": "Insurance service",
        "additional_purpose": "Receipts of freight insurance relating to import and export of goods",
        "type": "default_purpose",
        "label": "P0602 - Insurance service ; Receipts of freight insurance relating to import and export of goods"
    },
    {
        "value": "MNEANYINRP0603",
        "default_purpose_code": "P0603",
        "default_purpose": "Insurance service",
        "additional_purpose": "Receipts on account of other general insurance premium",
        "type": "default_purpose",
        "label": "P0603 - Insurance service ; Receipts on account of other general insurance premium"
    },
    {
        "value": "MNEANYINRP0604",
        "default_purpose_code": "P0604",
        "default_purpose": "Insurance service",
        "additional_purpose": "Receipts of reinsurance premium",
        "type": "default_purpose",
        "label": "P0604 - Insurance service ; Receipts of reinsurance premium"
    },
    {
        "value": "MNEANYINRP0605",
        "default_purpose_code": "P0605",
        "default_purpose": "Insurance service",
        "additional_purpose": "Receipts on account of auxiliary services (commission on insurance)",
        "type": "default_purpose",
        "label": "P0605 - Insurance service ; Receipts on account of auxiliary services (commission on insurance)"
    },
    {
        "value": "MNEANYINRP0606",
        "default_purpose_code": "P0606",
        "default_purpose": "Insurance service",
        "additional_purpose": "Receipts on account of settlement of claims",
        "type": "default_purpose",
        "label": "P0606 - Insurance service ; Receipts on account of settlement of claims"
    },
    {
        "value": "MNEANYINRP0701",
        "default_purpose_code": "P0701",
        "default_purpose": "Financial services",
        "additional_purpose": "Financial intermediation except investment banking - bank charges",
        "type": "default_purpose",
        "label": "P0701 - Financial services ; Financial intermediation except investment banking - bank charges"
    },
    {
        "value": "MNEANYINRP0702",
        "default_purpose_code": "P0702",
        "default_purpose": "Financial services",
        "additional_purpose": "Investment banking: brokerage",
        "type": "default_purpose",
        "label": "P0702 - Financial services ; Investment banking: brokerage"
    },
    {
        "value": "MNEANYINRP0703",
        "default_purpose_code": "P0703",
        "default_purpose": "Financial services",
        "additional_purpose": "Auxiliary services: charges on operation and regulatory fees",
        "type": "default_purpose",
        "label": "P0703 - Financial services ; Auxiliary services: charges on operation and regulatory fees"
    },
    {
        "value": "MNEANYINRP0801",
        "default_purpose_code": "P0801",
        "default_purpose": "Computer and information services",
        "additional_purpose": "Hardware consultancy",
        "type": "default_purpose",
        "label": "P0801 - Computer and information services ; Hardware consultancy"
    },
    {
        "value": "MNEANYINRP0802",
        "default_purpose_code": "P0802",
        "default_purpose": "Computer and information services",
        "additional_purpose": "Software implementation or consultancy (other than those covered in SOFTEX form)",
        "type": "default_purpose",
        "label": "P0802 - Computer and information services ; Software implementation or consultancy (other than those covered in SOFTEX form)"
    },
    {
        "value": "MNEANYINRP0803",
        "default_purpose_code": "P0803",
        "default_purpose": "Computer and information services",
        "additional_purpose": "Database data processing charges",
        "type": "default_purpose",
        "label": "P0803 - Computer and information services ; Database data processing charges"
    },
    {
        "value": "MNEANYINRP0804",
        "default_purpose_code": "P0804",
        "default_purpose": "Computer and information services",
        "additional_purpose": "Repair and maintenance of computer and software",
        "type": "default_purpose",
        "label": "P0804 - Computer and information services ; Repair and maintenance of computer and software"
    },
    {
        "value": "MNEANYINRP0805",
        "default_purpose_code": "P0805",
        "default_purpose": "Computer and information services",
        "additional_purpose": "News agency services",
        "type": "default_purpose",
        "label": "P0805 - Computer and information services ; News agency services"
    },
    {
        "value": "MNEANYINRP0806",
        "default_purpose_code": "P0806",
        "default_purpose": "Computer and information services",
        "additional_purpose": "Other information services: subscription to newspapers",
        "type": "default_purpose",
        "label": "P0806 - Computer and information services ; Other information services: subscription to newspapers"
    },
    {
        "value": "MNEANYINRP0901",
        "default_purpose_code": "P0901",
        "default_purpose": "Royalties and license fees",
        "additional_purpose": "Franchises services: patents",
        "type": "default_purpose",
        "label": "P0901 - Royalties and license fees ; Franchises services: patents"
    },
    {
        "value": "MNEANYINRP0902",
        "default_purpose_code": "P0902",
        "default_purpose": "Royalties and license fees",
        "additional_purpose": "Receipts for use",
        "type": "default_purpose",
        "label": "P0902 - Royalties and license fees ; Receipts for use"
    },
    {
        "value": "MNEANYINRP1001",
        "default_purpose_code": "P1001",
        "default_purpose": "Other business services",
        "additional_purpose": "Merchanting services: net receipts (from sale and purchase of goods without crossing the border)",
        "type": "default_purpose",
        "label": "P1001 - Other business services ; Merchanting services: net receipts (from sale and purchase of goods without crossing the border)"
    },
    {
        "value": "MNEANYINRP1002",
        "default_purpose_code": "P1002",
        "default_purpose": "Other business services",
        "additional_purpose": "Trade related services: commission on exports or imports",
        "type": "default_purpose",
        "label": "P1002 - Other business services ; Trade related services: commission on exports or imports"
    },
    {
        "value": "MNEANYINRP1003",
        "default_purpose_code": "P1003",
        "default_purpose": "Other business services",
        "additional_purpose": "Operational leasing services (other than financial leasing and without operating crew) including charter hire",
        "type": "default_purpose",
        "label": "P1003 - Other business services ; Operational leasing services (other than financial leasing and without operating crew) including charter hire"
    },
    {
        "value": "MNEANYINRP1006",
        "default_purpose_code": "P1006",
        "default_purpose": "Other business services",
        "additional_purpose": "Business and management consultancy and public relations services",
        "type": "default_purpose",
        "label": "P1006 - Other business services ; Business and management consultancy and public relations services"
    },
    {
        "value": "MNEANYINRP1008",
        "default_purpose_code": "P1008",
        "default_purpose": "Other business services",
        "additional_purpose": "Research and development services",
        "type": "default_purpose",
        "label": "P1008 - Other business services ; Research and development services"
    },
    {
        "value": "MNEANYINRP1009",
        "default_purpose_code": "P1009",
        "default_purpose": "Other business services",
        "additional_purpose": "Architectural",
        "type": "default_purpose",
        "label": "P1009 - Other business services ; Architectural"
    },
    {
        "value": "MNEANYINRP1010",
        "default_purpose_code": "P1010",
        "default_purpose": "Other business services",
        "additional_purpose": "Agricultural",
        "type": "default_purpose",
        "label": "P1010 - Other business services ; Agricultural"
    },
    {
        "value": "MNEANYINRP1011",
        "default_purpose_code": "P1011",
        "default_purpose": "Other business services",
        "additional_purpose": "Inward remittance for maintenance of offices in India",
        "type": "default_purpose",
        "label": "P1011 - Other business services ; Inward remittance for maintenance of offices in India"
    },
    {
        "value": "MNEANYINRP1012",
        "default_purpose_code": "P1012",
        "default_purpose": "Other business services",
        "additional_purpose": "Distribution services",
        "type": "default_purpose",
        "label": "P1012 - Other business services ; Distribution services"
    },
    {
        "value": "MNEANYINRP1013",
        "default_purpose_code": "P1013",
        "default_purpose": "Other business services",
        "additional_purpose": "Environmental services",
        "type": "default_purpose",
        "label": "P1013 - Other business services ; Environmental services"
    },
    {
        "value": "MNEANYINRP1101",
        "default_purpose_code": "P1101",
        "default_purpose": "Personal",
        "additional_purpose": " cultural and recreational services",
        "type": "default_purpose",
        "label": "P1101 - Personal ;  cultural and recreational services"
    },
    {
        "value": "MNEANYINRP1102",
        "default_purpose_code": "P1102",
        "default_purpose": "Personal",
        "additional_purpose": " cultural and recreational services",
        "type": "default_purpose",
        "label": "P1102 - Personal ;  cultural and recreational services"
    },
    {
        "value": "MNEANYINRP1201",
        "default_purpose_code": "P1201",
        "default_purpose": "Government (not included elsewhere)",
        "additional_purpose": "Maintenance of foreign embassies in India",
        "type": "default_purpose",
        "label": "P1201 - Government (not included elsewhere) ; Maintenance of foreign embassies in India"
    },
    {
        "value": "MNEANYINRP1203",
        "default_purpose_code": "P1203",
        "default_purpose": "Government (not included elsewhere)",
        "additional_purpose": "Maintenance of international institutions such as offices of IMF mission",
        "type": "default_purpose",
        "label": "P1203 - Government (not included elsewhere) ; Maintenance of international institutions such as offices of IMF mission"
    },
    {
        "value": "MNEANYINRP1301",
        "default_purpose_code": "P1301",
        "default_purpose": "Transfers",
        "additional_purpose": "Inward remittance from Indian non-residents towards family maintenance and savings",
        "type": "default_purpose",
        "label": "P1301 - Transfers ; Inward remittance from Indian non-residents towards family maintenance and savings"
    },
    {
        "value": "MNEANYINRP1302",
        "default_purpose_code": "P1302",
        "default_purpose": "Transfers",
        "additional_purpose": "Personal gifts and donations",
        "type": "default_purpose",
        "label": "P1302 - Transfers ; Personal gifts and donations"
    },
    {
        "value": "MNEANYINRP1303",
        "default_purpose_code": "P1303",
        "default_purpose": "Transfers",
        "additional_purpose": "Donations to religious and charitable institutions in India",
        "type": "default_purpose",
        "label": "P1303 - Transfers ; Donations to religious and charitable institutions in India"
    },
    {
        "value": "MNEANYINRP1304",
        "default_purpose_code": "P1304",
        "default_purpose": "Transfers",
        "additional_purpose": "Grants and donations to governments and charitable institutions established by the governments",
        "type": "default_purpose",
        "label": "P1304 - Transfers ; Grants and donations to governments and charitable institutions established by the governments"
    },
    {
        "value": "MNEANYINRP1306",
        "default_purpose_code": "P1306",
        "default_purpose": "Transfers",
        "additional_purpose": "Receipts or refund of taxes",
        "type": "default_purpose",
        "label": "P1306 - Transfers ; Receipts or refund of taxes"
    },
    {
        "value": "MNEANYINRP1401",
        "default_purpose_code": "P1401",
        "default_purpose": "Income",
        "additional_purpose": "Compensation of employees",
        "type": "default_purpose",
        "label": "P1401 - Income ; Compensation of employees"
    },
    {
        "value": "MNEANYINRP1403",
        "default_purpose_code": "P1403",
        "default_purpose": "Income",
        "additional_purpose": "Inward remittance towards interest on loans extended to non-residents (short term",
        "type": "default_purpose",
        "label": "P1403 - Income ; Inward remittance towards interest on loans extended to non-residents (short term"
    },
    {
        "value": "MNEANYINRP1404",
        "default_purpose_code": "P1404",
        "default_purpose": "Income",
        "additional_purpose": "Inward remittance of interest on debt securities: debentures",
        "type": "default_purpose",
        "label": "P1404 - Income ; Inward remittance of interest on debt securities: debentures"
    },
    {
        "value": "MNEANYINRP1405",
        "default_purpose_code": "P1405",
        "default_purpose": "Income",
        "additional_purpose": "Inward remittance towards interest receipts of ADs on their own account (on investments)",
        "type": "default_purpose",
        "label": "P1405 - Income ; Inward remittance towards interest receipts of ADs on their own account (on investments)"
    },
    {
        "value": "MNEANYINRP1406",
        "default_purpose_code": "P1406",
        "default_purpose": "Income",
        "additional_purpose": "Repatriation of profits to India",
        "type": "default_purpose",
        "label": "P1406 - Income ; Repatriation of profits to India"
    },
    {
        "value": "MNEANYINRP1407",
        "default_purpose_code": "P1407",
        "default_purpose": "Income",
        "additional_purpose": "Receipt of dividends by Indians",
        "type": "default_purpose",
        "label": "P1407 - Income ; Receipt of dividends by Indians"
    },
    {
        "value": "MNEANYINRP1501",
        "default_purpose_code": "P1501",
        "default_purpose": "Others",
        "additional_purpose": "Refunds or rebates on account of imports",
        "type": "default_purpose",
        "label": "P1501 - Others ; Refunds or rebates on account of imports"
    },
    {
        "value": "MNEANYINRP1502",
        "default_purpose_code": "P1502",
        "default_purpose": "Others",
        "additional_purpose": "Reversal of wrong entries",
        "type": "default_purpose",
        "label": "P1502 - Others ; Reversal of wrong entries"
    },
    {
        "value": "MNEANYINRP1503",
        "default_purpose_code": "P1503",
        "default_purpose": "Others",
        "additional_purpose": "Remittances (receipts) by residents under international bidding process",
        "type": "default_purpose",
        "label": "P1503 - Others ; Remittances (receipts) by residents under international bidding process"
    },
    {
        "value": "MNEANYINRP1590",
        "default_purpose_code": "P1590",
        "default_purpose": "Others",
        "additional_purpose": "Receipts below 10",
        "type": "default_purpose",
        "label": "P1590 - Others ; Receipts below 10"
    },
    {
        "value": "MNEKEKESPENA",
        "default_purpose_code": "PENA",
        "default_purpose": "Penalties",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PENA - Penalties"
    },
    {
        "value": "MNEAEANYPIP",
        "default_purpose_code": "PIP",
        "default_purpose": "Profits on Islamic products",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PIP - Profits on Islamic products"
    },
    {
        "value": "MNEBHANYPIP",
        "default_purpose_code": "PIP",
        "default_purpose": "Interest and profits with abroad",
        "additional_purpose": "Profits on Islamic products",
        "type": "default_purpose",
        "label": "PIP - Interest and profits with abroad ; Profits on Islamic products"
    },
    {
        "value": "MNEKEKESPL39",
        "default_purpose_code": "PL39",
        "default_purpose": "Licence fees for commercial banks",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL39 - Licence fees for commercial banks"
    },
    {
        "value": "MNEKEKESPL40",
        "default_purpose_code": "PL40",
        "default_purpose": "Licence fees deposit micro institutions",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL40 - Licence fees deposit micro institutions"
    },
    {
        "value": "MNEKEKESPL41",
        "default_purpose_code": "PL41",
        "default_purpose": "Licence fees forex bureaus",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL41 - Licence fees forex bureaus"
    },
    {
        "value": "MNEKEKESPL42",
        "default_purpose_code": "PL42",
        "default_purpose": "Licence fees credit reference bureaus",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL42 - Licence fees credit reference bureaus"
    },
    {
        "value": "MNEKEKESPL43",
        "default_purpose_code": "PL43",
        "default_purpose": "Licence fees mortgage financial institutions",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL43 - Licence fees mortgage financial institutions"
    },
    {
        "value": "MNEKEKESPL44",
        "default_purpose_code": "PL44",
        "default_purpose": "Applications fees for commercial banks",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL44 - Applications fees for commercial banks"
    },
    {
        "value": "MNEKEKESPL45",
        "default_purpose_code": "PL45",
        "default_purpose": "Application fees for mortgage financial institutions",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL45 - Application fees for mortgage financial institutions"
    },
    {
        "value": "MNEKEKESPL46",
        "default_purpose_code": "PL46",
        "default_purpose": "Applications fees depo. tak. institutions",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL46 - Applications fees depo. tak. institutions"
    },
    {
        "value": "MNEKEKESPL47",
        "default_purpose_code": "PL47",
        "default_purpose": "Application fees forex bureaus",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL47 - Application fees forex bureaus"
    },
    {
        "value": "MNEKEKESPL48",
        "default_purpose_code": "PL48",
        "default_purpose": "Application fees credit reference bureaus",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL48 - Application fees credit reference bureaus"
    },
    {
        "value": "MNEKEKESPL49",
        "default_purpose_code": "PL49",
        "default_purpose": "Penalties commercial banks",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL49 - Penalties commercial banks"
    },
    {
        "value": "MNEKEKESPL50",
        "default_purpose_code": "PL50",
        "default_purpose": "Penalties mortgage financial institutions",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL50 - Penalties mortgage financial institutions"
    },
    {
        "value": "MNEKEKESPL51",
        "default_purpose_code": "PL51",
        "default_purpose": "Penalties deposit taking micro institutions",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL51 - Penalties deposit taking micro institutions"
    },
    {
        "value": "MNEKEKESPL52",
        "default_purpose_code": "PL52",
        "default_purpose": "Penalties forex bureaus",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL52 - Penalties forex bureaus"
    },
    {
        "value": "MNEKEKESPL53",
        "default_purpose_code": "PL53",
        "default_purpose": "Penalties credit reference bureaus",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PL53 - Penalties credit reference bureaus"
    },
    {
        "value": "MNEAEANYPMS",
        "default_purpose_code": "PMS",
        "default_purpose": "Professional and management consulting services",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PMS - Professional and management consulting services"
    },
    {
        "value": "MNEBHANYPMS",
        "default_purpose_code": "PMS",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Professional and management consulting services",
        "type": "default_purpose",
        "label": "PMS - Services with abroad ; Professional and management consulting services"
    },
    {
        "value": "MNEAEANYPOR",
        "default_purpose_code": "POR",
        "default_purpose": "Refunds/reversals on IPO subscriptions",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "POR - Refunds/reversals on IPO subscriptions"
    },
    {
        "value": "MNEAEANYPOS",
        "default_purpose_code": "POS",
        "default_purpose": "POS merchant settlement",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "POS - POS merchant settlement"
    },
    {
        "value": "MNEAEANYPPA",
        "default_purpose_code": "PPA",
        "default_purpose": "Purchase of real estate abroad from residents",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PPA - Purchase of real estate abroad from residents"
    },
    {
        "value": "MNEBHANYPPA",
        "default_purpose_code": "PPA",
        "default_purpose": "Personal",
        "additional_purpose": "Purchase of real estate abroad from residents",
        "type": "default_purpose",
        "label": "PPA - Personal ; Purchase of real estate abroad from residents"
    },
    {
        "value": "MNEAEANYPPL",
        "default_purpose_code": "PPL",
        "default_purpose": "Purchase of real estate in the UAE from non-residents",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PPL - Purchase of real estate in the UAE from non-residents"
    },
    {
        "value": "MNEBHANYPPL",
        "default_purpose_code": "PPL",
        "default_purpose": "Personal",
        "additional_purpose": "Purchase of real estate in Bahrain from non-residents",
        "type": "default_purpose",
        "label": "PPL - Personal ; Purchase of real estate in Bahrain from non-residents"
    },
    {
        "value": "MNEKEKESPPTI",
        "default_purpose_code": "PPTI",
        "default_purpose": "Property insurance",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PPTI - Property insurance"
    },
    {
        "value": "MNEAEANYPRP",
        "default_purpose_code": "PRP",
        "default_purpose": "Profit rate swap payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PRP - Profit rate swap payments"
    },
    {
        "value": "MNEAEANYPRR",
        "default_purpose_code": "PRR",
        "default_purpose": "Profits or rents on real estate",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PRR - Profits or rents on real estate"
    },
    {
        "value": "MNEBHANYPRR",
        "default_purpose_code": "PRR",
        "default_purpose": "Interest and profits with abroad",
        "additional_purpose": "Profits or rents on real estate",
        "type": "default_purpose",
        "label": "PRR - Interest and profits with abroad ; Profits or rents on real estate"
    },
    {
        "value": "MNEAEANYPRS",
        "default_purpose_code": "PRS",
        "default_purpose": "Personal cultural audio visual and recreational services",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PRS - Personal cultural audio visual and recreational services"
    },
    {
        "value": "MNEBHANYPRS",
        "default_purpose_code": "PRS",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Personal",
        "type": "default_purpose",
        "label": "PRS - Services with abroad ; Personal"
    },
    {
        "value": "MNEAEANYPRW",
        "default_purpose_code": "PRW",
        "default_purpose": "Profit rate unwind payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "PRW - Profit rate unwind payments"
    },
    {
        "value": "MNEBHANYRDA",
        "default_purpose_code": "RDA",
        "default_purpose": "FDI flows: acquisition and liquidation by residents of equity and securities in Bahrain (above 10% share)",
        "additional_purpose": "Reverse debt instruments abroad",
        "type": "default_purpose",
        "label": "RDA - FDI flows: acquisition and liquidation by residents of equity and securities in Bahrain (above 10% share) ; Reverse debt instruments abroad"
    },
    {
        "value": "MNEBHANYRDL",
        "default_purpose_code": "RDL",
        "default_purpose": "Foreign Direct Investment (FDI) flows: acquisition and liquidation by residents of equity and securities abroad (above 10% share)",
        "additional_purpose": "Reverse debt instruments in Bahrain",
        "type": "default_purpose",
        "label": "RDL - Foreign Direct Investment (FDI) flows: acquisition and liquidation by residents of equity and securities abroad (above 10% share) ; Reverse debt instruments in Bahrain"
    },
    {
        "value": "MNEAEANYRDS",
        "default_purpose_code": "RDS",
        "default_purpose": "Research and development services",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "RDS - Research and development services"
    },
    {
        "value": "MNEBHANYRDS",
        "default_purpose_code": "RDS",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Research and development services",
        "type": "default_purpose",
        "label": "RDS - Services with abroad ; Research and development services"
    },
    {
        "value": "MNEBHANYREA",
        "default_purpose_code": "REA",
        "default_purpose": "FDI flows: acquisition and liquidation by residents of equity and securities in Bahrain (above 10% share)",
        "additional_purpose": "Reverse equity share abroad",
        "type": "default_purpose",
        "label": "REA - FDI flows: acquisition and liquidation by residents of equity and securities in Bahrain (above 10% share) ; Reverse equity share abroad"
    },
    {
        "value": "MNEKEKESREFU",
        "default_purpose_code": "REFU",
        "default_purpose": "Refund",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "REFU - Refund"
    },
    {
        "value": "MNEBHANYREL",
        "default_purpose_code": "REL",
        "default_purpose": "Foreign Direct Investment (FDI) flows: acquisition and liquidation by residents of equity and securities abroad (above 10% share)",
        "additional_purpose": "Reverse equity share in Bahrain",
        "type": "default_purpose",
        "label": "REL - Foreign Direct Investment (FDI) flows: acquisition and liquidation by residents of equity and securities abroad (above 10% share) ; Reverse equity share in Bahrain"
    },
    {
        "value": "MNEKEKESRELG",
        "default_purpose_code": "RELG",
        "default_purpose": "Religious activities",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "RELG - Religious activities"
    },
    {
        "value": "MNEKEKESRENT",
        "default_purpose_code": "RENT",
        "default_purpose": "Rent",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "RENT - Rent"
    },
    {
        "value": "MNEAEANYRFS",
        "default_purpose_code": "RFS",
        "default_purpose": "Repos on foreign securities",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "RFS - Repos on foreign securities"
    },
    {
        "value": "MNEBHANYRFS",
        "default_purpose_code": "RFS",
        "default_purpose": "Lending: repayments and transfers by residents of loans and deposits abroad",
        "additional_purpose": "Repos on foreign securities",
        "type": "default_purpose",
        "label": "RFS - Lending: repayments and transfers by residents of loans and deposits abroad ; Repos on foreign securities"
    },
    {
        "value": "MNEKEKESRITX",
        "default_purpose_code": "RITX",
        "default_purpose": "Rental income tax",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "RITX - Rental income tax"
    },
    {
        "value": "MNEAEANYRLS",
        "default_purpose_code": "RLS",
        "default_purpose": "Repos on securities issued by residents",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "RLS - Repos on securities issued by residents"
    },
    {
        "value": "MNEBHANYRLS",
        "default_purpose_code": "RLS",
        "default_purpose": "Lending: repayments and transfers by non-residents of loans and deposits in Bahrain",
        "additional_purpose": "Repos on securities issued by residents",
        "type": "default_purpose",
        "label": "RLS - Lending: repayments and transfers by non-residents of loans and deposits in Bahrain ; Repos on securities issued by residents"
    },
    {
        "value": "MNEKEKESRLWY",
        "default_purpose_code": "RLWY",
        "default_purpose": "Railway",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "RLWY - Railway"
    },
    {
        "value": "MNEAEANYRNT",
        "default_purpose_code": "RNT",
        "default_purpose": "Rent payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "RNT - Rent payments"
    },
    {
        "value": "MNEAEANYSAA",
        "default_purpose_code": "SAA",
        "default_purpose": "Salary advance",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SAA - Salary advance"
    },
    {
        "value": "MNEKEKESSAVG",
        "default_purpose_code": "SAVG",
        "default_purpose": "Savings",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SAVG - Savings"
    },
    {
        "value": "MNEAEANYSCO",
        "default_purpose_code": "SCO",
        "default_purpose": "Construction",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SCO - Construction"
    },
    {
        "value": "MNEBHANYSCO",
        "default_purpose_code": "SCO",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Construction",
        "type": "default_purpose",
        "label": "SCO - Services with abroad ; Construction"
    },
    {
        "value": "MNEKEKESSDTX",
        "default_purpose_code": "SDTX",
        "default_purpose": "Stamp duty",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SDTX - Stamp duty"
    },
    {
        "value": "MNEKEKESSHIP",
        "default_purpose_code": "SHIP",
        "default_purpose": "Shipping",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SHIP - Shipping"
    },
    {
        "value": "MNEAEANYSLA",
        "default_purpose_code": "SLA",
        "default_purpose": "Loans: drawings or repayments on loans extended to non-residents (short term)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SLA - Loans: drawings or repayments on loans extended to non-residents (short term)"
    },
    {
        "value": "MNEBHANYSLA",
        "default_purpose_code": "SLA",
        "default_purpose": "Lending: repayments and transfers by residents of loans and deposits abroad",
        "additional_purpose": "Loans: drawings or repayments on loans extended to non-residents (short-term)",
        "type": "default_purpose",
        "label": "SLA - Lending: repayments and transfers by residents of loans and deposits abroad ; Loans: drawings or repayments on loans extended to non-residents (short-term)"
    },
    {
        "value": "MNEAEANYSLL",
        "default_purpose_code": "SLL",
        "default_purpose": "Loans: drawings or repayments on foreign loans extended to residents (short term)",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SLL - Loans: drawings or repayments on foreign loans extended to residents (short term)"
    },
    {
        "value": "MNEBHANYSLL",
        "default_purpose_code": "SLL",
        "default_purpose": "Lending: repayments and transfers by non-residents of loans and deposits in Bahrain",
        "additional_purpose": "Loans: drawings or repayments on foreign loans extended to residents (short-term)",
        "type": "default_purpose",
        "label": "SLL - Lending: repayments and transfers by non-residents of loans and deposits in Bahrain ; Loans: drawings or repayments on foreign loans extended to residents (short-term)"
    },
    {
        "value": "MNEAEANYSTR",
        "default_purpose_code": "STR",
        "default_purpose": "Travel",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "STR - Travel"
    },
    {
        "value": "MNEAEANYSTS",
        "default_purpose_code": "STS",
        "default_purpose": "Sea transport",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "STS - Sea transport"
    },
    {
        "value": "MNEBHANYSTS",
        "default_purpose_code": "STS",
        "default_purpose": "Transport and travel",
        "additional_purpose": "Sea transport",
        "type": "default_purpose",
        "label": "STS - Transport and travel ; Sea transport"
    },
    {
        "value": "MNEAEANYSVI",
        "default_purpose_code": "SVI",
        "default_purpose": "Stored value card cash in",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SVI - Stored value card cash in"
    },
    {
        "value": "MNEAEANYSVP",
        "default_purpose_code": "SVP",
        "default_purpose": "Stored value card payment",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "SVP - Stored value card payment"
    },
    {
        "value": "MNEKEKESTAXR",
        "default_purpose_code": "TAXR",
        "default_purpose": "Tax refund",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TAXR - Tax refund"
    },
    {
        "value": "MNEAEANYTCP",
        "default_purpose_code": "TCP",
        "default_purpose": "Trade credits and advances payable",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TCP - Trade credits and advances payable"
    },
    {
        "value": "MNEBHANYTCP",
        "default_purpose_code": "TCP",
        "default_purpose": "Lending: repayments and transfers by non-residents of loans and deposits in Bahrain",
        "additional_purpose": "Trade credits and advances payable",
        "type": "default_purpose",
        "label": "TCP - Lending: repayments and transfers by non-residents of loans and deposits in Bahrain ; Trade credits and advances payable"
    },
    {
        "value": "MNEAEANYTCR",
        "default_purpose_code": "TCR",
        "default_purpose": "Trade credits and advances receivable",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TCR - Trade credits and advances receivable"
    },
    {
        "value": "MNEBHANYTCR",
        "default_purpose_code": "TCR",
        "default_purpose": "Lending: repayments and transfers by residents of loans and deposits abroad",
        "additional_purpose": "Trade credits and advances receivable",
        "type": "default_purpose",
        "label": "TCR - Lending: repayments and transfers by residents of loans and deposits abroad ; Trade credits and advances receivable"
    },
    {
        "value": "MNEAEANYTCS",
        "default_purpose_code": "TCS",
        "default_purpose": "Telecommunication services",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TCS - Telecommunication services"
    },
    {
        "value": "MNEBHANYTCS",
        "default_purpose_code": "TCS",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Telecommunications services",
        "type": "default_purpose",
        "label": "TCS - Services with abroad ; Telecommunications services"
    },
    {
        "value": "MNEAEANYTKT",
        "default_purpose_code": "TKT",
        "default_purpose": "Tickets",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TKT - Tickets"
    },
    {
        "value": "MNEAEANYTOF",
        "default_purpose_code": "TOF",
        "default_purpose": "Transfer of funds between persons normal and juridical",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TOF - Transfer of funds between persons normal and juridical"
    },
    {
        "value": "MNEKEKESTOTX",
        "default_purpose_code": "TOTX",
        "default_purpose": "Turnover tax",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TOTX - Turnover tax"
    },
    {
        "value": "MNEKEKESTRAC",
        "default_purpose_code": "TRAC",
        "default_purpose": "Removed from tracking",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TRAC - Removed from tracking"
    },
    {
        "value": "MNEAEANYTTS",
        "default_purpose_code": "TTS",
        "default_purpose": "Technical trade-related and other business services",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "TTS - Technical trade-related and other business services"
    },
    {
        "value": "MNEBHANYTTS",
        "default_purpose_code": "TTS",
        "default_purpose": "Services with abroad",
        "additional_purpose": "Technical",
        "type": "default_purpose",
        "label": "TTS - Services with abroad ; Technical"
    },
    {
        "value": "MNEKEKESVIPN",
        "default_purpose_code": "VIPN",
        "default_purpose": "Vehicle identification plate number",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "VIPN - Vehicle identification plate number"
    },
    {
        "value": "MNEKEKESVOST",
        "default_purpose_code": "VOST",
        "default_purpose": "Vostro payments",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "VOST - Vostro payments"
    },
    {
        "value": "MNE_KE_KESWHLD",
        "default_purpose_code": "WHLD",
        "default_purpose": "Withholding",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "WHLD - Withholding"
    },
    {
        "value": "MMPPC990001",
        "default_purpose_code": "Intra company transfer",
        "default_purpose": "Intra company transfer",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "Intra company transfer - Intra company transfer"
    },
    {
        "value": "MMPPC990002",
        "default_purpose_code": "Operational expenses",
        "default_purpose": "Payments towards payroll",
        "additional_purpose": " suppliers",
        "type": "default_purpose",
        "label": "Operational expenses - Payments towards payroll ;  suppliers"
    },
    {
        "value": "MMPPC990003",
        "default_purpose_code": "Payment towards services",
        "default_purpose": "Payment to fulfil orders to be executed or services consumed",
        "additional_purpose": null,
        "type": "default_purpose",
        "label": "Payment towards services - Payment to fulfil orders to be executed or services consumed"
    },
    {
        "value": "MNEANYBRL12005",
        "default_purpose_code": 12005,
        "default_purpose": "Import of goods",
        "additional_purpose": "Importation payment",
        "type": "default_purpose",
        "label": "12005 - Import of goods ; Importation payment"
    },
    {
        "value": "MNEANYBRL22136",
        "default_purpose_code": 22136,
        "default_purpose": "Services",
        "additional_purpose": "Sea transportation",
        "type": "default_purpose",
        "label": "22136 - Services ; Sea transportation"
    },
    {
        "value": "MNEANYBRL47740",
        "default_purpose_code": 47740,
        "default_purpose": "Services",
        "additional_purpose": "Tourism services",
        "type": "default_purpose",
        "label": "47740 - Services ; Tourism services"
    }
];
