import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/recipients';
import { request } from 'utils/api';
import Endpoints from 'utils/endpoints';
import { getAccessToken } from 'utils/auth';
import { getQueryParams } from 'utils/helpers';

const { url } = Endpoints.recipients.recipient;
const { getRecipient, getPaymentPurposeCodes, validateRecipient } = Endpoints.recipients;

export function* getRecipients(action) {
    const { payload } = action;

    if (payload?.currency?.includes('ALL')) {
        delete payload.currency;
    }

    const queryParams = getQueryParams(payload);
    try {
        const getRecipients = yield call(request().get, getRecipient.url(queryParams));
        if (getRecipients) {
            yield put(actions.getRecipientsSuccess(getRecipients.data));
        }
    } catch (err) {
        yield put(actions.getRecipientsError(err.message));
    }
}

export function* getPaymentPurposeCodesAction(action) {
    const { payload } = action;

    const queryParams = getQueryParams(payload);
    try {
        const paymentPurposeCodes = yield call(request().get, getPaymentPurposeCodes.url(queryParams));
        if (paymentPurposeCodes) {
            yield put(actions.getPaymentPurposeCodesSuccess(paymentPurposeCodes.data));
        }
    } catch (err) {
        yield put(actions.getPaymentPurposeCodesError({error: "Unable to fetch purpose codes"}));
    }
}

export function* validateRecipientAction(action) {
    try {
        const validateResult = yield call(request().post, validateRecipient.url(), action.payload);
        if (
            validateResult &&
            validateResult.data &&
            validateResult.status >= 200 &&
            validateResult.status < 300
        ) {
            const response = validateResult.data;
            // if (response.validation.status === "success") {
            yield put(actions.validateRecipientSuccess(response));
            // } else {
            //     yield put(actions.validateRecipientError({
            //         message: response.validation.details,
            //         status: response.validation.status,
            //     }));

            // }
        } else {
            yield put(
                actions.validateRecipientError({
                    message: 'Failed to validate the recipient',
                    status: validateResult.status,
                })
            );
        }
    } catch (err) {
        if (!err.response) {
            yield put(
                actions.validateRecipientError({
                    message: 'An error was encountered when creating the recipient. Retry after some time. If the issue persists, then contact support at cs@merge.money.',
                    status: 500,
                })
            );
            return;
        }
        const { data, status } = err.response;

        if (status > 500) {
            yield put(
                actions.validateRecipientError({
                    message: 'An error was encountered when creating the recipient. Retry after some time. If the issue persists, then contact support at cs@merge.money.',
                    status: 500,
                })
            );
            return;
        } else {
            yield put(
                actions.validateRecipientError({
                    message: data?.message,
                    status: status,
                })
            );    
            return;
        }
    }
}


/*
@payload {
    email: "user@example.com",
    account_holder_name: "string",
    nickname: "string",
    type: "string",
    account_number: "string",
    sort_code: "string"
}
 */
export function* addRecipient(action) {
    const {
        payload: { payload, otp },
    } = action;
    try {
        const createRecipient = yield call(request().post, url, payload, {
            headers: {
                X_OTP_CODE: otp.otp,
                X_OTP_TOKEN: otp.token,
            },
        });
        if (
            createRecipient &&
            createRecipient.data &&
            createRecipient.status >= 200 &&
            createRecipient.status < 300
        ) {
            yield put(actions.createRecipientsSuccess(createRecipient.data));
        } else {
            yield put(
                actions.createRecipientsError({
                    message: 'Failed to create recipient',
                    status: createRecipient.status,
                })
            );
        }
    } catch (err) {
        yield put(
            actions.createRecipientsError({
                message: 'Invalid OTP',
                status: 403,
            })
        );
    }
}

export function* updateRecipient(action) {
    const {
        payload: { recipient_id, nickname, otp },
    } = action;
    try {
        const updateRecipient = yield call(
            request().patch,
            `${url}/${recipient_id}`,
            {
                nickname: nickname ? nickname : '',
            },
            {
                headers: {
                    X_OTP_CODE: otp.otp,
                    X_OTP_TOKEN: otp.token,
                },
            }
        );

        if (
            updateRecipient &&
            updateRecipient.data &&
            updateRecipient.status >= 200 &&
            updateRecipient.status < 300
        ) {
            yield put(actions.updateRecipientsSuccess(updateRecipient.data));
        } else {
            yield put(
                actions.updateRecipientsError({
                    message: 'Failed to update recipient',
                    status: updateRecipient.status,
                })
            );
        }
    } catch (err) {
        yield put(
            actions.updateRecipientsError({
                message: 'Invalid OTP',
                status: 403,
            })
        );
    }
}

export function* deleteRecipient(action) {
    const {
        payload: { recipient_id, otp },
    } = action;
    try {
        const deleteRecipient = yield call(request().delete, `${url}/${recipient_id}`, {
            headers: {
                X_OTP_CODE: otp.otp,
                X_OTP_TOKEN: otp.token,
            },
        });
        if (
            deleteRecipient &&
            deleteRecipient.data &&
            deleteRecipient.status >= 200 &&
            deleteRecipient.status < 300
        ) {
            yield put(actions.deleteRecipientsSuccess(deleteRecipient.data));
        } else {
            yield put(
                actions.deleteRecipientsError({
                    message: 'Failed to delete recipient',
                    status: deleteRecipient.status,
                })
            );
        }
    } catch (err) {
        yield put(
            actions.deleteRecipientsError({
                message: 'Invalid OTP',
                status: 403,
            })
        );
    }
}

export function* getAddRecipientOtp(action) {
    const { payload } = action;
    const recipientName = payload.company_name ?? `${payload.first_name} ${payload.last_name}`;
    try {
        const response = yield call(request().post, 'auth/generate-request-otp', {
            message: `{otp} is your passcode to add the recipient named ${recipientName}. Never share this code.`,
            subject: 'Request to add recipient',
            request: {
                path: `//v1/recipient`,
                method: 'POST',
                payload: payload,
            },
            token: getAccessToken(),
            delivery_channel: 'sms',
        });
        if (response) {
            yield put(actions.getAddRecipientOtpSuccess(response.data));
        }
    } catch (err) {
        yield put(
            actions.getAddRecipientOtpError({
                message: err.message,
                status: 403,
            })
        );
    }
}

export function* getUpdateRecipientOtp(action) {
    const { payload } = action;
    try {
        const response = yield call(request().post, 'auth/generate-request-otp', {
            message: `{otp} is your passcode to update the recipient named ${payload.name}. Never share this code.`,
            subject: 'Request to update recipient',
            request: {
                path: `//v1/recipient/${payload.recipient_id}`,
                method: 'PATCH',
                payload: {
                    nickname: payload.nickname ? payload.nickname : '',
                },
            },
            token: getAccessToken(),
            delivery_channel: 'sms',
        });
        if (response) {
            yield put(actions.getUpdateRecipientOtpSuccess(response.data));
        }
    } catch (err) {
        yield put(
            actions.getUpdateRecipientOtpError({
                message: err.message,
                status: 403,
            })
        );
    }
}

export function* getDeleteRecipientOtp(action) {
    const { payload } = action;
    try {
        const message = `{otp} is your passcode to delete the recipient named ${payload.name}. Never share this code.`;
        const response = yield call(request().post, 'auth/generate-request-otp', {
            message,
            subject: 'Request to delete recipient',
            request: {
                path: `//v1/recipient/${payload.recipient_id}`,
                method: 'DELETE',
                payload: {},
            },
            token: getAccessToken(),
            delivery_channel: 'sms',
        });
        if (response) {
            yield put(actions.getDeleteRecipientOtpSuccess(response.data));
        }
    } catch (err) {
        yield put(
            actions.getDeleteRecipientOtpError({
                message: err.message,
                status: 403,
            })
        );
    }
}

export function* getSendFundRecipients(action) {
    const { payload } = action;
    const queryParams = getQueryParams(payload);
    try {
        const recipients = yield call(request().get, getRecipient.url(queryParams));
        if (recipients) {
            yield put(actions.getSendFundRecipientsSuccess(recipients.data));
        }
    } catch (err) {
        yield put(actions.getSendFundRecipientsError(err.message));
    }
}

export function* recipientsSaga() {
    yield takeLatest(actions.getRecipients.type, getRecipients);
    yield takeLatest(actions.createRecipients.type, addRecipient);
    yield takeLatest(actions.deleteRecipients.type, deleteRecipient);
    yield takeLatest(actions.updateRecipients.type, updateRecipient);
    yield takeLatest(actions.getAddRecipientOtp.type, getAddRecipientOtp);
    yield takeLatest(actions.getUpdateRecipientOtp.type, getUpdateRecipientOtp);
    yield takeLatest(actions.getDeleteRecipientOtp.type, getDeleteRecipientOtp);
    yield takeLatest(actions.getSendFundRecipients.type, getSendFundRecipients);
    yield takeLatest(actions.getPaymentPurposeCodes.type,getPaymentPurposeCodesAction);
    yield takeLatest(actions.validateRecipient.type, validateRecipientAction);
}
