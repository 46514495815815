import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { ClickAwayListener, Paper, Popper, styled } from '@mui/material';
import selectAppState from 'AppSelectors';
import { getUserTypes } from 'store/selectors';
import { CustomTooltip, Icon } from 'components/common';
import { accountFeatureFlag, isProd } from 'utils/helpers';
import { AllowedUsers, belongsTo } from 'utils/auth';
import styles from 'assets/styles/account.module.scss';
import { MODAL_TYPE } from './constants';
import AddFundsModal from './AddFundsModal';
import CreateSubAccount from './CreateSubAccount';
import UpdateAccount from './UpdateAccount';
import CloseSubAccount from './CloseSubAccount';

export default function AccountsKebabMenu({ account, activeKebabId, setActiveKebabId }) {
    const [anchorEl, setAnchorEl] = useState(false);
    const { featureFlags } = selectAppState();
    const userTypes = useSelector(getUserTypes);
    const [displayModal, setDisplayModal] = useState('');
    const [accountMeta, setAccountMeta] = useState({
        accountId: '',
        currency: '',
    });
    const isKebabOpen = activeKebabId === account.id;

    const onModalClose = (val = false) => setDisplayModal(val);

    const handleClickAway = (e) => {
        setActiveKebabId(null);
    };

    const handleKebabClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setActiveKebabId(isKebabOpen ? null : account.id);
    };

    const copyAccount = useCallback((account) => {
        var toCopy = account.account_name + '\n';
        if (account.account_number) {
            toCopy += `Account number: ${account?.account_number} `;
        }
        if (account.sort_code) {
            toCopy += `Sort code: ${account?.sort_code} `;
        }
        if (account.iban) {
            toCopy += `IBAN: ${account?.iban} `;
        }
        if (account.bic) {
            toCopy += `BIC: ${account?.bic} `;
        }
        return toCopy.trim();
    }, []);

    const StyledPopper = styled(Popper)(() => ({
        zIndex: 1,
        '.MuiPaper-root': {
            padding: '20px 22px',
            display: 'flex',
            gap: '24px',
            flexDirection: 'column',
        },
        '.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded': {
            margin: 0,
            borderRadius: '8px',
        },
    }));

    return (
        <div className={styles.ctaBox} onClick={handleKebabClick}>
            <div className={cx(styles.kebabIcon, isKebabOpen && styles.kebabIconHilight)}>
                <Icon name={'kebab-menu'} />
            </div>
            <ClickAwayListener onClickAway={handleClickAway}>
                <StyledPopper
                    id={'default-StyledPopper-id'}
                    disablePortal={true}
                    open={isKebabOpen}
                    anchorEl={anchorEl}
                    placement="bottom"
                    modifiers={[
                        {
                            name: 'flip',
                            enabled: true,
                            options: {
                                fallbackPlacements: ['top'],
                                rootBoundary: 'viewport',
                            },
                        },
                        {
                            name: 'offset',
                            options: {
                                offset: [-34, 14],
                            },
                        },
                    ]}>
                    <Paper>
                        <div
                            className={cx(styles.actionsIcon)}
                            disabled={account.status === 'pending'}
                            onClick={(e) => {
                                e.stopPropagation();
                                navigator.clipboard.writeText(copyAccount(account));
                            }}>
                            <CustomTooltip
                                info={'Copy bank account details'}
                                enableInfoStyle={false}>
                                <Icon name={'copy'} className={cx(styles.copyIcon)} />
                            </CustomTooltip>
                        </div>

                        {featureFlags.create_account_enabled &&
                            accountFeatureFlag(
                                account,
                                'linked_account_creation_enabled',
                                true
                            ) && (
                                <div
                                    className={cx(styles.actionsIcon)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDisplayModal(MODAL_TYPE.CreateSubAccount);
                                        setAccountMeta({
                                            currency: account.currency,
                                            accountId: account.id,
                                            accountName: account.account_name,
                                        });
                                    }}
                                    disabled={
                                        !belongsTo(userTypes, AllowedUsers.AccountAdmin) ||
                                        account.status !== 'active'
                                    }>
                                    <CustomTooltip info={'Create account'} enableInfoStyle={false}>
                                        <Icon name={MODAL_TYPE.CreateSubAccount} />
                                    </CustomTooltip>
                                </div>
                            )}

                        {featureFlags.update_account_enabled && (
                            <div
                                className={cx(styles.actionsIcon)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDisplayModal(MODAL_TYPE.UpdateAccount);
                                    setAccountMeta({
                                        accountId: account.id,
                                        accountName:
                                            account.client_account_name || account.account_name,
                                    });
                                }}
                                disabled={
                                    !belongsTo(userTypes, AllowedUsers.AccountAdmin) ||
                                    account.status !== 'active'
                                }>
                                <CustomTooltip info={'Update account'} enableInfoStyle={false}>
                                    <Icon name={MODAL_TYPE.UpdateAccount} />
                                </CustomTooltip>
                            </div>
                        )}

                        {featureFlags.close_account_enabled &&
                            accountFeatureFlag(account, 'account_closure_enabled', true) && (
                                <div
                                    className={cx(styles.actionsIconWarning)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDisplayModal(MODAL_TYPE.CloseAccount);
                                        setAccountMeta({
                                            accountId: account.id,
                                            accountName:
                                                account.client_account_name || account.account_name,
                                            balance: account.balance,
                                        });
                                    }}
                                    disabled={
                                        !belongsTo(userTypes, AllowedUsers.AccountAdmin) ||
                                        account.status !== 'active'
                                    }>
                                    <CustomTooltip info={'Close account'} enableInfoStyle={false}>
                                        <Icon name={MODAL_TYPE.CloseAccount} />
                                    </CustomTooltip>
                                </div>
                            )}

                        {!isProd && (
                            <div
                                className={cx(styles.actionsIconSandbox)}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDisplayModal(MODAL_TYPE.AddFunds);
                                    setAccountMeta({
                                        accountId: account.id,
                                        currency: account.currency,
                                    });
                                }}
                                disabled={
                                    !belongsTo(userTypes, AllowedUsers.AddFunds) ||
                                    account.status !== 'active'
                                }>
                                <CustomTooltip info={'Add funds'} enableInfoStyle={false}>
                                    <Icon name={'add-funds'} />
                                </CustomTooltip>
                            </div>
                        )}
                    </Paper>
                </StyledPopper>
            </ClickAwayListener>
            {featureFlags.create_account_enabled && (
                <CreateSubAccount
                    displayModal={displayModal === MODAL_TYPE.CreateSubAccount}
                    accountMeta={accountMeta}
                    handleModal={onModalClose}
                    pageType="summary"
                />
            )}
            {featureFlags.update_account_enabled && (
                <UpdateAccount
                    displayModal={displayModal === MODAL_TYPE.UpdateAccount}
                    accountMeta={accountMeta}
                    handleModal={onModalClose}
                    pageType="summary"
                />
            )}
            {featureFlags.close_account_enabled && (
                <CloseSubAccount
                    displayModal={displayModal === MODAL_TYPE.CloseAccount}
                    accountMeta={accountMeta}
                    handleModal={onModalClose}
                    pageType="summary"
                />
            )}
            <AddFundsModal
                displayModal={displayModal === MODAL_TYPE.AddFunds}
                accountMeta={accountMeta}
                handleModal={onModalClose}
            />
        </div>
    );
}
