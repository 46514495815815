export const POST_LOGIN_PENDING = '@AUTH/POST_LOGIN_PENDING';
export const POST_LOGIN_SUCCESS = '@AUTH/POST_LOGIN_SUCCESS';
export const POST_LOGIN_FAILURE = '@AUTH/POST_LOGIN_FAILURE';

export const POST_LOGIN_OTP_PENDING = '@AUTH/POST_LOGIN_OTP_PENDING';
export const POST_LOGIN_OTP_SUCCESS = '@AUTH/POST_LOGIN_OTP_SUCCESS';
export const POST_LOGIN_OTP_FAILURE = '@AUTH/POST_LOGIN_OTP_FAILURE';

export const LOGOUT = '@AUTH/LOGOUT';
export const LOGOUT_SUCCESS = '@AUTH/LOGOUT_SUCCESS';
export const CLEAR_STATE = '@AUTH/CLEAR_STATE';
export const CLEAR_ERRORS = '@AUTH/CLEAR_ERRORS';
export const RESET_EXPIRED_SESSION = '@AUTH/RESET_EXPIRED_SESSION';

export const SET_RE_CAPTCHA_TOKEN = '@AUTH/SET_RE_CAPTCHA_TOKEN';
