import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as ApiKey } from './api-key.svg';
import { ReactComponent as Background } from './background.svg';
import { ReactComponent as BackgroundMD } from './background-md.svg';
import { ReactComponent as BackgroundSM } from './background-sm.svg';
import { ReactComponent as Expired } from './expired.svg';
import { ReactComponent as AccountBalance } from './account-balance.svg';
import { ReactComponent as Language } from './language.svg';
import { ReactComponent as GppGood } from './gpp-good.svg';
import { ReactComponent as HidePassword } from './hide-password.svg';
import { ReactComponent as ShowPassword } from './show-password.svg';
import { ReactComponent as Payments } from './payments.svg';
import { ReactComponent as Accounts } from './accounts.svg';
import { ReactComponent as Transactions } from './transactions.svg';
import { ReactComponent as Balance } from './balance.svg';
import { ReactComponent as ButtonLoading } from './button-loading.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Profile } from './profile.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as CaretDown } from './caret-down.svg';
import { ReactComponent as CaretRight } from './caret-right.svg';
import { ReactComponent as WWW } from './www.svg';
import { ReactComponent as Checkmark } from './checkmark.svg';
import { ReactComponent as Ellipse } from './ellipse.svg';
import { ReactComponent as EllipseActive } from './ellipse-active.svg';
import { ReactComponent as EllipseCompleted } from './ellipse-copleted.svg';
import { ReactComponent as DottedLine } from './dotted-line.svg';
import { ReactComponent as Remove } from './remove.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as EditBorders } from './edit-borders.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Loading } from './loading.svg';
import { ReactComponent as Passed } from './passed.svg';
import { ReactComponent as LastStep } from './last-step.svg';
import { ReactComponent as HorizontalDashes } from './horizontal-dashes.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as TooltipArrow } from './tooltip-arrow.svg';
import { ReactComponent as Dashboard } from './dashboard.svg';
import { ReactComponent as Completed } from './completed.svg';
import { ReactComponent as DashboardInfo } from './dashboard-info.svg';
import { ReactComponent as GB } from './flags/GB.svg';
import { ReactComponent as EUR } from './flags/euro.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as BlueGradientDot } from './blue-gradient-dot.svg';
import { ReactComponent as GreenGradientDot } from './green-gradient-dot.svg';
import { ReactComponent as TransparentDot } from './transparent-dot.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as DottedHorizontal } from './dotted-line-horizontal.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as UserAdd } from './users-add.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as SendExchange } from './send-exchange.svg';
import { ReactComponent as CaretUp } from './caret-up.svg';
import { ReactComponent as Globe } from './world.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as Developer } from './developer.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as NoFunds } from './insufficient-funds.svg';
import { ReactComponent as SubAccounts } from './sub-accounts.svg';
import { ReactComponent as UserFilled } from './user-filled.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as DownloadReport } from './download-report.svg';
import { ReactComponent as FailedCircle } from './failed-circle.svg';
import { ReactComponent as SuccessCircle } from './success-circle.svg';
import { ReactComponent as PendingCircle } from './pending-circle.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as CircleGradient } from './circle-gradient.svg';
import { ReactComponent as ErrorClose } from './error-close.svg';
import { ReactComponent as StepDefault } from './step-default.svg';
import { ReactComponent as StepActive } from './step-active.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as Bin } from './bin.svg';
import { ReactComponent as SubAccountCircle } from './sub-account-circle.svg';
import { ReactComponent as SubAccount } from './sub-account.svg';
import { ReactComponent as DisableAccount } from './disable-account.svg';
import { ReactComponent as CanNotDisable } from './cannot-disable.svg';
import { ReactComponent as ModalImage } from './modal-image.svg';
import { ReactComponent as FundsSuccess } from './funds-success.svg';
import { ReactComponent as FundsError } from './funds-error.svg';
import { ReactComponent as ColoredCopy } from './colored-copy.svg';
import { ReactComponent as InfoAlert } from './alert-info.svg';
import { ReactComponent as HourGlassEmpty } from './hour-glass-empty.svg';
import { ReactComponent as Verified } from './verified.svg';
import { ReactComponent as FileDownload } from './file-download.svg';
import { ReactComponent as PaymentRequest } from './payment_request.svg';
import { ReactComponent as Beneficiaries } from './beneficiaries.svg';
import { ReactComponent as ApprovePayments } from './approve-payments.svg';
import { ReactComponent as MonthlyStatement } from './monthly-statement.svg';
import { ReactComponent as ApprovePaymentAction } from './approve-payment-action.svg';
import { ReactComponent as WarningStatus } from './warning-status.svg';
import { ReactComponent as Created } from './created.svg';
import { ReactComponent as Retry } from './retry.svg';
import { ReactComponent as Reports } from './reports.svg';
import { ReactComponent as ReportsIcon } from './reports-icon.svg';
import { ReactComponent as AddFunds } from './add-funds.svg';
import { ReactComponent as AccountStatusActive } from './account-status-active.svg';
import { ReactComponent as AccountStatusPending } from './account-status-pending.svg';
import { ReactComponent as AccountStatusBlocked } from './account-status-blocked.svg';
import { ReactComponent as TransactionStatusSuccess } from './transaction-status-success.svg';
import { ReactComponent as TransactionStatusProcessing } from './transaction-status-processing.svg';
import { ReactComponent as TransactionStatusFailed } from './transaction-status-failed.svg';
import { ReactComponent as AccountStatusClosed } from './account-status-closed.svg';
import { ReactComponent as PaymentRequestStatusApproved } from './payment-request-status-approved.svg';
import { ReactComponent as PaymentRequestStatusCreated } from './payment-request-status-created.svg';
import { ReactComponent as PaymentRequestStatusExpired } from './payment-request-status-expired.svg';
import { ReactComponent as PaymentRequestStatusProcessing } from './payment-request-status-processing.svg';
import { ReactComponent as PaymentRequestStatusRejected } from './payment-request-status-rejected.svg';
import { ReactComponent as PaymentRequestStatusRetry } from './payment-request-status-retry.svg';
import { ReactComponent as ChargeFee } from './charge-fee.svg';
import { ReactComponent as ChangeTransactionStatus } from './change-transaction-status.svg';
import { ReactComponent as Quote } from './quote.svg';
import { ReactComponent as CloseModal } from './close-modal.svg';
import { ReactComponent as ModalInfo } from './modal-info.svg';
import { ReactComponent as ModalError } from './modal-error.svg';
import { ReactComponent as ModalSuccess } from './modal-success.svg';
import { ReactComponent as ModalWarning } from './modal-warning.svg';
import { ReactComponent as ModalWarningTerminal } from './modal-warning-terminal.svg';
import { ReactComponent as MergeFavicon } from './merge_favicon.svg';
import { ReactComponent as LeftChevron } from './chevron-left.svg';
import { ReactComponent as RightChevron } from './chevron-right.svg';
import { ReactComponent as DownChevron } from './chevron-down-solid.svg';
import { ReactComponent as RecipientPerson } from './recipient-person.svg';
import { ReactComponent as RecipientBusiness } from './recipient-business.svg';
import { ReactComponent as DownloadAvailable } from './download-available.svg';
import { ReactComponent as DownloadProcessing } from './download-processing.svg';
import { ReactComponent as DownloadExpired } from './download-expired.svg';
import { ReactComponent as SuccessTick } from './success-tick.svg';
import { ReactComponent as CreateSubAccount } from './create-sub-account.svg';
import { ReactComponent as UpdateAccount } from './account_update.svg';
import { ReactComponent as CloseAccount } from './account_close.svg';
import { ReactComponent as KebabMenu } from './kebab-menu-green.svg';
import { ReactComponent as WarningOutline } from './warning-outline.svg';

const Icons = {
    logo: Logo,
    'api-key': ApiKey,
    'background-lg': Background,
    'background-md': BackgroundMD,
    'background-sm': BackgroundSM,
    'account-balance': AccountBalance,
    language: Language,
    'gpp-good': GppGood,
    'hide-password': HidePassword,
    'show-password': ShowPassword,
    payments: Payments,
    accounts: Accounts,
    transactions: Transactions,
    balance: Balance,
    'button-loading': ButtonLoading,
    developer: Developer,
    expired: Expired,
    settings: Settings,
    profile: Profile,
    logout: Logout,
    'caret-down': CaretDown,
    'caret-right': CaretRight,
    www: WWW,
    checkmark: Checkmark,
    ellipse: Ellipse,
    'ellipse-active': EllipseActive,
    'ellipse-completed': EllipseCompleted,
    'dotted-line': DottedLine,
    remove: Remove,
    edit: Edit,
    'edit-borders': EditBorders,
    close: Close,
    loading: Loading,
    passed: Passed,
    'last-step': LastStep,
    'horizontal-dashes': HorizontalDashes,
    info: Info,
    'tooltip-arrow': TooltipArrow,
    dashboard: Dashboard,
    completed: Completed,
    'dashboard-info': DashboardInfo,
    gb: GB,
    eur: EUR,
    refresh: Refresh,
    'blue-gradient-dot': BlueGradientDot,
    'green-gradient-dot': GreenGradientDot,
    beneficiaries: Beneficiaries,
    search: Search,
    users: Users,
    'user-add': UserAdd,
    send: Send,
    'send-exchange': SendExchange,
    'caret-up': CaretUp,
    globe: Globe,
    'transparent-dot': TransparentDot,
    'dotted-horizontal': DottedHorizontal,
    warning: Warning,
    menu: Menu,
    delete: Delete,
    'arrow-left': ArrowLeft,
    'arrow-right': ArrowRight,
    'insufficient-funds': NoFunds,
    'sub-accounts': SubAccounts,
    'user-filled': UserFilled,
    copy: Copy,
    'failed-circle': FailedCircle,
    'success-circle': SuccessCircle,
    'pending-circle': PendingCircle,
    calendar: Calendar,
    'circle-gradient': CircleGradient,
    'error-close': ErrorClose,
    'step-active': StepActive,
    'step-default': StepDefault,
    upload: Upload,
    file: File,
    bin: Bin,
    'sub-account': SubAccount,
    'sub-account-circle': SubAccountCircle,
    'disable-account': DisableAccount,
    'cannot-disable': CanNotDisable,
    'modal-image': ModalImage,
    'funds-success': FundsSuccess,
    'funds-error': FundsError,
    'colored-copy': ColoredCopy,
    'alert-info': InfoAlert,
    'hour-glass-empty': HourGlassEmpty,
    verified: Verified,
    'file-download': FileDownload,
    'monthly-statement': MonthlyStatement,
    'payment-request': PaymentRequest,
    'approve-payments': ApprovePayments,
    'approve-payment-action': ApprovePaymentAction,
    'warning-status': WarningStatus,
    created: Created,
    retry: Retry,
    reports: Reports,
    'reports-icon': ReportsIcon,
    'download-report': DownloadReport,
    'add-funds': AddFunds,
    'account-status-active': AccountStatusActive,
    'account-status-pending': AccountStatusPending,
    'account-status-blocked': AccountStatusBlocked,
    'account-status-closed': AccountStatusClosed,
    'transaction-status-success': TransactionStatusSuccess,
    'transaction-status-processing': TransactionStatusProcessing,
    'transaction-status-failed': TransactionStatusFailed,
    'payment-request-status-approved': PaymentRequestStatusApproved,
    'payment-request-status-created': PaymentRequestStatusCreated,
    'payment-request-status-expired': PaymentRequestStatusExpired,
    'payment-request-status-processing': PaymentRequestStatusProcessing,
    'payment-request-status-rejected': PaymentRequestStatusRejected,
    'payment-request-status-retry': PaymentRequestStatusRetry,
    'charge-fee': ChargeFee,
    'change-transaction-status': ChangeTransactionStatus,
    quote: Quote,
    'close-modal': CloseModal,
    'merge-favicon': MergeFavicon,
    'left-chevron': LeftChevron,
    'modal-info': ModalInfo,
    'modal-error': ModalError,
    'modal-warning': ModalWarning,
    'modal-warning-terminal': ModalWarningTerminal,
    'modal-success': ModalSuccess,
    chevron: RightChevron,
    'down-chevron': DownChevron,
    'recipient-business': RecipientBusiness,
    'recipient-person': RecipientPerson,
    'download-available': DownloadAvailable,
    'download-processing': DownloadProcessing,
    'download-expired': DownloadExpired,
    'success-tick': SuccessTick,
    'create-sub-account': CreateSubAccount,
    'update-account': UpdateAccount,
    'close-account': CloseAccount,
    'kebab-menu': KebabMenu,
    'warning-outline': WarningOutline,
};
export default Icons;
