import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/company';
import { request } from '../../utils/api';

function* getCompanyDataWorker() {
    try {
        const { data } = yield call(request().get, 'business-information/company');
        yield put(actions.getCompanySuccess(data));
    } catch (error) {
        const response = error?.response;
        const data = response?.data;
        const status = response?.status;

        if (!status || !data) {
            yield put(actions.getCompanyError('Something went wrong!', 500));
            return;
        }
        yield put(actions.getCompanyError(data?.message, status));
    }
}

export function* companySaga() {
    yield takeLatest(actions.getCompanyData.type, getCompanyDataWorker);
}
