import React, { useRef } from 'react';
import { MenuItem, Select } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

import CustomTooltip from '../CustomTooltip';
import Icon from '../Icon';

import styles from 'assets/styles/common.module.scss';
import cx from 'classnames';
import CurrencyIcon from '../CurrencyIcon';

const SelectField = (props) => {
    const ref = useRef(null);
    const {
        className,
        selectClassName,
        name,
        options,
        required,
        label,
        hasLabel = true,
        value,
        onChange,
        helperText,
        error,
        disable,
        disableItems,
        currencyIcon,
        info,
        placeHolderText,
        allowDeselect = true,
    } = props;
    const handleChange = (name, optionValue) => {
        if (allowDeselect && optionValue === value) {
            onChange(name, null);
        } else {
            onChange(name, optionValue);
        }
    };

    const materialTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        width: '100%',
                    },
                    input: {
                        padding: '12px 14px',
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    icon: {
                        color: '#999',
                    },
                },
            },
        },
    });

    return (
        <div style={{ height: error ? (helperText? '140px': '110px') : (helperText? '110px' : '80px') }} className={className}>
            {hasLabel && (
                <div className={cx(disable ? styles.disable : styles.label)}>
                    {label}
                    {required && <span className={cx(styles.asterisk)}> *</span>}
                    {info && (
                        <CustomTooltip info={info}>
                            <Icon name={'info'} />
                        </CustomTooltip>
                    )}
                </div>
            )}
            <ThemeProvider theme={materialTheme}>
                <div className={cx([error ? styles.errorBorder : styles.default, selectClassName])}>
                    <Select
                        ref={ref}
                        name={name}
                        disabled={disable}
                        id="demo-controlled-open-select"
                        value={value}
                        defaultValue={value || ''}
                        error={error}
                        displayEmpty={true}
                        MenuProps={{
                            PaperProps: {
                              style: {
                                width: ref?.current?.clientWidth ? ref?.current?.clientWidth+'px': '0px',
                              },
                            },
                        }}                        
                        renderValue={(selectedValue) => {
                            const selectedOption = options.filter(
                                ({ value }) => value === selectedValue
                            );
                            return selectedOption?.length ? (
                                selectedOption.map(({ label, value }) => (
                                    <>
                                        {currencyIcon && (
                                            <CurrencyIcon
                                                key={value}
                                                currency={value}
                                                className={cx(styles.flag)}
                                            />
                                        )}
                                        <p className={cx(styles.itemText)}>{label}</p>
                                    </>
                                ))
                            ) : (
                                <span style={{ fontWeight: '600', fontSize: '14px' }}>
                                    {placeHolderText}
                                </span>
                            );
                        }}>
                        {options?.map((option) => (
                            <MenuItem
                                style={{
                                    maxWidth: ref?.current?.offsetWidth,
                                    marginLeft: 0,
                                }}
                                // Using custom on-click handler to support deselecting the option
                                onClick={() => handleChange(name, option.value)}
                                value={option.value || ''}
                                defaultValue={option.value || ''}
                                key={option.value}
                                disabled={disableItems}
                                className={cx([styles.selectMenuItem])}>
                                {option.value === value && (
                                    <Icon name={'success-tick'} className={cx(styles.checkmark)} />
                                )}
                                <div className={cx(styles.listMenuItem)}>
                                    {currencyIcon && (
                                        <CurrencyIcon
                                            currency={option.value}
                                            className={cx(styles.flag)}
                                        />
                                    )}
                                    <p className={cx(styles.itemText)}>{option.label}</p>
                                </div>
                                {option.value === 'line' && <div className={cx(styles.divider)} />}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                {helperText && <p style={{marginTop: '5px'}} className={cx(styles.asterisk)}>{helperText}</p>}
                </ThemeProvider>
        </div>
    );
};

export default SelectField;
