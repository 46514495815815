import { Avatar, Box, Grid } from '@mui/material';
import styles from 'assets/styles/account.module.scss';
import cx from 'classnames';
import { CustomTooltip, Icon, Loading } from 'components/common';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserTypes } from 'store/selectors/auth';
import { AllowedUsers, belongsTo } from 'utils/auth';
import {
    disableAccountCreatedInCurrentMonth,
    formatAmount,
    getCurrencyDescription,
    getCurrencySymbol,
    getInitials,
    isQuoteEnabled,
    toCapitalize,
} from 'utils/helpers';
import { getAccountStatusIcon, getAccountStatusDescription } from './utils';
import { actions } from 'store/slice/account';
import selectState from 'store/selectors/account';
import CurrencyIcon from 'components/common/CurrencyIcon';
import NumberFormat from 'react-number-format';
import selectAppState from 'AppSelectors';
import { MAIN_ACCOUNT } from './constants';
import AccountsKebabMenu from './AccountsKebabMenu';

const AccountsSummary = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { featureFlags } = selectAppState();
    const { loading, error, summary } = selectState();
    const userTypes = useSelector(getUserTypes);
    const [activeKebabId, setActiveKebabId] = useState(null);

    useEffect(() => {
        dispatch(actions.getAccountsSummary());
    }, [dispatch]);

    const handleRefresh = useCallback(() => {
        if (!loading) {
            dispatch(actions.getAccountsSummary());
        }
    }, [dispatch, loading]);

    return (
        <Grid container className={cx(styles.container)}>
            <div className={cx(styles.header)}>
                <h1 className={cx(styles.title)}>Accounts</h1>
                <Icon name={'refresh'} onClick={() => handleRefresh()} />
            </div>
            {loading ? (
                <Loading className={cx(styles.loading)} />
            ) : (
                summary?.currencies?.map((account) => (
                    <Grid container key={account.currency}>
                        <Grid item sm={12} className={cx(styles.currencyDetails)}>
                            <div className={cx(styles.currencyHeader)}>
                                <div className={cx(styles.currencyTitle)}>
                                    <CustomTooltip info={account.currency}>
                                        <div className={cx(styles.accountName)}>
                                            <CurrencyIcon
                                                rounded
                                                style={{
                                                    height: 'auto',
                                                    width: '30px',
                                                }}
                                                currency={account.currency}
                                            />
                                            &nbsp;
                                            {getCurrencyDescription(account.currency)}
                                        </div>
                                    </CustomTooltip>
                                    <CustomTooltip info={'Refresh details'}>
                                        <Icon name={'refresh'} onClick={handleRefresh} />
                                    </CustomTooltip>
                                </div>
                                <div className={cx(styles.cumulativeBalance)}>
                                    <div className={cx(styles.totalBalance)}>
                                        <NumberFormat
                                            value={formatAmount(account.total_available_balance)}
                                            thousandSeparator={true}
                                            displayType={'text'}
                                            decimalScale={2}
                                            prefix={getCurrencySymbol(account.currency)}
                                        />
                                    </div>
                                    <div
                                        className={cx(styles.viewDetails)}
                                        onClick={() => {
                                            navigate(`/dashboard/accounts/${account.currency}`);
                                        }}>
                                        View details <Icon name={'chevron'} />
                                    </div>
                                </div>
                            </div>
                            <div className={cx(styles.snapShot)}>
                                {account.top_accounts.map((account) => (
                                    <div key={account.id} className={cx(styles.detail)}>
                                        <div className={cx(styles.summaryPanel)}>
                                            <div style={{ position: 'relative' }}>
                                                <Avatar className={cx(styles.avatar)}>
                                                    {getInitials(
                                                        account.client_account_name ||
                                                            account.account_name
                                                    )}
                                                </Avatar>
                                                <Box className={cx(styles.avatarSubIconSummary)}>
                                                    <CustomTooltip
                                                        info={getAccountStatusDescription(
                                                            account.status
                                                        )}>
                                                        <Icon
                                                            name={getAccountStatusIcon(
                                                                account.status
                                                            )}
                                                            width="15px"
                                                            height="15px"
                                                        />
                                                    </CustomTooltip>
                                                </Box>
                                            </div>
                                            <div className={cx(styles.clientName)}>
                                                {toCapitalize(
                                                    account.client_account_name ||
                                                        account.account_name
                                                )}
                                            </div>
                                            <div className={cx(styles.bankDetails)}>
                                                *
                                                {account.iban?.substring(account.iban.length - 4) ||
                                                    account.account_number?.substring(
                                                        account.account_number.length - 4
                                                    )}
                                            </div>
                                            {account.type === MAIN_ACCOUNT && (
                                                <div className={cx(styles.tagContainer)}>
                                                    <div className={cx(styles.typeTag)}>Main</div>
                                                </div>
                                            )}
                                        </div>
                                        <div className={cx(styles.summaryBalance)}>
                                            <div className={styles.actions}>
                                                <div className={cx(styles.accBalance)}>
                                                    <NumberFormat
                                                        value={formatAmount(account.balance)}
                                                        thousandSeparator={true}
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        prefix={getCurrencySymbol(account.currency)}
                                                    />
                                                </div>
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={account.status === 'pending'} // Enable view transactions for other than pending accounts
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/accounts/${account.id}/transactions`,
                                                        })
                                                    }>
                                                    <CustomTooltip info={'View Transactions'}>
                                                        <Icon name={'transactions'} />
                                                    </CustomTooltip>
                                                </div>
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={
                                                        !belongsTo(
                                                            userTypes,
                                                            AllowedUsers.SendFunds
                                                        ) ||
                                                        account.status !== 'active' ||
                                                        parseFloat(account.balance) === 0
                                                    }
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/funds`,
                                                            search: `?from=${account.id}`,
                                                        })
                                                    }>
                                                    <CustomTooltip info={'Send funds'}>
                                                        <Icon
                                                            name={
                                                                isQuoteEnabled(account)
                                                                    ? 'send-exchange'
                                                                    : 'send'
                                                            }
                                                        />
                                                    </CustomTooltip>
                                                </div>
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={account.status === 'pending'}
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/pending-payments`,
                                                            search: `?search_account=${
                                                                account.iban ??
                                                                account.account_number
                                                            }&currency=${account.currency}`,
                                                        })
                                                    }>
                                                    <CustomTooltip info={'Pending payments'}>
                                                        <Icon name={'approve-payments'} />
                                                    </CustomTooltip>
                                                </div>
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={
                                                        !belongsTo(
                                                            userTypes,
                                                            AllowedUsers.MakePaymentRequest
                                                        ) ||
                                                        account.status !== 'active' ||
                                                        parseFloat(account.balance) === 0
                                                    }
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/payment-request`,
                                                            search: `?from=${account.id}`,
                                                        })
                                                    }>
                                                    <CustomTooltip info={'Queue payments'}>
                                                        <Icon name={'payment-request'} />
                                                    </CustomTooltip>
                                                </div>
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={
                                                        !belongsTo(
                                                            userTypes,
                                                            AllowedUsers.Reports
                                                        ) || account.status === 'pending'
                                                    }
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/transaction-report`,
                                                            search: `?from=${account.id}`,
                                                        })
                                                    }>
                                                    <CustomTooltip info={'Transaction reports'}>
                                                        <Icon name={'reports-icon'} />
                                                    </CustomTooltip>
                                                </div>
                                                {featureFlags.monthly_account_statement_enabled && (
                                                        <div
                                                            className={cx(styles.actionsIcon)}
                                                            disabled={
                                                                !belongsTo(
                                                                    userTypes,
                                                                    AllowedUsers.Reports
                                                                ) ||
                                                                account.status === 'pending' ||
                                                                disableAccountCreatedInCurrentMonth(
                                                                    account
                                                                )
                                                            }
                                                            onClick={() =>
                                                                navigate({
                                                                    pathname: `/dashboard/account-statement`,
                                                                    search: `?from=${account.id}`,
                                                                })
                                                            }>
                                                            <CustomTooltip
                                                                info={'Monthly account statement'}>
                                                                <Icon name={'monthly-statement'} />
                                                            </CustomTooltip>
                                                        </div>
                                                    )}
                                                <AccountsKebabMenu
                                                    account={account}
                                                    activeKebabId={activeKebabId}
                                                    setActiveKebabId={setActiveKebabId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                ))
            )}
            {error?.message && !loading && (
                <div className={cx(styles.empty, styles.error)}>
                    <p>Something went wrong. Please try again later.</p>
                </div>
            )}
        </Grid>
    );
};

export default AccountsSummary;
