import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { InputField, SelectField } from 'components/common';
import cx from 'classnames';
import styles from 'assets/styles/recipients.module.scss';
import { useFormik } from 'formik';
import { SelectCountry } from 'components/common/SelectInput';
import { Country } from 'country-state-city';
import * as yup from 'yup';
import { toCapitalize } from 'utils/helpers';
import ReviewBankDetails from './ReviewBankDetails';
import { ButtonV2 } from 'components/common/Button';
import selectRecipientState from 'store/selectors/recipients';
import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/recipients';
import selectCompanyState from 'store/selectors/company';

const AddResidenceDetails = ({ recipient, onSubmit, onEdit, onCancel }) => {
    const countries = Country.getAllCountries();
    const {
        paymentPurposeCodes
    } = selectRecipientState();
    const dispatch = useDispatch();
    const { isMerlinOnly } = selectCompanyState();

    const recipientCountries = countries.map((country) => ({
        label: country.name,
        value: country.isoCode,
    }));

    const [paymentPurposeTypes, setPaymentPurposeType] = useState([]);
    const [paymentPurposeError, setPaymentPurposeError] = useState(false);

    const renderValidationSchema = (type) => {
        return yup.lazy((values) => {
            const validationSchema = {
                email: yup.string('Email').email('Enter a valid email'),
                nickname: yup.string('Nickname'),
                address_line1: yup.string('Recipient line1'),
                address_line2: yup.string('Recipient line2'),
                address_city: yup.string('Recipient city'),
                address_state: yup.string('Recipient state'),
                address_postcode: yup.string('Recipient Post code'),
                address_country: yup
                    .string('Recipient country')
                    .test(
                        'countryValidation',
                        'Recipient country is required',
                        function () {
                            if (values.address_country) {
                                return true;
                            }
                            const addressFields = [values.address_line1, values.address_line2, values.address_city, values.address_state, values.address_postcode];
                            if (addressFields.some(x => !!x )) {
                                return false;
                            }
                            return true;
                        }
                    ),
                default_purpose: yup.string('Payment purpose').nullable(),
            };

            const validationSchemaWithType = {
                ...validationSchema,
                ...validationSchemaForType(type),
            };

            const validationSchemeWithDefaultPurpose = {
                ...validationSchemaWithType,
                ...validationSchemaForDefaultPurpose(),
            }
            return yup.object(validationSchemeWithDefaultPurpose);
        });
    };

    const validationSchemaForType = (type) => {
        if (type === 'person') {
            return {
                first_name: yup
                    .string('Enter your first name')
                    .required('Please enter the first name'),
                last_name: yup
                    .string('Enter your last name')
                    .required('Please enter the last name'),
            };
        } else if (type === 'business') {
            return {
                company_name: yup
                    .string('Enter your company name')
                    .required('Please enter the company name'),
            };
        }
        return {};
    };

    const validationSchemaForDefaultPurpose = () => {
        if (isMerlinOnly) {
            return {
                default_purpose: yup.string('Payment purpose').nullable(),
            };
        } else {
            return {
                default_purpose: yup.string('Payment purpose').required(),
            };
        }
    };

    const formik = useFormik({
        initialValues: {
            first_name: recipient?.first_name,
            last_name: recipient?.last_name,
            company_name: recipient?.company_name,
            email: recipient?.email,
            nickname: recipient?.nickname,
            address_line1: recipient?.address_line1,
            address_line2: recipient?.address_line2,
            address_city: recipient?.address_city,
            address_state: recipient?.address_state,
            address_postcode: recipient?.address_postcode,
            address_country: recipient?.address_country,
            default_purpose: recipient?.default_purpose ?? '',
        },
        validationSchema: renderValidationSchema(recipient.type),
        onSubmit: (values) => {
            onSubmit(values);
        },
    });

    const handleEmailChange = useCallback(
        (event) => {
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: event.target.value?.toLowerCase()?.trim(),
            }));
        },
        [formik]
    );

    const handleSelect = (name, value) => {
        formik.setValues((data) => ({ ...data, [name]: value }));
    };

    const updateToUpperCase = useCallback(
        (event) => {
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: event.target.value?.toUpperCase(),
            }));
        },
        [formik]
    );

    const updateToTitleCase = useCallback(
        (event) => {
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: toCapitalize(event.target.value),
            }));
        },
        [formik]
    );

    const trimWhitespaces = useCallback(
        (event) => {
            // Trim the extra spaces around the string and fix the multiple spaces between string using regex
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: event.target.value?.trim().replace(/\s+/g, ' '),
            }));
        },
        [formik]
    );

    useEffect(() => {
        console.log(formik.values.default_purpose);
        if (!formik.values.default_purpose || paymentPurposeTypes.length === 0) {
            return;
        }

        const selectedPurpose = paymentPurposeTypes.filter(
            ({ value }) => value === formik.values.default_purpose
        );
        console.log(selectedPurpose);

        if (selectedPurpose.length === 0) {
            handleSelect('default_purpose', null);
        }
        // eslint-disable-next-line
    }, [paymentPurposeTypes, formik.values.default_purpose])

    const handleChange = useCallback(
        (event) => {
            formik.setValues((data) => ({
                ...data,
                [event.target.name]: event.target.value,
            }));
        },
        [formik]
    );

    const handleAutocompleteSelect = (name, value) => {
        formik.setValues((data) => ({ ...data, [name]: value }));
    };

    useEffect(() => {
        const recipientCountry = (recipient.country || recipient.bank_country)?.toUpperCase()
        dispatch(actions.getPaymentPurposeCodes({country: recipientCountry, currency: recipient.currency?.toUpperCase(), recipient_type: recipient.type, size: 500}));
    }, [dispatch, recipient.country, recipient.bank_country, recipient.currency, recipient.type]);
    
    const getDefualtPurposeDescription = (x) => {
        let description = x.default_purpose_code;
        if (x.default_purpose) {
            description += `- ${x.default_purpose}`;
        }
        if (x.additional_purpose) {
            description += ` ; ${x.additional_purpose}`;
        }
        return description;
    };

    useEffect(() => {
        if(paymentPurposeCodes?.items?.length > 0) {
            setPaymentPurposeType(
                paymentPurposeCodes.items.map(
                    x => ({...x, value: x.identifier, type: 'default_purpose', label: getDefualtPurposeDescription(x)})
                )
            );
        } else {
            setPaymentPurposeType([]);
        }
        setPaymentPurposeError(paymentPurposeCodes.error);
    }, [paymentPurposeCodes]);

    return (
        <Grid item xs={12} md={12} lg={12} sm={12} position={'relative'}>
            <Grid item xs={12}>
                <ReviewBankDetails recipient={recipient} onEdit={onEdit} />
                <div className={cx(styles.sectionTitle)}>Enter recipient details</div>
                <Grid item xs={12}>
                    {recipient.type === 'person' && (
                        <div className={cx(styles.row)}>
                            <div className={cx(styles.record, styles.w50)}>
                                <InputField
                                    name={'first_name'}
                                    label={'First name'}
                                    required={true}
                                    className={cx(styles.inputField)}
                                    onChange={handleChange}
                                    value={formik.values.first_name}
                                    error={
                                        formik.touched.first_name &&
                                        Boolean(formik.errors.first_name)
                                    }
                                    helperText={
                                        formik.touched.first_name && formik.errors.first_name
                                    }
                                />
                            </div>
                            <div className={cx(styles.record, styles.w50)}>
                                <InputField
                                    name={'last_name'}
                                    label={'Last name'}
                                    required={true}
                                    className={cx(styles.inputField)}
                                    onChange={handleChange}
                                    value={formik.values.last_name}
                                    error={
                                        formik.touched.last_name && Boolean(formik.errors.last_name)
                                    }
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                />
                            </div>
                        </div>
                    )}
                    {recipient.type === 'business' && (
                        <div className={cx(styles.row)}>
                            <div className={cx(styles.record, styles.w50)}>
                                <InputField
                                    className={cx(styles.inputField)}
                                    name={'company_name'}
                                    label={'Company name'}
                                    placeholder={'Enter company name'}
                                    onChange={updateToUpperCase}
                                    required={true}
                                    value={formik.values.company_name}
                                    error={
                                        formik.touched.company_name &&
                                        Boolean(formik.errors.company_name)
                                    }
                                    helperText={
                                        formik.touched.company_name && formik.errors.company_name
                                    }
                                    onBlur={trimWhitespaces}
                                />
                            </div>
                        </div>
                    )}
                    <div className={cx(styles.row)}>
                        <div className={cx(styles.record, styles.w50)}>
                            <InputField
                                name={'nickname'}
                                label={'Nick name'}
                                placeholder={'Enter nick name'}
                                className={cx(styles.inputField)}
                                onChange={updateToTitleCase}
                                value={formik.values.nickname}
                                error={formik.touched.nickname && Boolean(formik.errors.nickname)}
                                onBlur={trimWhitespaces}
                            />
                        </div>
                        <div className={cx(styles.record, styles.w50)}>
                            <InputField
                                name={'email'}
                                label={'Email address'}
                                placeholder={'Enter email address'}
                                className={cx(styles.inputField)}
                                onChange={handleEmailChange}
                                value={formik.values.email}
                                error={formik.touched.email && !!formik.errors.email}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            </div>
                        </div>
                </Grid>
                <div style={{ marginTop: '20px'}}>
                    <div className={cx(styles.sectionTitle)}>Enter recipient address</div>
                    <div className={cx(styles.row)}>
                        <div className={cx(styles.record, styles.w50)} style={{paddingRight: '20px', boxSizing: 'border-box' }}>
                            <SelectCountry
                                className={cx(styles.inputField)}
                                name={'address_country'}
                                value={formik.values.address_country}
                                label={'Select country'}
                                onChange={handleAutocompleteSelect}
                                countries={recipientCountries}
                            />
                        </div>
                    </div>

                    <div className={cx(styles.row)}>
                        <div className={cx(styles.record, styles.w50)}>
                            <InputField
                                name={'address_line1'}
                                label={'Line 1'}
                                className={cx(styles.inputField)}
                                placeholder={'Enter address line 1'}
                                onChange={handleChange}
                                value={formik.values.address_line1}
                                error={
                                    formik.touched.address_line1 &&
                                    Boolean(formik.errors.address_line1)
                                }
                                onBlur={trimWhitespaces}
                            />
                        </div>
                        <div className={cx(styles.record, styles.w50)}>
                            <InputField
                                name={'address_line2'}
                                label={'Line 2'}
                                className={cx(styles.inputField)}
                                placeholder={'Enter address line 2'}
                                onChange={handleChange}
                                value={formik.values.address_line2}
                                error={
                                    formik.touched.address_line2 &&
                                    Boolean(formik.errors.address_line2)
                                }
                                onBlur={trimWhitespaces}
                            />
                        </div>
                    </div>
                    <div className={cx(styles.row)}>
                        <div className={cx(styles.record, styles.w33)}>
                            <InputField
                                name={'address_city'}
                                label={'City'}
                                className={cx(styles.inputField)}
                                placeholder={'Enter city'}
                                onChange={handleChange}
                                value={formik.values.address_city}
                                error={
                                    formik.touched.address_city &&
                                    Boolean(formik.errors.address_city)
                                }
                                onBlur={trimWhitespaces}
                            />
                        </div>
                        <div className={cx(styles.record, styles.w33)}>
                            <InputField
                                name={'address_state'}
                                label={'State'}
                                className={cx(styles.inputField)}
                                placeholder={'Enter state'}
                                onChange={handleChange}
                                value={formik.values.address_state}
                                error={
                                    formik.touched.address_state &&
                                    Boolean(formik.errors.address_state)
                                }
                                onBlur={trimWhitespaces}
                            />
                        </div>
                        <div className={cx(styles.record, styles.w33)}>
                            <InputField
                                name={'address_postcode'}
                                label={'Post Code'}
                                className={cx(styles.inputField)}
                                placeholder={'Enter post code'}
                                onChange={handleChange}
                                value={formik.values.address_postcode}
                                error={
                                    formik.touched.address_postcode &&
                                    Boolean(formik.errors.address_postcode)
                                }
                                onBlur={trimWhitespaces}
                            />
                        </div>
                    </div>
                </div>
                <hr className={cx(styles.divider)} />

                <div style={{ marginTop: '-30px'}}>
                    <div className={cx(styles.row)}>
                        <div className={cx(styles.paymentPurposeAlignment, styles.record, styles.w50)}>
                            <SelectField
                                name={'default_purpose'}
                                label={'Payment purpose'}
                                options={paymentPurposeTypes}
                                onChange={handleSelect}
                                className={cx(styles.inputField)}
                                value={formik.values.default_purpose}
                                error={
                                    formik.touched.default_purpose &&
                                    Boolean(formik.errors.default_purpose)
                                }
                                disable={paymentPurposeError}
                                helperText={
                                    (formik.touched.default_purpose && formik.errors.default_purpose) || (paymentPurposeError? 'Error while fetching payment purpose code. Retry': null)
                                }
                                required={!isMerlinOnly}
                                info={'For international payments, it is recommended to share the payment purpose.'}
                            />
                        </div>
                    </div>
                </div>


                <div style={{ display: 'flex', padding: '16px 0px', justifyContent: 'center', gap: '20px' }}>
                    <ButtonV2
                        text={'Cancel'}
                        variant="secondary"
                        onClick={onCancel}
                    />

                    <ButtonV2
                        text={'Continue'}
                        onClick={formik.handleSubmit}
                        disabled={!formik.isValid}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default AddResidenceDetails;
