import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { actions } from 'store/slice/common';
import selectState from 'store/selectors/common';
import cx from 'classnames';
import { Button, CustomTooltip, Icon, Loading } from 'components/common';
import styles from 'assets/styles/common.module.scss';
import { formatAmount, getCurrencySymbol, isQuoteEnabled } from 'utils/helpers';
import SelectAccountV2 from '../SelectInput/SelectAccountV2';
import SelectRecipientV2 from '../SelectInput/SelectRecipientV2';
import CurrencyIcon from '../CurrencyIcon';
import NumberFormat from 'react-number-format';
import PaymentValue from './PaymentValue';

const PaymentDetail = (props) => {
    const dispatch = useDispatch();
    const [destinationAmount, setDestinationAmount] = useState(null);
    const [isQuoteTransaction, setIsQuoteTransaction] = useState(false);

    const {
        details: { fromAccount, recipient, sourceAmount, reference },
        handleOnEdit,
    } = props;

    const {
        quote: { quoteInfo, loading },
    } = selectState();

    const fetchQuote = useCallback(() => {
        if (isQuoteTransaction && fromAccount && recipient && recipient) {
            setDestinationAmount(null);
            dispatch(
                actions.createQuote({
                    source_account: fromAccount,
                    source_amount: sourceAmount,
                    recipient: recipient,
                    reference: reference,
                })
            );
        }
    }, [dispatch, fromAccount, sourceAmount, isQuoteTransaction, recipient, reference]);

    useEffect(() => {
        const enabled = isQuoteEnabled(fromAccount);
        setIsQuoteTransaction(enabled);
        if (enabled) {
            fetchQuote();
        }
    }, [fetchQuote, fromAccount]);

    useEffect(() => {
        if (isQuoteEnabled(fromAccount)) {
            if (quoteInfo) {
                setDestinationAmount(quoteInfo.destination_amount);
            }
        } else {
            setDestinationAmount(sourceAmount);
        }
    }, [fromAccount, sourceAmount, quoteInfo]);

    const renderQuoteInfo = () => {
        if (quoteInfo) {
            return (
                <>
                    <div className={cx(styles.quoteLabelContainer)}>
                        <div className={cx(styles.quoteLabel)}>Estimated exchange rate and fee</div>
                    </div>
                    <div className={cx(styles.fetchQuote)}>
                        <div className={cx(styles.exchangeRate)}>
                            <CurrencyIcon
                                className={cx(styles.currency)}
                                rounded
                                currency={fromAccount?.currency}
                            />
                            1 {fromAccount?.currency} =
                            <CurrencyIcon
                                className={cx(styles.currency)}
                                rounded
                                currency={recipient?.currency}
                            />
                            {quoteInfo.exchange_rate} {recipient?.currency}
                            <Icon
                                name="refresh"
                                className={cx(styles.refresh)}
                                onClick={() => {
                                    fetchQuote();
                                }}
                            />
                        </div>
                        <div className={cx(styles.divider)}></div>
                        <div className={cx(styles.feeAmount)}>
                            Fee :&nbsp;
                            <NumberFormat
                                value={formatAmount(quoteInfo.fee_amount)}
                                thousandSeparator={true}
                                displayType={'text'}
                                decimalScale={2}
                                prefix={getCurrencySymbol(fromAccount?.currency)}
                            />
                        </div>
                    </div>
                </>
            );
        } else if (loading) {
            return (
                <div className={cx(styles.fetchQuote)}>
                    <Loading className={cx(styles.loading)} />
                </div>
            );
        } else {
            return (
                <div className={cx(styles.fetchQuote)}>
                    <div className={cx(styles.quoteText)}>Fetch exchange rate to proceed</div>
                    <div>
                        <Button
                            className={cx(styles.fetchBtn)}
                            disabled={loading || !sourceAmount}
                            text={'Fetch exchange rate'}
                            onClick={fetchQuote}
                        />
                    </div>
                </div>
            );
        }
    };

    return (
        <div className={cx(styles.paymentInputContainer)}>
            <div>
                {/* {handleOnEdit && <div
                    className={cx(styles.editButton)}
                    >
                    Edit
                    <Icon name="edit" onClick={() => {
                        handleOnEdit();
                    }} className={cx(styles.editIcon)} />
                </div>} */}
                {handleOnEdit && <div className={cx(styles.editButtonTooltip)}>
                    <CustomTooltip info={'Click here to edit payout details'} enableInfoStyle={false}>
                        <Icon name="edit" onClick={() => {
                                handleOnEdit();
                            }} className={cx(styles.editIcon)} />
                    </CustomTooltip>
                </div>}                
                <div className={cx(styles.paymentAccounts)}>
                    <div className={cx(styles.paymentInputSelect)}>
                        <SelectAccountV2
                            name={'fromAccount'}
                            label={'Send funds from'}
                            value={fromAccount?.id}
                            readonly={true}
                            disableInactiveAccount={true}
                            disableEmptyAccount={true}
                            showBalance={true}
                            onChange={()=>{}}
                        />
                    </div>
                    <div className={cx(styles.paymentInputSelect)}>
                        <SelectRecipientV2
                            name={'recipient'}
                            label={'Send funds to'}
                            readonly={true}
                            currency={isQuoteTransaction ? null : fromAccount?.currency}
                            value={recipient?.id}
                        />
                    </div>
                </div>
                <div className={styles.amountInput}>
                    <div className={styles.source}>
                        {sourceAmount && (
                            <PaymentValue label={'You send'}
                                valueType={'number'}
                                value={
                                    (<NumberFormat
                                        value={formatAmount(sourceAmount)}
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        decimalScale={2}
                                        prefix={getCurrencySymbol(fromAccount?.currency)}
                                    />)} />
                        )}
                    </div>
                    {isQuoteTransaction && (
                        <div className={styles.quoteInfo}>{renderQuoteInfo()}</div>
                    )}
                    <div className={styles.destination}>
                        {destinationAmount && (
                            <PaymentValue label={'Send to recipient'}
                                valueType={'number'}
                                value={
                                    (<NumberFormat
                                        value={formatAmount(destinationAmount)}
                                        thousandSeparator={true}
                                        displayType={'text'}
                                        decimalScale={2}
                                        prefix={getCurrencySymbol(recipient?.currency)}
                                    />)} />
                        )}
                    </div>
                </div>
                <div className={styles.reference}>
                    <PaymentValue 
                        label={'Reference'}
                        value={reference ? reference: (isQuoteTransaction? null: 'payout')}
                        valueType={'text'}
                        />
                </div>
            </div>
        </div>
    );
};

export default PaymentDetail;
