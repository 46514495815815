import styles from 'assets/styles/users.module.scss';
import { useFormik } from 'formik';
import { actions } from 'store/slice/userManagement';
import Checkbox from '@mui/material/Checkbox';
import { updateRolesValue, validationSchema } from './utils';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import cx from 'classnames';
import { Grid } from '@mui/material';
import { CustomTooltip, Dialog, InputField, Icon } from 'components/common';
import selectUsersState from 'store/selectors/userManagement';
import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Roles } from 'utils/auth';
import { logoutUser } from 'store/actions';
import DialogV2 from 'components/common/DialogV2';

const UserUpdate = ({ user, handleClose, isCurrentUser }) => {
    const { loading, rolesList, userAdminCount, updateUserOutcome } = selectUsersState();
    const dispatch = useDispatch();
    const [toggleIcon, setToggleIcon] = useState('show-password');
    const [passwordEditMode, setPasswordEditMode] = useState(false);
    const [haveValuesToUpdate, setHaveValuesToUpdate] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [showUpdateModal, setShowUpdateModal] = useState(!isCurrentUser);
    const [showNotificationModal, setShowNotificationModal] = useState(isCurrentUser);
    const [timer, setTimer] = useState('0');
    const [isPwdConfirmationModalOpen, setIsPwdConfirmationModalOpen] = React.useState(false);
    const [userData, setUserData] = useState(user);

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);

    const formik = useFormik({
        initialValues: {
            id: userData.id,
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            phone_number: userData.phone_number,
            txn_email_otp_enabled: userData.txn_email_otp_enabled?.toString(),
            is_active: userData.is_active?.toString(),
            roles: userData.roles.slice(),
        },
        validationSchema: validationSchema(
            userAdminCount,
            true,
            userData.roles.includes(Roles.UserAdmin)
        ),
        onSubmit: (values) => {
            dispatch(actions.updateUser(values));
        },
    });

    const closeDialog = () => {
        dispatch(actions.clearUserUpdateState());
        formik.resetForm({
            values: formik.initialValues,
        });
        handleClose();
    };

    const toggleShowPassword = useCallback(() => {
        setToggleIcon(toggleIcon === 'show-password' ? 'hide-password' : 'show-password');
    }, [toggleIcon]);

    const handleChange = useCallback(
        (event) => {
            formik.setValues((values) => ({
                ...values,
                [event.target.name]: event.target.value,
            }));
        },
        [formik]
    );

    const handlePhoneNumberChange = useCallback(
        (event) => {
            const value = event.target.value;
            if (value === '') {
                // Allow empty values to clean existing field
                formik.setValues((values) => ({
                    ...values,
                    [event.target.name]: '+',
                }));
                return;
            }
            if (value.search(/^((\+\d{0,20}))$/) !== 0) {
                return;
            }
            formik.setValues((values) => ({
                ...values,
                [event.target.name]: value,
            }));
        },
        [formik]
    );

    const handleRolesChange = useCallback(
        (event) => {
            const roles_to_update = updateRolesValue(
                formik.values.roles,
                event.target.value,
                event.target.checked
            );
            formik.setFieldTouched('roles', true);
            formik.setFieldValue('roles', roles_to_update);
        },
        [formik]
    );

    const getTimeRemaining = useCallback((e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        return {
            total,
            seconds,
        };
    }, []);

    const endTime = useCallback(() => {
        let time = new Date();

        // This is where you need to adjust if
        // you entend to add more time
        time.setSeconds(time.getSeconds() + 5);
        return time;
    }, []);

    const refreshTimer = useCallback(
        (e) => {
            let { total, seconds } = getTimeRemaining(e);
            if (total > 0) {
                setTimer('' + seconds);
            } else if (total <= 0) {
                clearInterval(Ref.current);
                dispatch(logoutUser());
            }
        },
        [dispatch, getTimeRemaining, setTimer]
    );

    const startTimer = useCallback(
        (e) => {
            // If you adjust it you should also need to adjust the Endtime formula we are about to code next.
            setTimer('5');

            // Clearing the previous setInterval if the timer is started again
            if (Ref.current) clearInterval(Ref.current);
            const id = setInterval(() => {
                refreshTimer(e);
            }, 1000);
            Ref.current = id;
        },
        [setTimer, refreshTimer]
    );

    useEffect(() => {
        if (updateUserOutcome.status === null) {
            return;
        }
        if (updateUserOutcome.status === 'failed') {
            setErrorMsg(updateUserOutcome.error.message);
            setSuccessMsg(null);
        }
        if (updateUserOutcome.status === 'success') {
            setErrorMsg(null);
            if (formik.values.password !== undefined) {
                formik.setFieldValue('password', undefined);
            }
            setPasswordEditMode(false);
            setUserData(updateUserOutcome.user);
            setSuccessMsg('Updated the user successfully');
            if (isCurrentUser) {
                startTimer(endTime());
                setIsPwdConfirmationModalOpen(true);
            }
        }
    }, [updateUserOutcome, endTime, isCurrentUser, startTimer, formik]);

    const values_to_update = useCallback(() => {
        const values = formik.values;
        return {
            ...(userData.first_name !== values.first_name && {
                first_name: values.first_name,
            }),
            ...(userData.last_name !== values.last_name && {
                last_name: values.last_name,
            }),
            ...(userData.email !== values.email && { email: values.email }),
            ...(userData.phone_number !== values.phone_number && {
                phone_number: values.phone_number,
            }),
            ...(userData.txn_email_otp_enabled?.toString() !==
                values.txn_email_otp_enabled.toString() && {
                txn_email_otp_enabled: values.txn_email_otp_enabled,
            }),
            ...(userData.is_active?.toString() !== values.is_active.toString() && {
                is_active: values.is_active,
            }),
            ...(!areRolesEqual(userData.roles, values.roles) && {
                roles: values.roles,
            }),
            ...(values.password !== undefined && { password: values.password }),
        };
    }, [userData, formik]);

    useEffect(() => {
        const to_update = values_to_update();
        if (Object.keys(to_update).length === 0) {
            setHaveValuesToUpdate(false);
        } else {
            setHaveValuesToUpdate(true);
        }
    }, [values_to_update]);

    const areRolesEqual = (old_roles, new_roles) => {
        return (
            old_roles.length === new_roles.length &&
            old_roles.every((value) => new_roles.indexOf(value) !== -1)
        );
    };
    const updateUser = useCallback(() => {
        dispatch(
            actions.updateUser({
                id: userData.id,
                ...values_to_update(),
            })
        );
    }, [values_to_update, userData.id, dispatch]);

    return (
        <>
            <DialogV2
                title={'Edit user'}
                open={showNotificationModal}
                setOpen={handleClose}
                className={'users-modal'}
                submitButton={'Continue'}
                cancelButton={'Cancel'}
                cancelAction={handleClose}
                submitAction={() => {
                    setShowNotificationModal(false);
                    setShowUpdateModal(true);
                }}
                variant={'warning'}>
                You are updating your user profile. Once your profile is updated, you will be logged
                out of your current session and required to re-login. Do you wish to continue?
            </DialogV2>

            <Dialog
                title={'Edit user'}
                fullWidth
                open={showUpdateModal}
                isSingleButton={false}
                className={'users-modal'}
                isDisable={loading || !formik.isValid || !haveValuesToUpdate}
                setOpen={closeDialog}
                submitButton={'Edit user'}
                cancelButton={'Cancel'}
                cancelAction={closeDialog}
                submitAction={() => {
                    updateUser();
                }}
                paperProps={{
                    maxWidth: '100%',
                    width: '80%',
                }}
                backdropProps={{
                    style: {
                        backgroundColor: 'rgba(255,255,255, 0.8)',
                    },
                }}>
                <div className={cx(styles.confirmationDialog)}>
                    <div className={cx(styles.confirmation)}>
                        {errorMsg && <h3 className={cx(styles.errorMsg)}>{errorMsg}</h3>}
                        {successMsg && <h3 className={cx(styles.successMsg)}>{successMsg}</h3>}
                        <form onSubmit={formik.handleSubmit}>
                            <Grid className={cx(styles.gridRow)}>
                                <div className={cx(styles.gridRowEl)}>
                                    <InputField
                                        name={'first_name'}
                                        label={'First name'}
                                        required={true}
                                        value={formik.values.first_name}
                                        onChange={handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.first_name &&
                                            Boolean(formik.errors.first_name)
                                        }
                                        helperText={
                                            formik.touched.first_name && formik.errors.first_name
                                        }
                                        id={'first_name'}
                                        className={styles.usersInput}
                                    />
                                </div>
                                <div className={cx(styles.gridRowEl)}>
                                    <InputField
                                        name={'last_name'}
                                        label={'Last name'}
                                        required={true}
                                        value={formik.values.last_name}
                                        onChange={handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.last_name &&
                                            Boolean(formik.errors.last_name)
                                        }
                                        helperText={
                                            formik.touched.last_name && formik.errors.last_name
                                        }
                                        id={'last_name'}
                                    />
                                </div>
                            </Grid>
                            <Grid className={cx(styles.gridRow)}>
                                <div className={cx(styles.gridRowEl)}>
                                    <InputField
                                        name={'email'}
                                        label={'Email'}
                                        required={true}
                                        value={formik.values.email}
                                        onChange={handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                        id={'email'}
                                    />
                                </div>
                                <div className={cx(styles.gridRowEl)}>
                                    {!passwordEditMode ? (
                                        <>
                                            <div
                                                style={{
                                                    textAlign: 'left',
                                                    marginTop: '16px',
                                                    marginBottom: '5px',
                                                }}>
                                                Password
                                            </div>
                                            <CustomTooltip
                                                info={'Click here to update the password'}>
                                                <div
                                                    style={{ fontSize: '14px' }}
                                                    onClick={() => setPasswordEditMode(true)}>
                                                    Click here to update the password{' '}
                                                    <Icon
                                                        className={cx(styles.editUserButton)}
                                                        name={'edit'}
                                                        height={'15px'}
                                                        width={'15px'}
                                                    />
                                                </div>
                                            </CustomTooltip>
                                        </>
                                    ) : (
                                        <InputField
                                            type={
                                                toggleIcon === 'show-password' ? 'password' : 'text'
                                            }
                                            name={'password'}
                                            label={'Password'}
                                            value={formik.values.password}
                                            onBlur={formik.handleBlur}
                                            onChange={handleChange}
                                            handleIconClick={toggleShowPassword}
                                            hasIcon={true}
                                            iconPosition="end"
                                            iconName={toggleIcon}
                                            error={
                                                formik.touched.password &&
                                                Boolean(formik.errors.password)
                                            }
                                            helperText={
                                                formik.touched.password && formik.errors.password
                                            }
                                            id={'password'}
                                        />
                                    )}
                                </div>
                            </Grid>
                            <Grid item className={cx(styles.gridRow)} xs={6}>
                                <div className={cx(styles.gridRowEl)}>
                                    <InputField
                                        name={'phone_number'}
                                        label={'Mobile number'}
                                        value={formik.values.phone_number || '+'}
                                        onBlur={formik.handleBlur}
                                        onChange={handlePhoneNumberChange}
                                        error={
                                            formik.touched.phone_number &&
                                            Boolean(formik.errors.phone_number)
                                        }
                                        helperText={
                                            formik.touched.phone_number &&
                                            formik.errors.phone_number
                                        }
                                        id={'phone_number'}
                                    />
                                </div>
                            </Grid>
                            <Grid container className={styles.userWrapper}>
                                <Grid item className={styles.usersRolesLabel}>
                                    Roles:
                                </Grid>
                                <Grid item>
                                    <FormControl
                                        required
                                        error={Boolean(formik.errors.roles)}
                                        component="fieldset"
                                        variant="standard">
                                        <FormGroup className={cx(styles.rolesWrapper)}>
                                            {rolesList.map(({ label, value, description }) => {
                                                return (
                                                    <CustomTooltip key={value} info={description}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={formik.values.roles?.includes(
                                                                        value
                                                                    )}
                                                                    name={label}
                                                                    value={value}
                                                                    onChange={handleRolesChange}
                                                                />
                                                            }
                                                            label={label}
                                                            key={label}
                                                        />
                                                    </CustomTooltip>
                                                );
                                            })}
                                        </FormGroup>
                                        {formik.touched.roles && formik.errors.roles && (
                                            <FormHelperText className={cx(styles.invalid)}>
                                                {formik.errors.roles}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
            <DialogV2
                title={'User update confirmation'}
                open={isPwdConfirmationModalOpen}
                hideActions={true}
                disableClose={true}
                setOpen={handleClose}
                className={'users-modal'}
                variant={'success'}>
                The User is updated successfully. You will be logged out in{' '}
                <span className={cx(styles.otp)}> {timer} </span> seconds. Relogin to resume with
                revised details.
            </DialogV2>
        </>
    );
};

export default UserUpdate;
