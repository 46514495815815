import { call, put, takeLatest } from 'redux-saga/effects';
import store from 'store';
import {
    postLoginSuccess,
    postLoginFailure,
    postLoginOtpSuccess,
    postLoginOtpFailure,
} from '../actions';
import { POST_LOGIN_PENDING, POST_LOGIN_OTP_PENDING, LOGOUT } from '../actionTypes';
import { actions as userActions } from 'store/slice/user';
import { logoutSuccess } from 'store/actions/auth';
import { request } from 'utils/api';
import endpoints from 'utils/endpoints';

const {
    auth: { logout },
} = endpoints;

function* postLoginWorker({ option }) {
    try {
        const { data } = yield call(request().post, '/auth/login', option);

        yield put(postLoginSuccess(data));
    } catch (error) {
        const response = error?.response;
        const data = response?.data;
        const status = response?.status;

        if (!status || !data) {
            yield put(postLoginFailure('Something went wrong', 500));
            return;
        }
        yield put(postLoginFailure(data?.message, status));
    }
}

export function* postLogin() {
    yield takeLatest(POST_LOGIN_PENDING, postLoginWorker);
}

function* postLoginOtpWorker({ option }) {
    try {
        const { data } = yield call(request().post, '/auth/2fa-verify', option);

        yield put(postLoginOtpSuccess(data));

        if (data) {
            const user = yield call(request().get, `user-management/user/${data.user_id}`);
            yield put(userActions.getUserDataSuccess(user.data));
        }
    } catch (error) {
        const { data } = error.response;
        yield put(postLoginOtpFailure(data?.message, 403));
    }
}

function* logoutUser({ data }) {
    try {
        const state = store.getState();
        if (state?.app?.featureFlags.enable_jwt_expiry) {
            yield call(request().post, logout.url);
        }
        yield put(logoutSuccess());
    } catch (error) {
        yield put(logoutSuccess());
    }
}

export function* postLoginOtp() {
    yield takeLatest(POST_LOGIN_OTP_PENDING, postLoginOtpWorker);
    yield takeLatest(LOGOUT, logoutUser);
}
