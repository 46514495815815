import axios from 'axios';
import { getAccessToken, getReCaptchaToken } from './auth';
import jwt from 'jwt-decode';

const developmentDomain = 'https://api-development.merge.money';
const stagingDomain = 'https://api-staging.merge.money';
const sandboxDomain = 'https://api-sandbox.mergedup.com';
const productionDomain = 'https://api.mergedup.com';

let domain;
switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'staging':
        domain = stagingDomain;
        break;
    case 'sandbox':
        domain = sandboxDomain;
        break;
    case 'production':
        domain = productionDomain;
        break;
    default:
        domain = developmentDomain;
}

export const request = ({ prefix = '/v1' } = {}) => {
    const accessToken = getAccessToken();
    const reCaptchaToken = getReCaptchaToken();
    let token;
    let reCaptcha;

    if (accessToken) {
        token = { x_token: accessToken };
    }

    if (reCaptchaToken) {
        reCaptcha = { x_recaptcha_token: reCaptchaToken };
    }

    const headers = {
        ...token,
        ...reCaptcha,
    };

    return axios.create({
        baseURL: `${domain}${prefix}`,
        headers,
    });
};

export const localRequest = ({ port, prefix = '/v1' } = {}) => {
    let token;
    let reCaptcha;
    const accessToken = getAccessToken();
    const reCaptchaToken = getReCaptchaToken();

    if (accessToken) {
        token = { x_token: accessToken };
    }

    if (reCaptchaToken) {
        reCaptcha = { x_recaptcha_token: reCaptchaToken };
    }

    const { sub } = jwt(accessToken);
    const { user_id, token_id, company_id } = JSON.parse(sub);
    const headers = {
        ...token,
        ...reCaptcha,
        'x-company-id': company_id,
        'x-user-id': user_id,
        'x-token-id': token_id,
    };

    return axios.create({
        baseURL: `http://localhost:${port}/v1`,
        headers,
    });
};
