import { Box, Grid, List } from '@mui/material';
import styles from 'assets/styles/account.module.scss';
import cx from 'classnames';
import { CustomList, CustomTooltip, Icon } from 'components/common';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserTypes } from 'store/selectors/auth';
import { AllowedUsers, belongsTo } from 'utils/auth';
import {
    getCurrencySymbol,
    formatAmount,
    isQuoteEnabled,
    disableAccountCreatedInCurrentMonth,
} from 'utils/helpers';
import { getAccountStatusIcon, getAccountStatusDescription } from './utils';
import selectAppState from 'AppSelectors';
import { MAIN_ACCOUNT } from 'components/dashboard/account/constants';
import AccountsKebabMenu from './AccountsKebabMenu';

const getSubtitle = (account) => {
    var subTitle = '';
    if (account.account_number) {
        subTitle += `Acc-number: ${account.account_number}, `;
    }
    if (account.sort_code) {
        subTitle += `Sort code: ${account.sort_code}, `;
    }
    if (account.iban) {
        subTitle += `IBAN: ${account.iban}, `;
    }
    if (account.bic) {
        subTitle += `BIC: ${account.bic}, `;
    }
    return subTitle?.substring(0, subTitle.length - 2);
};

const AccountList = ({ accounts, pageMeta }) => {
    const navigate = useNavigate();
    const { featureFlags } = selectAppState();
    const userTypes = useSelector(getUserTypes);
    const [activeKebabId, setActiveKebabId] = useState(null);

    const renderTile = (account) => {
        return (
            <div style={{ display: 'flex', gap: '17px' }}>
                <div>
                    {account.account_name}
                    {account.client_account_name
                        ? ` (Nickname: ${account.client_account_name})`
                        : ''}
                </div>
                {account.type === MAIN_ACCOUNT && <div className={cx(styles.typeTag)}>Main</div>}
            </div>
        );
    };

    return (
        <Grid item md={12} xs={12} className={cx(styles.list)} style={{ marginTop: '56px' }}>
            <List>
                {accounts?.length > 0 &&
                    accounts.map((account) => (
                        <React.Fragment key={account.id}>
                            <CustomList
                                id={account.id}
                                key={account.id}
                                name={account.client_account_name || account.account_name}
                                title={renderTile(account)}
                                subtitle={getSubtitle(account)}
                                avatar={'true'}
                                customClass={cx(styles.item, styles.subItem)}
                                avatarClass={cx(
                                    styles.avatar,
                                    account.status === 'blocked' && styles.avatarDisable
                                )}
                                subAvatar={
                                    <Box className={cx(styles.avatarSubIcon)}>
                                        <CustomTooltip
                                            info={getAccountStatusDescription(account.status)}>
                                            <Icon name={getAccountStatusIcon(account.status)} />
                                        </CustomTooltip>
                                    </Box>
                                }
                                actions={[
                                    <div key={'actions'} className={styles.actions}>
                                        <div className={cx(styles.accountBalance)}>
                                            <NumberFormat
                                                prefix={getCurrencySymbol(account.currency)}
                                                value={formatAmount(account.balance)}
                                                thousandSeparator={true}
                                                displayType={'text'}
                                                decimalScale={2}
                                            />
                                        </div>
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={account.status === 'pending'} // Enable view transactions for other than pending accounts
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/dashboard/accounts/${account.id}/transactions`,
                                                })
                                            }>
                                            <CustomTooltip info={'View Transactions'}>
                                                <Icon name={'transactions'} />
                                            </CustomTooltip>
                                        </div>
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={
                                                !belongsTo(userTypes, AllowedUsers.SendFunds) ||
                                                account.status !== 'active' ||
                                                parseFloat(account.balance) === 0
                                            }
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/dashboard/funds`,
                                                    search: `?from=${account.id}`,
                                                })
                                            }>
                                            <CustomTooltip info={'Send funds'}>
                                                <Icon
                                                    name={
                                                        isQuoteEnabled(account)
                                                            ? 'send-exchange'
                                                            : 'send'
                                                    }
                                                />
                                            </CustomTooltip>
                                        </div>
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={account.status === 'pending'}
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/dashboard/pending-payments`,
                                                    search: `?search_account=${
                                                        account.iban ?? account.account_number
                                                    }&currency=${account.currency}`,
                                                })
                                            }>
                                            <CustomTooltip info={'Pending payments'}>
                                                <Icon name={'approve-payments'} />
                                            </CustomTooltip>
                                        </div>
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={
                                                !belongsTo(
                                                    userTypes,
                                                    AllowedUsers.MakePaymentRequest
                                                ) ||
                                                account.status !== 'active' ||
                                                parseFloat(account.balance) === 0
                                            }
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/dashboard/payment-request`,
                                                    search: `?from=${account.id}`,
                                                })
                                            }>
                                            <CustomTooltip info={'Queue payments'}>
                                                <Icon name={'payment-request'} />
                                            </CustomTooltip>
                                        </div>
                                        <div
                                            className={cx(styles.actionsIcon)}
                                            disabled={
                                                !belongsTo(userTypes, AllowedUsers.Reports) ||
                                                account.status === 'pending'
                                            }
                                            onClick={() =>
                                                navigate({
                                                    pathname: `/dashboard/transaction-report`,
                                                    search: `?from=${account.id}`,
                                                })
                                            }>
                                            <CustomTooltip info={'Transaction reports'}>
                                                <Icon name={'reports-icon'} />
                                            </CustomTooltip>
                                        </div>
                                        {featureFlags.monthly_account_statement_enabled && (
                                                <div
                                                    className={cx(styles.actionsIcon)}
                                                    disabled={
                                                        !belongsTo(
                                                            userTypes,
                                                            AllowedUsers.Reports
                                                        ) ||
                                                        account.status === 'pending' ||
                                                        disableAccountCreatedInCurrentMonth(account)
                                                    }
                                                    onClick={() =>
                                                        navigate({
                                                            pathname: `/dashboard/account-statement`,
                                                            search: `?from=${account.id}`,
                                                        })
                                                    }>
                                                    <CustomTooltip
                                                        info={'Monthly account statement'}>
                                                        <Icon name={'monthly-statement'} />
                                                    </CustomTooltip>
                                                </div>
                                            )}
                                        <AccountsKebabMenu
                                            account={account}
                                            activeKebabId={activeKebabId}
                                            setActiveKebabId={setActiveKebabId}
                                        />
                                    </div>,
                                ]}
                            />
                        </React.Fragment>
                    ))}
            </List>
        </Grid>
    );
};
export default AccountList;
