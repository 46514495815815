import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/slice/common';
import { request } from 'utils/api';
import { getAccessToken } from 'utils/auth';
import Endpoints from 'utils/endpoints';
import { clean, getQueryParams } from 'utils/helpers';

const { accounts, recipients } = Endpoints;

export function* getAccountsForSelect(action) {
    try {
        const { currency, search } = action.payload;
        const { data } = yield call(
            request().get,
            accounts.accounts.url({ currency, search, size: 100 })
        );
        if (data) {
            const { items } = data;
            yield put(actions.getAccountsForSelectSuccess(items));
        } else {
            yield put(actions.getAccountsforSelectError('Failed to fetch accounts'));
        }
    } catch (err) {
        yield put(actions.getAccountsforSelectError(err.message));
    }
}

function* getRecipientsForSelect(action) {
    try {
        const queryParams = getQueryParams(clean(action.payload));
        const { data } = yield call(request().get, recipients.getRecipient.url(queryParams));
        if (data) {
            const { items } = data;
            yield put(actions.getRecipientsForSelectSuccess(items));
        } else {
            yield put(actions.getRecipientsforSelectError('Failed to fetch accounts'));
        }
    } catch (err) {
        yield put(actions.getRecipientsforSelectError(err.message));
    }
}

export function* createQuote(action) {
    const { url } = Endpoints.accounts.account;

    const { source_account, recipient, source_amount, reference } = action.payload;
    const quote_payload = {
        source_currency: source_account.currency,
        source_amount: source_amount,
        recipient_id: recipient.id,
        destination_currency: recipient.currency,
        ...(reference ? { reference: reference } : {}),
    };
    try {
        const { data } = yield call(
            request().post,
            `${url}/${action.payload.source_account.id}/quote`,
            quote_payload
        );
        if (data) {
            yield put(actions.createQuoteSuccess(data));
        }
    } catch (err) {
        const response = err?.response;
        const data = response?.data;
        const status = response?.status;

        if (!status || !data) {
            yield put(
                actions.createQuoteError({
                    message: 'Failed to fetch quote',
                    status: 500,
                })
            );
            return;
        }
        
        yield put(
            actions.createQuoteError({
                message: data.message ? data.message : data.detail,
                status: status,
            })
        );
    }
}

export function* generateRequestOtp({ payload }) {
    const { message, subject, requestPath, requestMethod, requestPayload, deliveryChannel } =
        payload;

    try {
        const generateRequestOtpResponse = yield call(request().post, 'auth/generate-request-otp', {
            message,
            subject,
            request: {
                path: requestPath,
                method: requestMethod,
                payload: requestPayload,
            },
            token: getAccessToken(),
            delivery_channel: deliveryChannel,
        });
        if (
            generateRequestOtpResponse?.data &&
            generateRequestOtpResponse.status >= 200 &&
            generateRequestOtpResponse.status < 300
        ) {
            const { data } = generateRequestOtpResponse;
            yield put(actions.generateRequestOtpSuccess(data.token));
        } else {
            yield put(
                actions.generateRequestOtpError({
                    message: 'Error while fetching OTP. Please try again.',
                    status: generateRequestOtpResponse.status,
                })
            );
        }
    } catch (err) {
        yield put(
            actions.generateRequestOtpError({
                message: 'Error while fetching OTP. Please try again.',
                status: 403,
            })
        );
    }
}

export function* commonSaga() {
    yield takeLatest(actions.getAccountsForSelect.type, getAccountsForSelect);
    yield takeLatest(actions.getRecipientsForSelect.type, getRecipientsForSelect);
    yield takeLatest(actions.createQuote.type, createQuote);
    yield takeLatest(actions.generateRequestOtp.type, generateRequestOtp);
}
